import React from 'react'

import {
  Flex,
  CopyToClipboard,
  TruncateWithTooltip,
  useForTablet,
} from '@sketch/components'
import {
  StyledModal as Modal,
  Title,
  PreviewImage,
  PreviewImageEmpty,
  ShareParentName,
  Subtitle,
} from './SharingModal.styles'

import { ShareInfoWithCollectionFragment } from '@sketch/gql-types'

const ShareLocation: React.FC<{
  share?: ShareInfoWithCollectionFragment
}> = ({ share }) => {
  if (!share) {
    return null
  }

  if (share.project) {
    return (
      <Subtitle>
        <TruncateWithTooltip width={350}>
          {share.workspace.name} /{' '}
          <ShareParentName>{share.project.name}</ShareParentName>
        </TruncateWithTooltip>
      </Subtitle>
    )
  }

  return (
    <TruncateWithTooltip width={350}>
      <Subtitle>{share.workspace.name}</Subtitle>
    </TruncateWithTooltip>
  )
}

interface SharingModalHeaderProps {
  share?: ShareInfoWithCollectionFragment
}

export const SharingModalHeader = ({ share }: SharingModalHeaderProps) => {
  const isTabletAndBigger = useForTablet()
  const thumbnails = share?.version?.document?.previewFile?.thumbnails
  const image = thumbnails ? thumbnails[0]?.url : undefined

  return (
    <Modal.Header>
      <Flex data-testid="sharing-modal-header" alignItems="center" mb={4}>
        {image ? (
          <PreviewImage alt={share?.name ?? ''} src={image} />
        ) : (
          <PreviewImageEmpty data-testid="share-image-placeholder" />
        )}

        <Flex ml={6} mr={6} flex={1} flexDirection="column">
          <Title>
            <strong>Share:</strong> {share?.name ?? <>&hellip;</>}
          </Title>
          <ShareLocation share={share} />
        </Flex>
        {share && isTabletAndBigger && (
          <CopyToClipboard
            size="40"
            showIcon={false}
            value={share.publicUrl}
            title={
              share.publicAccessLevel !== 'NONE'
                ? 'Copy Public Link'
                : 'Copy Link'
            }
          />
        )}
      </Flex>
    </Modal.Header>
  )
}
