import React from 'react'
import styled from 'styled-components'
import { Text as BaseText } from '@sketch/components'

const Text = styled(BaseText)`
  && {
    margin-top: -8px;
  }

  > a {
    white-space: wrap;
  }
`

export const MemberLimitMessage: React.FC = ({ children }) => {
  return <Text textStyle="copy.tertiary.standard.C">{children}</Text>
}
