/**
 * Z-Index design tokens.
 *
 * For use with the following CSS properties:
 *
 *     z-index
 *
 * @see https://system-ui.com/theme
 */
export const zIndex = [
  -1,
  1,
  50,
  100,
  1000,
  2000,
  3000,
  4000,
  9999,
  10000,
] as const
