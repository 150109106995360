import styled from 'styled-components'

import { ReactComponent as QuestionMarkIcon } from '@sketch/icons/question-mark-16'

import { breakpoint } from '@sketch/global-styles'

import { TextArea, Modal } from '@sketch/components'

export const ModalHeader = styled(Modal.Header)`
  padding: 16px 0 4px;

  ${breakpoint('sm')`
      padding: 32px 0 4px;
  `}
`

export const StyledTextArea = styled(TextArea)`
  resize: none;
  width: 100%;
  height: 100px;
`

export const StyledQuestionMarkIcon = styled(QuestionMarkIcon)`
  color: ${({ theme }) => theme.colors.foreground.secondary.C};
  height: 16px;
  width: 16px;
  margin-left: 4px;
  margin-bottom: -2px; /* stylelint-disable-line scales/space */
`
