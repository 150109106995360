import React, { MouseEvent, useContext } from 'react'

import {
  LoadingPlaceholder,
  Tooltip,
  downloadFile,
  ModalContext,
} from '@sketch/components'
import { ReactComponent as ExportAssetsSVG } from '@sketch/icons/down-arrow-circle-16'
import { ReactComponent as ExportTokensSVG } from '@sketch/icons/token-16'
import { AssetButton, AssetSection } from './AboutTabExportItem.styles'
import AboutTabExportItem from './AboutTabExportItem'
import { ExportDesignTokensModal } from '../../modals/ExportDesignTokensModal'
import { useComponentsState } from '../ComponentsStateContext'
import { formatByteCount } from '@sketch/utils'

import useAssetDownload, { AssetState } from '../../hooks/useAssetDownload'
import { useVersioning } from '../../../versioning'

import {
  VersionDocumentFragment,
  ShareUserAccessLevelFragment,
} from '@sketch/gql-types'

interface AboutTabExportsSectionProps {
  updatedAt?: string
  shareIdentifier: string
  versionShortId: string
  document: VersionDocumentFragment
  userAccessLevel: ShareUserAccessLevelFragment['userAccessLevel']
  componentsCountTotal: number
}

const isForbiddenError = (assetState: AssetState): boolean => {
  if (!('errors' in assetState)) {
    return false
  }

  const errors = assetState.errors
  if (!errors) {
    return false
  }

  return !!errors.find(error => error.code === 'FORBIDDEN')
}

const AboutTabExportSection = ({
  updatedAt,
  shareIdentifier,
  versionShortId,
  document,
  userAccessLevel,
  componentsCountTotal,
}: AboutTabExportsSectionProps) => {
  const { isViewingLatestVersion } = useVersioning()
  const { showModal } = useContext(ModalContext)
  const componentsState = useComponentsState()

  const assetState = useAssetDownload({
    document,
    shareIdentifier,
    versionShortId,
  })
  const { assetStatus } = document

  const subtext = isViewingLatestVersion ? `Latest Version` : `Older Version`
  const asset =
    'assets' in assetState &&
    assetState.assets.length > 0 &&
    assetState.assets[0]

  const hasEmptyComponents =
    componentsState === 'PROCESSED' && componentsCountTotal === 0

  return (
    <>
      <AssetSection>
        <Tooltip
          placement="top"
          disabled={assetStatus === 'AVAILABLE'}
          content="Set layers exportable in the Mac app or select a layer and export it from the Inspect panel."
        >
          {assetState.type === 'UNAVAILABLE' && (
            <AssetButton disabled={true}>
              <AboutTabExportItem
                icon={ExportAssetsSVG}
                disabled={true}
                text="No assets to export (0)"
                updatedAt={updatedAt}
                subtext={subtext}
              />
            </AssetButton>
          )}
          {assetState.type === 'REQUESTABLE' && assetState.request && (
            <AssetButton onClick={assetState.request}>
              <AboutTabExportItem
                icon={ExportAssetsSVG}
                text={`Export Assets`}
                updatedAt={updatedAt}
                subtext={`${subtext}`}
              />
            </AssetButton>
          )}
          {assetState.type === 'LOADING' && (
            <AboutTabExportItem
              icon={LoadingPlaceholder}
              active={true}
              text="Generating assets…"
            />
          )}
          {assetState.type === 'DOWNLOADABLE' && asset && asset.path && (
            <AssetButton
              onClick={(
                e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>
              ) => {
                e.preventDefault()
                downloadFile(asset.path || '')
              }}
            >
              <AboutTabExportItem
                icon={ExportAssetsSVG}
                active={true}
                text={`Download Assets (ZIP ${formatByteCount(
                  asset.fileSizeInBytes || 0,
                  { fractionDigits: 0 }
                )})`}
              />
            </AssetButton>
          )}
        </Tooltip>

        {assetState.type === 'PERMANENT_FAILURE' &&
          (isForbiddenError(assetState) ? (
            <AboutTabExportItem
              icon={ExportAssetsSVG}
              error={true}
              text="Assets Unavailable"
              subtext="The option to export assets has been disabled in this document’s
            settings. Please contact the document’s owner for assistance."
            />
          ) : (
            <AboutTabExportItem
              icon={ExportAssetsSVG}
              error={true}
              text="Assets Unavailable"
              subtext="Unable to prepare assets. Please try again"
            />
          ))}

        {assetState.type === 'RECOVERABLE_FAILURE' && (
          <AboutTabExportItem
            icon={ExportAssetsSVG}
            error={true}
            text="Assets Unavailable"
            subtext="Unable to prepare assets. Please try again"
          />
        )}
      </AssetSection>

      <AssetSection>
        {hasEmptyComponents ? (
          <Tooltip
            placement="top"
            content="Set Color Variables, Text and Layer Styles tokens to export."
          >
            <AssetButton data-testid="document-export-tokens-button-disabled">
              <AboutTabExportItem
                icon={ExportTokensSVG}
                text="No Design Tokens to export"
                disabled
              />
            </AssetButton>
          </Tooltip>
        ) : (
          <AssetButton
            data-testid="document-export-tokens-button"
            onClick={() =>
              showModal(ExportDesignTokensModal, {
                shareIdentifier,
                versionShortId,
                userCanEditExportUrl: userAccessLevel === 'EDIT',
                componentsState,
              })
            }
          >
            <AboutTabExportItem
              icon={ExportTokensSVG}
              text="Export Design Tokens"
              subtext="Export Color Variables, Text and Layer Styles tokens, or create a public link"
              extraWidth
            />
          </AssetButton>
        )}
      </AssetSection>
    </>
  )
}

export default AboutTabExportSection
