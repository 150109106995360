import React, { forwardRef } from 'react'
import classNames from 'classnames'
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom'

import { useHover, useIsNodeContentTruncated } from '@sketch/utils'
import {
  MenuLink,
  Icon,
  HighlightIconWrapper,
  ActionWrapper,
  Text,
  DropdownAction,
  EllipsisIcon,
} from './Item.styles'
import { ButtonUnstyled } from '../../Button'
import { Tooltip } from '../../Tooltip'
import { useResponsiveDropdown } from '../../ResponsiveDropdown'

interface CommonProps {
  active?: boolean
  action?: React.ReactNode
  className?: string
  iconClassName?: string
  forceHover?: boolean
  forceActive?: boolean
  text: React.ReactNode
  additional?: React.ReactNode
  iconLabel?: string
}

interface SelectDropdownButtonItemProps
  extends React.ButtonHTMLAttributes<any>,
    CommonProps {
  icon?: React.ElementType | IconProps
  showActionsOnHover?: boolean
}

export interface IconProps {
  /** Icon element */
  icon: React.ElementType
  /** Icon element for the active state */
  activeIcon?: React.ElementType
  /** Icon element for the hover state */
  hoverIcon?: React.ElementType
  /** Icon element for the hover and active state */
  hoverIconActive?: React.ElementType
  /** Shows the highlight indicator on the icon */
  highlight?: boolean
}

export interface SelectDropdownItemProps extends SelectDropdownButtonItemProps {
  textNodeRef?: React.RefObject<HTMLElement>
}

export const SelectDropdownItem: React.FC<SelectDropdownItemProps> = React.forwardRef(
  function SelectDropdownItem(props, ref) {
    const {
      active,
      action,
      additional,
      className,
      iconClassName,
      forceHover,
      icon,
      text,
      textNodeRef,
      showActionsOnHover,
      ...buttonProps
    } = props

    const [hovered, hoverEventListeners] = useHover()

    const renderIcon = () => {
      if (!icon) return

      if (typeof icon === 'object' && icon.icon) {
        const iconElement = (
          <Icon className={iconClassName} as={icon.icon} role="image" />
        )

        return icon.highlight ? (
          <HighlightIconWrapper>{iconElement}</HighlightIconWrapper>
        ) : (
          iconElement
        )
      }

      return (
        <Icon
          className={iconClassName}
          as={(icon as unknown) as React.ElementType}
        />
      )
    }

    return (
      <MenuLink
        ref={ref}
        as={ButtonUnstyled}
        aria-label={text}
        className={classNames(className, { active })}
        $forceHover={forceHover}
        {...buttonProps}
        {...(showActionsOnHover && hoverEventListeners)}
      >
        {renderIcon()}

        <Text ref={textNodeRef}>{text}</Text>

        {action && (
          <ActionWrapper
            {...(!showActionsOnHover && hoverEventListeners)}
            onClick={event => event.stopPropagation()}
          >
            {showActionsOnHover ? hovered && action : action}
          </ActionWrapper>
        )}

        {additional}
      </MenuLink>
    )
  }
)

export const SelectDropdownButtonItem: React.FC<SelectDropdownButtonItemProps> = props => {
  const {
    action,
    additional,
    className,
    iconClassName,
    forceHover,
    icon,
    text,
    ...buttonProps
  } = props

  const [isNodeContentTruncated, truncatedNodeRef] = useIsNodeContentTruncated()

  return (
    <Tooltip
      placement="right"
      content={text}
      disabled={!isNodeContentTruncated}
    >
      {props => (
        <SelectDropdownItem
          action={action}
          additional={additional}
          className={className}
          iconClassName={iconClassName}
          forceHover={forceHover}
          icon={icon}
          text={text}
          textNodeRef={truncatedNodeRef}
          {...buttonProps}
          {...props}
        />
      )}
    </Tooltip>
  )
}

export type SelectDropdownLinkItemProps = NavLinkProps &
  CommonProps & {
    icon?: React.ElementType | IconProps
    iconWrapper?: React.ElementType<any>
    showActionsOnHover?: boolean
  }

export const SelectDropdownLinkItem = forwardRef(
  (props: SelectDropdownLinkItemProps, ref) => {
    const {
      action,
      additional,
      className,
      iconClassName,
      forceHover,
      forceActive,
      icon,
      iconLabel,
      iconWrapper: IconWrapper,
      text,
      showActionsOnHover,
      ...linkProps
    } = props

    const [
      isNodeContentTruncated,
      truncatedNodeRef,
    ] = useIsNodeContentTruncated()
    const [projectHovered, projectHoverEventListeners] = useHover()
    const [iconHovered, iconHoverEventListeners] = useHover()
    const location = useLocation()

    const renderIcon = () => {
      if (!icon) return

      if (typeof icon === 'object' && icon.icon) {
        const iconVariant =
          (location.pathname === linkProps.to && icon?.activeIcon) || icon.icon

        const iconHoverVariant =
          (location.pathname === linkProps.to && icon?.hoverIconActive) ||
          icon.hoverIcon

        const iconElement =
          iconHovered && icon.hoverIcon ? (
            <Icon
              className={iconClassName}
              as={iconHoverVariant}
              aria-label={iconLabel}
            />
          ) : (
            <Icon
              className={iconClassName}
              as={iconVariant}
              aria-label={iconLabel}
            />
          )

        const Wrapper = IconWrapper ?? React.Fragment

        return (
          <Wrapper>
            {icon.highlight ? (
              <HighlightIconWrapper>{iconElement}</HighlightIconWrapper>
            ) : (
              iconElement
            )}
          </Wrapper>
        )
      }

      return (
        <Icon
          className={iconClassName}
          as={(icon as unknown) as React.ElementType}
        />
      )
    }

    return (
      <Tooltip
        placement="right"
        content={text}
        disabled={!isNodeContentTruncated || projectHovered}
      >
        <div {...iconHoverEventListeners}>
          <MenuLink
            as={NavLink}
            activeClassName="active"
            className={className}
            $forceHover={forceHover}
            $forceActive={forceActive}
            ref={ref as React.MutableRefObject<any>}
            data-unmountafterclick="true"
            {...linkProps}
            {...(showActionsOnHover && projectHoverEventListeners)}
            onClick={(event: React.MouseEvent) => {
              // We don't want to follow the link if we are clicking in the
              // trigger to expand/collapse nested projects
              if (
                event.target instanceof HTMLElement &&
                event.target.closest('[aria-expanded]')
              ) {
                event.preventDefault()
              }
            }}
          >
            {renderIcon()}

            <Text ref={truncatedNodeRef}>{text}</Text>

            {action && (
              <ActionWrapper
                {...(!showActionsOnHover && projectHoverEventListeners)}
                onClick={event => event.stopPropagation()}
              >
                {showActionsOnHover ? projectHovered && action : action}
              </ActionWrapper>
            )}
            {additional}
          </MenuLink>
        </div>
      </Tooltip>
    )
  }
)

SelectDropdownLinkItem.displayName = 'LinkItem'

interface SelectDropdownExternalLinkItemProps
  extends React.ComponentProps<'a'>,
    CommonProps {
  icon?: React.ElementType
}

export const SelectDropdownExternalLinkItem: React.FC<SelectDropdownExternalLinkItemProps> = props => {
  const {
    action,
    additional,
    iconClassName,
    forceHover,
    icon,
    text,
    ...anchorProps
  } = props

  const [isNodeContentTruncated, truncatedNodeRef] = useIsNodeContentTruncated()

  return (
    <Tooltip
      placement="right"
      content={text}
      disabled={!isNodeContentTruncated}
    >
      {props => (
        <MenuLink
          as="a"
          rel="noopener noreferrer"
          target="_blank"
          $forceHover={forceHover}
          {...anchorProps}
          {...props}
        >
          {icon && <Icon className={iconClassName} as={icon} />}
          <Text ref={truncatedNodeRef}>{text}</Text>
          {action && <ActionWrapper>{action}</ActionWrapper>}
          {additional}
        </MenuLink>
      )}
    </Tooltip>
  )
}

interface SelectDropdownActionDropdownProps {
  text: string
  content: React.ReactNode
}

const ActionPopoverWrapper: React.FC = ({ children }) => <div>{children}</div>

export const SelectDropdownActionDropdown: React.FC<SelectDropdownActionDropdownProps> = props => {
  const { content, text } = props

  const [dropdownContent, buttonProps] = useResponsiveDropdown({
    dropdown: ActionPopoverWrapper,
    dropdownProps: { children: content },
    placement: 'bottom',
    usePortal: true,
  })

  return (
    <>
      <DropdownAction data-testid="project-options" {...buttonProps}>
        <EllipsisIcon />
        <span className="sr-only">{text}</span>
      </DropdownAction>
      {dropdownContent}
    </>
  )
}
