import React from 'react'
import { usePlaceDraftAnnotation } from '../../hooks'
import { NewFeatureHighlightPopover } from '@sketch/components'
import AddAnnotationButton from '../AddAnnotationButton'
import { AddAnnotationButtonProps } from '../AddAnnotationButton/AddAnnotationButton'
import { CancelButton } from '../AddAnnotationButton/AddAnnotationButton.styles'
import { useCookieConsentExists } from '@sketch/modules-common'

const AddAnnotationNavbarItem: React.FC<AddAnnotationButtonProps> = ({
  ...rest
}) => {
  const [placeDraftAnnotation, setPlaceDraftAnnotation] =
    usePlaceDraftAnnotation() || []

  const cookieConsentExists = useCookieConsentExists()

  /**
   * We should hide the button when the context is not available
   */
  if (placeDraftAnnotation === undefined) {
    return null
  }

  /** if place annotation is active, display cancel button */
  if (placeDraftAnnotation) {
    return (
      <CancelButton onClick={() => setPlaceDraftAnnotation?.(false)} size="32">
        Cancel
      </CancelButton>
    )
  }

  return (
    <NewFeatureHighlightPopover
      show={!cookieConsentExists}
      closeButtonTestId="close-comment-callout"
      highlightPlacement="header"
      titleText={'Comment Anywhere'}
      bodyText={
        'You can pin comments anywhere you like on the Canvas. Click the icon or press N to try it out.'
      }
      buttonLinkProps={{
        external: true,
        href:
          'http://sketch.com/docs/comments-and-notifications/?utm_source=web-app',
        target: '_blank',
        children: 'Learn more',
      }}
      localStorageKey="annotationsIntroSeen"
    >
      <AddAnnotationButton toolbar={true} {...rest} />
    </NewFeatureHighlightPopover>
  )
}

export default AddAnnotationNavbarItem
