import React from 'react'

import { ModalInjectedProps, ToastLink } from '@sketch/components'

import {
  ProjectFragment,
  GetProjectsDocument,
  useCreateProjectMutation,
} from '@sketch/gql-types'
import NestedProjectModalContent, {
  FormProps,
} from './NestedProjectModalContent'
import { useToast } from '@sketch/toasts'
import { routes } from '@sketch/modules-common'
import { useEventDispatch } from '@sketch/utils'

interface NestedProjectCreatedPayload {
  projectIdentifier: string
}

declare module '@sketch/utils' {
  export interface EventsMap {
    nestedProjectCreated: NestedProjectCreatedPayload
  }
}

interface CreateNestedProjectModalProps extends ModalInjectedProps {
  project: ProjectFragment
  workspaceIdentifier: string
}

export const CreateNestedProjectModal = (
  props: CreateNestedProjectModalProps
) => {
  const { project, workspaceIdentifier, hideModal } = props
  const { showToast } = useToast()
  const dispatchNestedProjectCreated = useEventDispatch('nestedProjectCreated')
  const dispatchSharesRefresh = useEventDispatch('workspaceShareRefresh')

  const [createProject, { loading }] = useCreateProjectMutation({
    onError: 'show-toast',
    onCompleted: ({ createWorkspaceProject }) => {
      const { name, identifier } = createWorkspaceProject.project

      dispatchSharesRefresh({
        workspaceIdentifier,
        projectIdentifier: project.identifier,
        onlyProjects: true,
      })

      dispatchNestedProjectCreated({ projectIdentifier: identifier })

      showToast(
        <>
          &quot;
          <ToastLink
            to={routes.WORKSPACE_PROJECT.create({
              workspaceId: workspaceIdentifier,
              projectId: identifier,
            })}
          >
            {name}
          </ToastLink>
          &quot; project created
        </>
      )

      hideModal?.()
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GetProjectsDocument,
        variables: { workspaceId: workspaceIdentifier },
      },
    ],
  })

  const handleSubmit = (values: FormProps) => {
    createProject({
      variables: {
        parentProjectIdentifier: project.identifier,
        workspaceId: workspaceIdentifier,
        name: values.name,
        description: values.description ?? '',
      },
    })
  }

  return (
    <NestedProjectModalContent
      onSubmit={handleSubmit}
      project={project}
      isLoading={loading}
      hideModal={hideModal}
    />
  )
}

export default CreateNestedProjectModal
