import React from 'react'
import { matchPath, useLocation } from 'react-router-dom'

import { routes } from '../../routes'

import {
  Text,
  Link,
  Navbar,
  LinkButton,
  useResponsiveDropdown,
  Dropdown,
} from '@sketch/components'

import {
  Header,
  LogoWrapper,
  Logo,
  MarketingWebsiteBackToWorkspaceButton,
  StyledAvatar,
  HelpButton,
  HelpChevron,
  HelpDropdownAnchor,
  HelpExternalArrow,
} from './IndexLayout.styles'

import { SKETCH_WEBSITE } from '@sketch/env-config'
import { useUserProfile } from '../../libs'
import { useScrollTop } from '@sketch/utils'

const headerLinkProps = {
  'sign-up': {
    link: routes.SIGN_UP.create({}),
    text: 'New to Sketch?',
    buttonCopy: 'Create an Account',
  },
  'sign-in': {
    link: routes.SIGN_IN.create({}),
    text: 'Already have an account?',
    buttonCopy: 'Sign In',
  },
  entry: {
    link: routes.ENTRY.create({}),
    text: null,
    buttonCopy: 'Back',
  },
  'back-sign-in': {
    link: routes.SIGN_IN.create({}),
    text: null,
    buttonCopy: 'Back to Sign In',
  },
  'workspace-settings': {
    link: routes.WORKSPACE_AGNOSTIC_SETTINGS_PEOPLE.create({}),
    linkWorkspace: (workspaceId: string) =>
      routes.WORKSPACE_SETTINGS_PEOPLE.create({ workspaceId }),
    text: null,
    buttonCopy: 'Back to Workspace',
  },
  'workspace-shares': {
    link: routes.WORKSPACE_AGNOSTIC_SHARES.create({}),
    linkWorkspace: (workspaceId: string) =>
      routes.WORKSPACE_SHARES.create({ workspaceId }),
    text: null,
    buttonCopy: 'Back to Workspace',
  },
} as const

type HeaderLinkType = typeof headerLinkProps
type HeaderLinkKeys = keyof HeaderLinkType | 'none'
type HeaderLinkItem = HeaderLinkType[keyof HeaderLinkType]

interface HeaderLinkProps {
  headerLink?: HeaderLinkKeys
}

const HelpDropdown = () => (
  <>
    <HelpDropdownAnchor href={`${SKETCH_WEBSITE}/docs/`}>
      Documentation
    </HelpDropdownAnchor>
    <HelpDropdownAnchor href={`${SKETCH_WEBSITE}/support/`}>
      Support
    </HelpDropdownAnchor>
    <HelpDropdownAnchor href={`${SKETCH_WEBSITE}/support/contact/`}>
      Contact Us
    </HelpDropdownAnchor>
    <Dropdown.Divider />
    <HelpDropdownAnchor href="https://forum.sketch.com" target="_blank">
      Community Forum <HelpExternalArrow />
    </HelpDropdownAnchor>
    <HelpDropdownAnchor href="https://status.sketch.com" target="_blank">
      Service Status <HelpExternalArrow />
    </HelpDropdownAnchor>
  </>
)

export const HeaderLink: React.FC<HeaderLinkProps> = ({
  headerLink = 'none',
}) => {
  const { state, search, pathname } = useLocation()
  const { data } = useUserProfile(true)

  const match = matchPath<{ workspaceId: string }>(pathname, {
    path: routes.WORKSPACE.template(),
  })

  const { workspaceId } = match?.params || {}
  const hasBackToWorkspaceButton =
    headerLink === 'workspace-settings' || headerLink === 'workspace-shares'

  const [content, buttonProps] = useResponsiveDropdown({
    dropdown: HelpDropdown,
    dropdownStyle: { minWidth: 270 },
  })

  const buildLink = (item: HeaderLinkItem) => {
    if (workspaceId && 'linkWorkspace' in item) {
      return item.linkWorkspace(workspaceId)
    }

    return item.link
  }

  const headerLinkButton = hasBackToWorkspaceButton ? (
    <MarketingWebsiteBackToWorkspaceButton
      variant="secondary"
      size="40"
      to={{
        pathname: buildLink(headerLinkProps[headerLink]),
        state,
      }}
    >
      <StyledAvatar
        src={data?.me.avatar?.small}
        size="32px"
        name={data?.me.name || data?.me.email || '?'}
      />
      {headerLinkProps[headerLink].buttonCopy}
    </MarketingWebsiteBackToWorkspaceButton>
  ) : (
    headerLink !== 'none' && (
      <LinkButton
        variant="secondary"
        size="32"
        to={{
          pathname: buildLink(headerLinkProps[headerLink]),
          state,
          search,
        }}
      >
        {headerLinkProps[headerLink].buttonCopy}
      </LinkButton>
    )
  )

  return (
    <Navbar.Section align="end" ml={2}>
      {content}

      <HelpButton
        size="48"
        icon={HelpChevron}
        iconPosition="right"
        {...buttonProps}
      >
        Help
      </HelpButton>
      {headerLink !== 'none' && headerLinkProps[headerLink].text && (
        <Text textStyle="copy.secondary.standard.D" mr={4}>
          {headerLinkProps[headerLink].text}
        </Text>
      )}

      {headerLinkButton}
    </Navbar.Section>
  )
}

export interface IndexLayoutHeaderProps {
  headerLink?: HeaderLinkKeys
  disableLogoLink?: boolean
}

export const IndexLayoutHeader: React.FC<IndexLayoutHeaderProps> = ({
  headerLink,
  disableLogoLink,
}) => {
  const { isScrollTop } = useScrollTop()

  return (
    <Header data-scrolltop={isScrollTop}>
      <LogoWrapper data-testid="sketch-logo">
        {disableLogoLink ? (
          <Logo />
        ) : (
          <Link to={routes.ENTRY.create({})}>
            <Logo />
          </Link>
        )}
      </LogoWrapper>
      <HeaderLink headerLink={headerLink} />
    </Header>
  )
}
