import React from 'react'

import { getRenderStatus, preRenderfarmStatus } from '@sketch/modules-common'
import { usePresences } from '../../../presence/operations'
import { AvatarItem } from '../DocumentPresenceCreatorAvatars'

import { thumbnailForPreviewFile, PreviewFile } from '../../utils'
import { DocumentGridItem, DocumentListItem } from './DocumentItem'
import { ModalRestrictor } from '@sketch/components'
import {
  ShareListItemFragment,
  SharesListVersionItemFragment,
} from '@sketch/gql-types'

// ConnectedDocumentItem was identified as a component which is crucial for the
// initial page loading performance. So we are aiming to keep this component optimal.
// One of the thins to make it possible - we want to know the minimal data which
// is necessary for this component to work.
//
// Due to this reason we are picking needed data fields here really granularly.
export interface VersionForDocumentItem
  extends Pick<
    SharesListVersionItemFragment,
    'userIsCreator' | 'updatedAt' | 'createdAt' | 'creator' | 'kind'
  > {
  document: {
    renderStatus: NonNullable<
      SharesListVersionItemFragment['document']
    >['renderStatus']
    previewFile: PreviewFile | null
  } | null
}

export interface ShareForDocumentItem
  extends Pick<
    ShareListItemFragment,
    'identifier' | 'name' | 'privacyIcon' | 'deletedAt' | 'updatedAt' | 'type'
  > {
  version: VersionForDocumentItem | null
  project: { name: string } | null
  collection?: { name: string } | null
  designSystems?: { name: string; preview: string | null }[]
  archivedAt?: string | null
  pinnedByCurrentUserAt?: string | null
}

export type RenderSharesDropdown<S> = (item: S) => React.ReactNode

export interface ConnectedDocumentItemProps<S> {
  share: S
  showProjectName?: boolean
  showCollectionName?: boolean
  hidePresences?: boolean
  onClick?: React.MouseEventHandler<HTMLElement>
  renderDropdown: RenderSharesDropdown<S>
  presentation: 'grid' | 'list'
  userIdentifier?: string
  draggable?: boolean
  onDragStart?: (event: React.DragEvent<HTMLElement>) => void
}

export const ConnectedDocumentItem = <S extends ShareForDocumentItem>(
  props: ConnectedDocumentItemProps<S>
) => {
  const {
    share,
    showProjectName,
    showCollectionName,
    hidePresences,
    presentation,
    onClick,
    userIdentifier,
    renderDropdown,
    ...dragProps
  } = props

  const canSeePresences = !share?.deletedAt

  const { presences = [] } = usePresences(share.identifier, {
    skip: hidePresences || !canSeePresences,
  })

  const renderStatus = share.version?.document?.renderStatus
  const author = share.version?.creator
  const thumbnailSource = thumbnailForPreviewFile(
    share.version?.document?.previewFile
  )

  const hasPresences = presences.length > 0

  const normalizedRenderStatus = getRenderStatus(
    renderStatus || preRenderfarmStatus(!!thumbnailSource, share.updatedAt!)
  )

  const Component =
    presentation === 'grid' ? DocumentGridItem : DocumentListItem

  const avatars: AvatarItem[] =
    hasPresences && canSeePresences
      ? presences.map(({ user, action }) => ({
          avatar: user.avatarUrl,
          identifier: user.identifier,
          name: userIdentifier === user.identifier ? 'You' : user.name,
          action,
        }))
      : [
          {
            name: share.version?.userIsCreator ? 'You' : author?.name!,
            identifier: author?.identifier!,
            avatar: author?.avatar?.small!,
            action: 'editing',
          },
        ]

  // The "updatedAt" is actually a required field however the schema is wrong and needs to be updated
  // BE issue -> https://github.com/sketch-hq/Cloud/issues/19575
  const lastUpdatedDate = share.version?.updatedAt ?? share.version?.createdAt!

  return (
    <ModalRestrictor>
      <Component
        name={share.name}
        thumbnailSource={thumbnailSource}
        renderStatus={normalizedRenderStatus}
        type={share.type}
        deletedAt={share.deletedAt}
        documentSharingStatus={share.privacyIcon}
        lastUpdatedDate={lastUpdatedDate}
        isLatestVersionAutosaved={share.version?.kind === 'AUTO'}
        renderDropdown={() => renderDropdown(share)}
        projectName={showProjectName ? share.project?.name : undefined}
        collectionName={showCollectionName ? share.collection?.name : undefined}
        isEditing={hasPresences}
        onClick={onClick}
        avatars={avatars}
        designSystems={share.designSystems ?? []}
        archivedAt={share.archivedAt}
        pinned={share.pinnedByCurrentUserAt}
        {...dragProps}
      />
    </ModalRestrictor>
  )
}
