import React from 'react'
import { Redirect, RouteChildrenProps } from 'react-router'
import { Location } from 'history'

import {
  Footer,
  IndexLayoutOldSubtitle,
  IndexLayoutOldTitle,
  routes,
  useFlag,
} from '@sketch/modules-common'
import { LinkButton } from '@sketch/components'

import { PrivateWorkspaceRouteExtraProps } from '../../containers'

import {
  ActionWrapper,
  BackgroundImage,
  ContinueLink,
  FooterWrapper,
  StyledIndexLayout,
  TextWrapper,
} from './WorkspaceThankYou.styles'

import img from '@sketch/icons/workspace-thank-you.png'
import img2x from '@sketch/icons/workspace-thank-you@2x.png'

type PossibleRouteState = {
  redirectTo?: string
}

interface WorkspaceThankYouProps
  extends PrivateWorkspaceRouteExtraProps,
    RouteChildrenProps {}

export const WorkspaceThankYou = (props: WorkspaceThankYouProps) => {
  const { workspace, location: untypedLocation } = props
  const workspaceId = workspace.identifier

  const location = untypedLocation as Location<PossibleRouteState>

  // Remove this FF on
  // https://github.com/sketch-hq/Cloud/issues/19536
  const isTrialAbuseOn = useFlag('trial-abuse')

  if (!isTrialAbuseOn) {
    return <Redirect to={routes.WORKSPACE_SHARES.create({ workspaceId })} />
  }

  return (
    <StyledIndexLayout
      headerLink="workspace-shares"
      showFooter={false}
      fullWidth
    >
      <TextWrapper>
        <IndexLayoutOldTitle>Thanks for trying Sketch</IndexLayoutOldTitle>
        <IndexLayoutOldSubtitle>
          Your <b>“{workspace.name}”</b> Workspace trial expired. Choose a
          subscription plan to get all collaborative features – we’ll have you
          set up and ready to do your best work in minutes.
        </IndexLayoutOldSubtitle>
        <ActionWrapper>
          <LinkButton
            variant="primary"
            size="32"
            to={routes.WORKSPACE_SUBSCRIBE.create({ workspaceId })}
          >
            Next: Choose a Plan
          </LinkButton>
          <ContinueLink
            size="smaller"
            to={
              location.state?.redirectTo ||
              routes.WORKSPACE_SHARES.create({ workspaceId })
            }
          >
            Continue to Workspace
          </ContinueLink>
        </ActionWrapper>
      </TextWrapper>
      <BackgroundImage
        srcSet={`${img} 1x, ${img2x} 2x`}
        alt="Sketch app screenshot"
      />
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </StyledIndexLayout>
  )
}
