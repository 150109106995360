import styled, { css, DefaultTheme } from 'styled-components'

import {
  AnchorButton,
  Avatar,
  Button,
  Dropdown,
  Heading,
  LinkButton,
  Navbar,
} from '@sketch/components'

import { breakpoint } from '@sketch/global-styles'

import { ReactComponent as LogoIcon } from '@sketch/icons/logo-black'

import { ReactComponent as ChevronDown } from '@sketch/icons/chevron-vertical-16'
import { ReactComponent as ExternalArrow } from '@sketch/icons/arrow-top-right-diagonal-16'

export const Wrapper = styled.div.attrs({ 'data-testid': 'index-layout' })`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 auto;
  background: ${({ theme }) => theme.colors.background.secondary.B};
`

export const HeaderContainer = styled.div`
  width: 100%;
  height: 100%;
`

export const Header = styled(Navbar)(
  ({
    theme: {
      colors,
      transitions: { duration, timing },
    },
  }) => css`
    position: sticky;
    top: 0;
    height: 80px;
    padding: 0 12px;
    z-index: 1;
    border: none;
    background: transparent;
    flex-shrink: 0;

    ::before {
      content: '';
      position: absolute;
      inset: 0;
      backdrop-filter: blur(15px);
      box-shadow: ${colors.headerSticky.shadow};
      background-color: ${colors.headerSticky.background};
      transform: translateY(-185px);
      transition: transform ${duration[2]} ${timing.easeInOut};
      z-index: -1;
    }

    &&[data-scrolltop='false'] {
      ::before {
        transform: translateY(0px);
      }
    }

    ${breakpoint('sm')`
      padding: 0 40px;
    `}

    ${breakpoint('md')`
      padding: 0 120px;
    `}
  `
)

export const StyledAvatar = styled(Avatar)`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  border-radius: ${({ theme }) => theme.radii.rounded};
  border: 1px solid ${({ theme }) => theme.colors.background.tertiary.B};
`

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 56px;

  ${breakpoint('base', 'sm')`
    border-right: none;
  `}
`

export const FooterWrapper = styled.div`
  margin-top: auto;

  footer {
    padding-top: 40px;
  }
`

export const Separator = styled.hr`
  display: block;
  width: 1px; /* stylelint-disable scales/space */
  height: 24px;
  background-color: ${({ theme }) => theme.colors.background.tertiary.B};
  margin: 0 32px;
`

export const Main = styled.main<{
  isCreatingWorkspace?: boolean
  fullWidth?: boolean
  maxContainerWidth?: string
}>`
  width: 100%;

  ${({ isCreatingWorkspace }) =>
    isCreatingWorkspace
      ? css`
          width: 500px;
          margin-top: 32px;

          ${breakpoint('base', 'sm')`
            width: auto;
          `};
        `
      : css`
          margin-top: 85px;
        `}

  max-width: ${({ fullWidth, maxContainerWidth }) =>
    fullWidth ? '100%' : maxContainerWidth ? maxContainerWidth : '500px'};

  ${breakpoint('base', 'sm')`
    padding: 0 16px;
    margin-top: 33px;
  `};
`

export const Subtitle = styled(Heading.H2)`
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  font-size: ${({ theme }) => theme.fontSizes.H};
  line-height: 1.4;
  text-align: center;
  font-weight: 400;

  margin-top: 8px;
  margin-bottom: 24px;

  ${breakpoint('sm')`
      font-size: 1.25rem;
      margin-top: 8px;
  `}
`

export const Title = styled.h1(
  ({ theme }) => css`
    color: ${theme.colors.foreground.secondary.A};
    margin-top: 0px;
    margin-bottom: 0;
    font-size: ${theme.fontSizes.I};
    text-align: center;
    line-height: 1;

    + :not(${/* sc-selector */ Subtitle}) {
      margin-top: 32px;
    }

    + ${Subtitle} {
      font-size: ${theme.fontSizes.E};
    }

    ${breakpoint('base', 'sm')`
      font-size: ${theme.fontSizes.H};
    `}
  `
)

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};

  /** In mobile, the sidebar disappears so we don't need the margin */
  ${breakpoint('base', 'sm')`
     margin-left: 0;
  `}
`

export const Logo = styled(LogoIcon)`
  width: 32px;
  height: 32px;
  margin: 0 auto;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
`

// Common styles between Download and Back to Workspace buttons
// These two buttons follow the same styles as the Marketing Website ones (Sketch.com)
const marketingWebsiteStyles = (theme: DefaultTheme) => css`
  color: ${theme.colors.foreground.secondary.A};
  background-color: ${theme.colors.background.secondary.A};
  background-clip: padding-box;
  border: 1px solid ${theme.colors.border.A};
  margin-right: 4px;

  transition: background-color ${theme.transitions.duration[1]}
    ${theme.transitions.timing.easeInOut};

  &:hover {
    color: ${theme.colors.foreground.secondary.A};
    background-color: ${theme.colors.background.secondary.B};
  }

  &:focus {
    border-color: transparent;
    outline: none;
    box-shadow: 0 0 0 4px ${theme.colors.shadow.inner};
  }

  &:active {
    background-color: ${theme.colors.background.secondary.C};
    // :active is also :focus, so enforce non-focus styling
    border-color: ${theme.colors.border.A};
    box-shadow: 0 1px 2px ${theme.colors.shadow.inner};
  }
`

/* This button should look exactly like the one in the Header for the Marketing Website (sketch.com) */
export const MarketingWebsiteAnchorButton = styled(AnchorButton)(
  ({ theme }) => css`
    ${marketingWebsiteStyles(theme)}
  `
)

export const MarketingWebsiteBackToWorkspaceButton = styled(LinkButton)(
  ({ theme }) => css`
    ${marketingWebsiteStyles(theme)}
  `
)

export const HelpButton = styled(Button).attrs({ variant: 'tertiary' })`
  background: none;

  &:hover {
    background: none;

    /* This color doesn't seem to exist on the prism library however we are replicating production */
    color: ${({ theme }) =>
      theme._name === 'dark'
        ? theme.colors.foreground.secondary.B
        : '#0000007a'};
  }
`

export const HelpChevron = styled(ChevronDown)`
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  opacity: 0.32;

  width: 16px;
  height: 16px;
`

export const HelpDropdownAnchor = styled(Dropdown.Item.Anchor)`
  font-size: 0.9375rem;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};

  display: flex;
  align-items: center;
`

export const HelpExternalArrow = styled(ExternalArrow)`
  margin-left: 8px;
  width: 16px;
  height: 16px;
  opacity: 0.32;
`
