import styled, { css } from 'styled-components'
import { ReactComponent as MagGlass } from '@sketch/icons/magnifying-glass-24'

import {
  Button,
  Filter,
  FilterFormContent,
  FilterSearchInput,
  Input,
} from '@sketch/components'
import { breakpoint } from '@sketch/global-styles'

export const Wrapper = styled.div<{ $isExpanded: boolean }>(
  ({ theme, $isExpanded }) => css`
    display: flex;
    margin-left: auto;
    max-width: ${$isExpanded ? '100%' : '50px'};
    overflow: hidden;

    ${SearchButton} {
      opacity: ${$isExpanded ? '0' : '1'};
    }

    will-change: max-width;
    transition: max-width ${theme.transitions.timing.easeInOut}
      ${theme.transitions.duration[0]};
  `
)

export const SearchField = styled(Filter)(
  ({ theme }) => css`
    ${FilterFormContent}, ${FilterSearchInput} {
      height: 32px;
    }

    ${Input} {
      position: relative;
      width: 100%;
      background: none;
      border-color: ${theme.colors.border.A};
      /* Avoid the focus ring from being clipped by the overflow hidden */
      outline-offset: -2px;
    }
  `
)

export const MagIcon = styled(MagGlass)`
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.colors.foreground.secondary.C};
`

export const SearchButton = styled(Button)(
  ({ theme }) => css`
    box-shadow: none;
    display: flex; /* Remove the line-height from pushing the button up */
    opacity: 1;
    transition: opacity ${theme.transitions.timing.easeInOut}
      ${theme.transitions.duration[0]};

    /**
        hack because, if we just set the button to display none,
        the search loupe on the filter looks funky
        but the goal here is to hide the button on mobile widths
       */
    ${breakpoint('base', 'xs')`
      width: 1px;
      overflow: hidden;
      gap: 0;
      padding: 0;
      margin: 0;
  `}
  `
)
