import React, { useState } from 'react'

import { AsyncButton, Tooltip } from '@sketch/components'
import { PlanPicker } from '../../components'
import BillSimulation from '../../components/BillSimulation'
import AddEditors from '../../components/AddEditors'
import { BusinessContactBanner } from '../../components/BusinessContactBanner'

// GQL
import {
  BillingPlanFragment,
  useDraftBillingSummaryQuery,
} from '@sketch/gql-types'

import {
  Wrapper,
  Container,
  PlanPickerWrapper,
  Section,
  BillSummaryWrapper,
  TextWithTooltip,
  RadioButtonContainer,
  TooltipText,
  QuestionMarkIcon,
  BillingCycleRadioButtons,
  StyledPill,
  RadioButtonStyled,
} from './WorkspaceSubscribePlan.styles'
import { H2 } from '../../views/WorkspaceSubscribeView/WorkspaceSubscribeView.styles'

import { DiscountError } from '../../components/Discount'

export interface WorkspaceSubscribePlanProps {
  plans: BillingPlanFragment[]
  selectedPlan: BillingPlanFragment
  onlyBusinessPlansAllowed: boolean
  onPlanChange: (plan: BillingPlanFragment) => void
  onPaymentDetails: () => Promise<void>
  numberEditors: number
  numberOfExtraSeats?: number
  onEditorsChange?: (editors: number) => void
  discountCode?: string
  onValidDiscountCode: (discountCode: string) => void
  onClearDiscountCode?: () => void
  discountError?: DiscountError
  onUpdateDiscountError: (discountError?: DiscountError) => void
  trialEnd: string
}

const TOOLTIP_TEXT = (
  <>
    <TooltipText>
      <b>How Pricing Works</b>
    </TooltipText>
    <TooltipText>
      <b>Viewers</b> can browse, inspect and comment on documents in the web
      app. Adding Viewers is free!
    </TooltipText>
    <TooltipText>
      <b>Editors</b> can do all of the above, and create and edit documents in
      the Mac app. You can add more Editors to the Workspace later.
    </TooltipText>
  </>
)

const getPlanProductSortValue = (plan: BillingPlanFragment): number => {
  const PLAN_SORT_VALUES = {
    STUDENT: 0,
    ESSENTIAL: 1,
    TEAMS: 2,
    BUSINESS: 3,
  }

  return PLAN_SORT_VALUES[plan.product]
}

export const WorkspaceSubscribePlan: React.FC<WorkspaceSubscribePlanProps> = ({
  plans,
  selectedPlan,
  onPlanChange,
  onPaymentDetails,
  numberEditors,
  onEditorsChange,
  numberOfExtraSeats,
  onlyBusinessPlansAllowed,
  discountCode,
  onValidDiscountCode,
  onClearDiscountCode,
  discountError,
  onUpdateDiscountError,
  trialEnd,
}) => {
  const [tooltipVisible, setTooltipVisible] = useState(false)

  const { data, error } = useDraftBillingSummaryQuery({
    variables: {
      planId: selectedPlan?.id || '',
      seats: numberEditors,
    },
    skip: !selectedPlan,
  })

  const alternateBillingCyclePlan = (
    plan: BillingPlanFragment,
    plans: BillingPlanFragment[]
  ) => {
    const targetType = plan.type === 'MONTHLY' ? 'YEARLY' : 'MONTHLY'

    return plans.find(p => p.product === plan?.product && p.type === targetType)
  }

  const hasAlternativeBillingCyclePlan = !!alternateBillingCyclePlan(
    selectedPlan,
    plans
  )

  const editorCost = data
    ? `${data.draftBillingSummary.billingCycleAmount}` // Type casting to a string to avoid 0 being falsy
    : undefined
  const isDisabled = Boolean(!selectedPlan || editorCost === '0' || error)

  const handleBillingCycleChange = () => {
    const alternativePlan = alternateBillingCyclePlan(selectedPlan, plans)

    if (selectedPlan !== alternativePlan && !!alternativePlan) {
      handlePlanChange(alternativePlan)
    }
  }
  const handlePlanChange = (plan: BillingPlanFragment) => {
    onPlanChange(plan)
    onClearDiscountCode?.()
  }

  const handleContinueToPayment = async () => {
    // Clear any discount errors than can exist
    onUpdateDiscountError?.()

    onPaymentDetails()
  }

  const sortedPlans = plans.sort((a, b) => {
    const aValue = getPlanProductSortValue(a)
    const bValue = getPlanProductSortValue(b)
    return aValue - bValue
  })
  const yearlyPlans = sortedPlans.filter(({ type }) => type === 'YEARLY')
  const monthlyPlans = sortedPlans.filter(({ type }) => type === 'MONTHLY')

  const businessPlans = yearlyPlans.filter(
    ({ product }) => product === 'BUSINESS'
  )
  const selectedPlans =
    selectedPlan.type === 'MONTHLY'
      ? [...monthlyPlans, ...businessPlans]
      : yearlyPlans
  return (
    <Wrapper>
      <Container>
        <div>
          <Section>
            <H2>Choose a Plan</H2>

            <TextWithTooltip>
              You only pay for users who edit Sketch documents
              <Tooltip
                disableWhenTouchDevice={false}
                tooltipContainerAs="span"
                content={TOOLTIP_TEXT}
              >
                <QuestionMarkIcon />
              </Tooltip>
            </TextWithTooltip>
            <BillingCycleRadioButtons>
              <RadioButtonContainer>
                <RadioButtonStyled
                  label="Pay yearly"
                  value="YEARLY"
                  onChange={handleBillingCycleChange}
                  checked={selectedPlan.type === 'YEARLY'}
                  data-testid="yearly-radio-button"
                />
                <StyledPill variant="brand">Save 16%</StyledPill>
              </RadioButtonContainer>
              <RadioButtonContainer
                // using a controlled Tooltip here because there's with the card disabled
                // events are not propagated leading to the Tooltip not hiding in same mouse movements situations
                onMouseEnter={() =>
                  setTooltipVisible(!hasAlternativeBillingCyclePlan)
                }
                onMouseLeave={() => setTooltipVisible(false)}
              >
                <Tooltip
                  placement="right"
                  visible={tooltipVisible}
                  content="Available on yearly billing only"
                >
                  <RadioButtonStyled
                    label="Pay monthly"
                    value="MONTHLY"
                    data-testid="monthly-radio-button"
                    onChange={handleBillingCycleChange}
                    disabled={!hasAlternativeBillingCyclePlan}
                    checked={selectedPlan.type === 'MONTHLY'}
                  />
                </Tooltip>
              </RadioButtonContainer>
            </BillingCycleRadioButtons>
            <PlanPickerWrapper numPlans={selectedPlans.length}>
              <PlanPicker
                hasAutomatedPlanProvisionFFOn={true}
                plans={selectedPlans}
                onlyBusinessPlansAllowed={onlyBusinessPlansAllowed}
                onChange={handlePlanChange}
                selectedPlan={selectedPlan}
                isDisabled={editorCost === '0'}
              />
            </PlanPickerWrapper>
          </Section>
          <BusinessContactBanner />
        </div>

        <div>
          <Section>
            <H2>Add Editors</H2>
            <AddEditors
              seats={numberEditors}
              onChange={editors => onEditorsChange?.(editors)}
            />
          </Section>
          <BillSummaryWrapper>
            <H2>Bill Summary</H2>
            <BillSimulation
              plan={selectedPlan!}
              trialEnd={trialEnd}
              numberEditors={numberEditors}
              numberExtraSeats={numberOfExtraSeats || 0}
              showDiscountInput={true}
              discountCode={discountCode}
              onValidDiscountCode={onValidDiscountCode}
              onClearDiscountCode={onClearDiscountCode}
              discountError={discountError}
              onUpdateDiscountError={onUpdateDiscountError}
            />
          </BillSummaryWrapper>

          <AsyncButton
            variant="primary"
            fill
            onClick={handleContinueToPayment}
            disabled={isDisabled}
          >
            Continue to Billing Details
          </AsyncButton>
        </div>
      </Container>
    </Wrapper>
  )
}
