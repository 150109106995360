import {
  useGetWorkspacesQuery,
  WorkspaceMinimalFragment,
} from '@sketch/gql-types'
import { useMemo } from 'react'
import { isMemberWorkspace } from '../../../../workspace/utils'
import { isTruthy } from '@sketch/utils'

const normalizeWorkspaces = (
  workspaces: WorkspaceMinimalFragment[],
  currentWorkspaceId: string
) => {
  const editableWorkspaces = (workspaces || []).filter(
    workspace =>
      isMemberWorkspace(workspace) &&
      (workspace.userCanEdit || workspace.userRole === 'ADMIN')
  )

  // push current workspace to the top
  let currentWorkspace = null
  const otherWorkspaces = []

  for (const workspace of editableWorkspaces) {
    if (workspace.identifier === currentWorkspaceId) {
      currentWorkspace = workspace
    } else {
      otherWorkspaces.push(workspace)
    }
  }

  return [currentWorkspace, ...otherWorkspaces].filter(isTruthy)
}

export const useGetWorkspaces = (currentWorkspaceId: string) => {
  const { data, loading, error } = useGetWorkspacesQuery({
    onCompleted() {
      // Don't use onCompleted - it is not fired when the query is fetched from the cache
      // see: https://github.com/apollographql/react-apollo/issues/2177
    },
  })

  const workspaces = useMemo(
    () => normalizeWorkspaces(data?.me?.workspaces || [], currentWorkspaceId),
    [data?.me?.workspaces, currentWorkspaceId]
  )

  return { workspaces, loading, error }
}
