import React, { useState, useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import * as Collapsible from '@radix-ui/react-collapsible'

import { useOnEvent } from '@sketch/utils'
import {
  useModalContext,
  SelectDropdownActionDropdown,
} from '@sketch/components'
import {
  ShareDropPayload,
  DroppableLinkItem,
  RouteParams,
} from '@sketch/modules-common'
import { ConfirmMoveModal } from '../../modals'

import { RenameInlineEditor } from '../InlineEditor'
import ProjectDropdown from '../ProjectDropdown'

import { useRemoveSharesFromCollection } from '../../../collections/operations'
import { useToast } from '@sketch/toasts'

import {
  CollapsibleButton,
  ChevronRight,
  ChevronDown,
  NestedProjectsWrapper,
} from './ProjectListItem.styles'

import { useGetProjects } from '../../operations'

import { ReactComponent as PinIcon } from '@sketch/icons/pin-16'

import { ProjectProps } from './ProjectListItem.types'

import {
  privacyIcon,
  hasProject,
  calculateNestedLevel,
} from './ProjectListItem.utils'

export const ProjectListItem: React.FC<ProjectProps> = props => {
  const {
    link,
    project,
    nestedProjects,
    workspace,
    refToLast,
    setEditedItem,
    forceHighlight = false,
  } = props

  const { showModal } = useModalContext()
  const { showToast } = useToast()
  const [isRenaming, setIsRenaming] = useState(false)
  const { collectionId: originCollectionId } = useParams<
    RouteParams<'WORKSPACE_COLLECTION'>
  >()
  const { projectId } = useParams<RouteParams<'WORKSPACE_PROJECT'>>()

  // An active nested projects is expanded by default
  const [nestedOpen, setNestedOpen] = React.useState(
    hasProject(nestedProjects, projectId)
  )

  const { projectsById } = useGetProjects({ workspaceId: workspace.identifier })

  const location = useLocation()

  // Let's make sure we show the active project item when the route changes
  useEffect(() => {
    if (hasProject(nestedProjects, projectId)) {
      setNestedOpen(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  // We want to show expanded newly created nested projects
  useOnEvent('nestedProjectCreated', ({ projectIdentifier }) => {
    if (hasProject(nestedProjects, projectIdentifier)) {
      setNestedOpen(true)
    }
  })

  const removeSharesFromCollection = useRemoveSharesFromCollection({
    projectIdentifier: project.identifier,
    onCompleted: () => {
      showToast(`Document removed from this collection`)
    },
  })

  const onDropped = ({ share }: ShareDropPayload) => {
    /**
     * No share nothing to move ¯\_(ツ)_/¯
     */
    if (!share) {
      return
    }

    /**
     * If the project identifier is different from the one dropped, move it please
     */
    if (share.project?.identifier !== project.identifier) {
      showModal(ConfirmMoveModal, {
        workspaceIdentifier: workspace.identifier,
        share,
        newProject: project,
      })

      return
    }

    // if we're moving this share to the same project identifier + we're also coming from a collection
    // then what we actually want is to remove this share from this collection!
    if (originCollectionId) {
      removeSharesFromCollection({
        variables: {
          identifier: originCollectionId,
          shareIdentifiers: [share.identifier],
        },
      })

      return
    }

    /**
     * Given that project identifier is the same and there's no collection set
     * we friendly remind that the document already exists in that project
     */
    showToast('Document is already on this project')
  }

  const workspaceId = workspace.identifier

  const toggleEditor = () => setIsRenaming(!isRenaming)
  const onCompleted = () => {
    setEditedItem?.(project.identifier)
  }

  if (isRenaming) {
    return (
      <RenameInlineEditor
        project={project}
        workspaceId={workspaceId}
        placeholder={project.name || 'Folder'}
        toggleEditor={toggleEditor}
        onCompleted={onCompleted}
      />
    )
  }

  // We should show the pin icon when FF is enabled
  // when the project is pinned and when it's not
  // a private project, as the lock icon should prevail
  // https://www.sketch.com/s/d57ba117-58de-4a49-b217-0720404f0a08/a/oM73ayj
  const showPinIcon =
    project.pinnedByCurrentUserAt &&
    project.privacyIcon !== 'INVITED_WORKSPACE_MEMBERS'

  // This function decides which icon needs to be shown for the project, these
  // situations are covered:
  // - The project is pinned so we show the pin icon
  // - The project is not pinned so we show a folder icon
  // - The project is private so we show a lock icon
  // - The project is shared with guests so we show a people icon
  // - The project has nested project, so we show chevrons on hover
  const setIcon = () => {
    const nestedProjectIcons = {
      ...(nestedProjects.length && {
        hoverIcon: nestedOpen ? ChevronDown : ChevronRight,
      }),
      ...(nestedProjects.length && {
        hoverIconActive: nestedOpen ? ChevronDown : ChevronRight,
      }),
    }

    return showPinIcon
      ? {
          icon: PinIcon,
          activeIcon: PinIcon,
          label: 'Pin icon',
          ...nestedProjectIcons,
        }
      : {
          icon: privacyIcon(project).component,
          label: privacyIcon(project).label,
          activeIcon: privacyIcon(project, true).component,
          ...nestedProjectIcons,
        }
  }

  const Trigger: React.FC = ({ children }) => (
    <Collapsible.Trigger asChild>
      <CollapsibleButton>{children}</CollapsibleButton>
    </Collapsible.Trigger>
  )
  const icon = setIcon()

  return nestedProjects.length ? (
    <Collapsible.Root open={nestedOpen} onOpenChange={setNestedOpen}>
      <DroppableLinkItem
        icon={icon}
        iconLabel={icon.label}
        iconWrapper={Trigger}
        to={link}
        text={project.name}
        onDropped={onDropped}
        action={
          <SelectDropdownActionDropdown
            text="Rename & More…"
            content={
              <ProjectDropdown
                workspace={workspace}
                project={project}
                onRename={() => setIsRenaming(!isRenaming)}
              />
            }
          />
        }
        ref={refToLast}
        aria-label={`Link to ${project.name}`}
        forceHighlight={forceHighlight}
      />
      <Collapsible.Content>
        <NestedProjectsWrapper
          level={calculateNestedLevel(projectsById, project.identifier)}
        >
          {nestedProjects.map(projectLink => (
            <ProjectListItem
              key={projectLink.project.identifier}
              project={projectLink.project}
              link={projectLink.link}
              nestedProjects={projectLink.nestedProjects}
              userCanEdit={props.userCanEdit}
              userCanAdminister={props.userCanAdminister}
              workspace={workspace}
              refToLast={refToLast}
              setEditedItem={setEditedItem}
              forceHighlight={forceHighlight}
            />
          ))}
        </NestedProjectsWrapper>
      </Collapsible.Content>
    </Collapsible.Root>
  ) : (
    <DroppableLinkItem
      icon={icon}
      iconLabel={icon.label}
      to={link}
      text={project.name}
      onDropped={onDropped}
      action={
        <SelectDropdownActionDropdown
          text="Rename & More…"
          content={
            <ProjectDropdown
              workspace={workspace}
              project={project}
              onRename={() => setIsRenaming(!isRenaming)}
            />
          }
        />
      }
      ref={refToLast}
      aria-label={`Link to ${project.name}`}
      forceHighlight={forceHighlight}
    />
  )
}
