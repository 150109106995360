import styled from 'styled-components'
import * as Collapsible from '@radix-ui/react-collapsible'

import { ReactComponent as ExclamationMarkIcon } from '@sketch/icons/exclamation-mark-triangle-24'

import BaseProjectsHeader from '../ProjectsHeader'
import { ButtonUnstyled, SkeletonSidebarList } from '@sketch/components'
import { breakpoint } from '@sketch/global-styles'

export const MenuWrapper = styled.div`
  padding: 0;
  width: 100%;
  flex: 1;
`

export const ProjectsHeader = styled(BaseProjectsHeader)`
  margin-bottom: 4px;
  overflow-y: hidden;
`

export const WarningIcon = styled(ExclamationMarkIcon)`
  margin-bottom: 8px;
  opacity: 0.3;
`

export const Error = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const ErrorText = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.C};
`

export const RefetchButton = styled(ButtonUnstyled)`
  color: ${({ theme }) => theme.colors.sketch.A};
  font-size: ${({ theme }) => theme.fontSizes.C};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  text-decoration: underline;
  cursor: pointer;

  border: 0;
  background: none;
  padding: 0;
`

export const StyledSkeletonSidebarList = styled(SkeletonSidebarList)`
  margin-top: 8px;
`

export const StyledCollapsibleTrigger = styled(Collapsible.Trigger)`
  margin-left: 32px;
`

export const StyledCollapsibleContent = styled(Collapsible.Content)`
  ${breakpoint('base', 'sm')`
    margin-top: 8px;
  `}
`

export const StyledTrigger = styled(Collapsible.Trigger)`
  width: 40px;
`
