import { GenericError } from '@sketch/components'
import React, { useEffect, useRef } from 'react'
import { projectIcon } from './icon'
import { useTreeNodeState, useTreeState } from './state'
import { TreeNode, TreeNodeSkeleton } from './TreeNode'
import { Icon } from './TreeNode.styles'

import { useGetProjectsLazy } from '../../operations'
import { useOnListChange } from './state/useOnListChange'
import { ProjectsTreeDataNode } from './state/treeState.context'
import { ProjectInSidebarAndHeaderFragment } from '@sketch/gql-types'

export interface ProjectTreeNodeProps {
  node: ProjectsTreeDataNode
  depth: number
}

export function ProjectTreeNode(props: ProjectTreeNodeProps) {
  const { node, depth } = props
  const { payload: project, id, children } = node

  if (project?.__typename !== 'Project') return null

  const standardOrDraftProject = project as Partial<ProjectInSidebarAndHeaderFragment>

  return (
    <TreeNode
      key={id}
      id={id}
      depth={depth}
      icon={open => (
        <Icon>
          {projectIcon({ project: standardOrDraftProject, isOpen: open })}
        </Icon>
      )}
      hasChildren={children.length > 0}
      label={<div>{project.name}</div>}
    >
      {children.map(child => {
        return <ProjectTreeNode key={child.id} node={child} depth={depth + 1} />
      })}
    </TreeNode>
  )
}

export interface ProjectsTreeNodesProps {
  workspaceId: string
}

export const ProjectTreeNodes = (props: ProjectsTreeNodesProps) => {
  const { workspaceId } = props

  const treeState = useTreeState()
  const { isOpen } = useTreeNodeState(workspaceId)
  const workspaceNode = treeState.tree.getNode(workspaceId)

  const containerRef = useRef<HTMLDivElement>(null)

  const { getProjects, loading, error, allProjects } = useGetProjectsLazy()

  useOnListChange(allProjects || [], (items, isFirstChange) => {
    treeState.setChildrenTree({
      list: items,
      id: x => x.identifier,
      parentId: x => x.parentProjectIdentifier || workspaceId,
    })

    if (
      isFirstChange &&
      treeState.currentDestinationId &&
      allProjects?.find(x => x.identifier === treeState.currentDestinationId)
    ) {
      treeState.setOpenToOnce(treeState.currentDestinationId)
    }
  })

  useEffect(() => {
    if (isOpen) getProjects({ variables: { workspaceId } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  if (loading) {
    return (
      <div>
        <TreeNodeSkeleton depth={1} />
        <TreeNodeSkeleton depth={1} />
        <TreeNodeSkeleton depth={1} />
      </div>
    )
  }

  if (error) {
    return <GenericError />
  }

  const projectNodes = workspaceNode?.children || []

  return (
    <div ref={containerRef} role="group">
      {projectNodes.map(x => (
        <ProjectTreeNode key={x.id} depth={1} node={x} />
      ))}
    </div>
  )
}
