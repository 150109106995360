import React, { useEffect } from 'react'
import styled from 'styled-components'
import * as yup from 'yup'
import { Formik, FormikHelpers } from 'formik'
import { CardNumberElement } from '@stripe/react-stripe-js'

import {
  Caption,
  Button,
  Form,
  Modal,
  ModalInjectedProps,
  useStripe,
} from '@sketch/components'
import { useToast } from '@sketch/toasts'

import CreditCardForm from '../../components/CreditCardForm'
import CreditCards from '../../components/CreditCardLogos'

// Validations
import { createFormikValidator } from '../../components/StripeField'

import {
  useUpdatePaymentMethodMutation,
  useCreateSetupIntentMutation,
} from '@sketch/gql-types'

import Fieldset from '../../components/Fieldset'

const Strong = styled.strong`
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`

// Formik Validation
const VALIDATION_SCHEMA = yup.object().shape({
  cardName: yup.string().trim().required('Name on Card is required'),
})

const INITIAL_VALUES = {
  cardName: '',

  /**
   * These fields are related with the credit-card
   * data, in order to get formik like validation
   * we need to include them in the "initial values"
   *
   * these values are only placeholders, in the end,
   * none of their values will be used
   */
  cardNumber: undefined,
  cardCvc: undefined,
  cardExpirationDate: undefined,
}

type FormValues = typeof INITIAL_VALUES

interface EditPaymentMethodModalProps extends ModalInjectedProps {
  customerId: string
  onPaymentUpdated?: () => Promise<void>
}

const stripeValidation = createFormikValidator(
  ['cardCvc', 'cardExpirationDate', 'cardNumber'],
  {
    cardNumber: 'Card Number is required',
    cardCvc: 'CVC is required',
    cardExpirationDate: 'Expiry Date is required',
  }
)

/**
 * EditPaymentMethodModal
 *
 * Renders the modal that allows the user to edit the payment method
 * in the Workspace Settings > Billing page inside the Payment Details panel
 */
const EditPaymentMethodModal: React.FC<EditPaymentMethodModalProps> = ({
  customerId,
  onPaymentUpdated,
  hideModal,
}) => {
  const { showToast } = useToast()
  const { load, status, stripe, elements } = useStripe()

  /* Load stripe */
  useEffect(() => {
    if (status !== 'success') {
      load()
    }
  }, [load, status])

  const [
    createSetupIntent,
    { loading: loadingValidate },
  ] = useCreateSetupIntentMutation({
    onError: 'show-toast',
    variables: {
      input: {
        customerId,
      },
    },
  })

  const [
    updatePaymentMethod,
    { loading: loadingNew },
  ] = useUpdatePaymentMethodMutation({
    onError: 'show-toast',
  })

  const handleOnSubmitPaymentMethod = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>
  ) => {
    if (!stripe || !elements) {
      showToast('Stripe module is not available', 'negative')
      return
    } else {
      const { data } = await createSetupIntent()

      const cardElement = elements.getElement(CardNumberElement)

      if (!data?.createSetupIntent?.pendingScaToken) {
        showToast('Error creating setup intent', 'negative')
        return
      }

      const response = await stripe.confirmCardSetup(
        data?.createSetupIntent?.pendingScaToken,
        {
          payment_method: {
            card: cardElement!,
          },
        }
      )

      if (response && response.error) {
        showToast(
          response?.error.message ||
            'An error has occurred, try again or use a different credit card.',
          'negative'
        )
        return
      }

      if (response.setupIntent?.status === 'succeeded') {
        if (!response.setupIntent.payment_method) {
          showToast('No payment method id available', 'negative')
          return
        }

        const { data, error } = await updatePaymentMethod({
          variables: {
            input: {
              customerId,
              paymentMethodId: response.setupIntent.payment_method as string,
            },
          },
        })

        if (error) {
          showToast(
            'Error attaching the Credit Card to your account',
            'negative'
          )
          return
        }

        await onPaymentUpdated?.()

        if (data?.updatePaymentMethod?.customer?.paymentDetails) {
          showToast('Your payment method has been updated')
          hideModal()
        }
      }

      actions.setSubmitting(false)
    }
  }

  const loading = loadingValidate || loadingNew
  return (
    <Modal onCancel={hideModal}>
      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={VALIDATION_SCHEMA}
        validate={stripeValidation}
        onSubmit={handleOnSubmitPaymentMethod}
      >
        {({ isSubmitting, errors }) => (
          <Form>
            <Fieldset disabled={isSubmitting || loading}>
              <Modal.Header>Payment Method</Modal.Header>
              <Modal.Body>
                <CreditCards />
                <CreditCardForm<FormValues> />
                <Caption>
                  <br />
                  This card <Strong>will replace</Strong> your current card and
                  be used on your next bill.
                </Caption>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  disabled={isSubmitting}
                  onClick={hideModal}
                  type="button"
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={
                    isSubmitting || loading || Object.keys(errors).length > 0
                  }
                  loading={isSubmitting || loading}
                >
                  Update Billing Details
                </Button>
              </Modal.Footer>
            </Fieldset>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default EditPaymentMethodModal
