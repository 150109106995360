// GraphQL
import { useGetNestedProjectsQuery } from '@sketch/gql-types'

interface UseGetNestedProjectsProps {
  projectIdentifier: string
  skip?: boolean
}

export const useGetNestedProjects = ({
  projectIdentifier,
  skip,
}: UseGetNestedProjectsProps) => {
  return useGetNestedProjectsQuery({
    variables: {
      projectIdentifier,
      after: null,
    },
    shouldInvalidatePrevious: (prev, curr) =>
      prev?.projectIdentifier !== curr?.projectIdentifier,
    skip,
  })
}
