import { useCallback, useEffect, useMemo } from 'react'
import { CollapsibleTree } from './CollapsibleTree'
import { TreeState } from './TreeState'

const createHandlers = (treeState: TreeState<{}>) => {
  const handleArrowUp = () => {
    const focused = treeState.focusedId || treeState.selectedId
    if (!focused) return

    const prevNode = treeState.tree.getPrevVisibleNode(focused)

    if (!prevNode) return
    treeState.focusNode(prevNode.id)
  }

  const handleArrowDown = () => {
    const focused = treeState.focusedId
    if (!focused) return

    const nextNode = treeState.tree.getNextVisibleNode(focused)
    if (!nextNode) return
    treeState.focusNode(nextNode.id)
  }

  const handleArrowLeft = () => {
    const focused = treeState.focusedId
    if (!focused) return

    const currentNode = treeState.tree.getNode(focused)
    if (!currentNode) return

    if (treeState.isOpen(focused)) {
      // If the current node is expanded, collapse it
      treeState.onOpenChange(focused, false)
    } else {
      // If it's already collapsed, move to parent
      const parentNode = treeState.tree.getParent(focused)
      if (parentNode && parentNode.id !== CollapsibleTree.ROOT_ID) {
        treeState.focusNode(parentNode.id)
      }
    }
  }

  const handleArrowRight = () => {
    const focusedId = treeState.focusedId
    if (!focusedId) return

    const focusedNode = treeState.tree.getNode(
      focusedId || CollapsibleTree.ROOT_ID
    )

    if (!treeState.isOpen(focusedId)) {
      // If the current node is collapsed - expand it
      treeState.onOpenChange(focusedId, true)
    } else if (focusedNode?.children.length) {
      // If the current node is expanded, move to the first child
      const firstChild = treeState.tree.getFirstChild(focusedNode.id)
      if (firstChild) treeState.focusNode(firstChild.id)
    }

    // If the current node is expanded and has no children, do nothing
  }

  const handleHome = () => {
    const node = treeState.tree.getFirstVisibleNode()
    if (!node) return
    treeState.focusNode(node.id)
  }

  const handleEnd = () => {
    const node = treeState.tree.getLastVisibleNode()
    if (!node) return
    treeState.focusNode(node.id)
  }

  const handleSelect = () => {
    const focused = treeState.focusedId
    if (!focused) return

    treeState.onSelect(focused)
  }

  return {
    handleArrowUp,
    handleArrowDown,
    handleArrowLeft,
    handleArrowRight,
    handleHome,
    handleEnd,
    handleSelect,
  }
}

export const useTreeKeyboardNavigation = (
  containerRef: React.RefObject<HTMLElement>,
  treeState: TreeState<any>
) => {
  const handlers = useMemo(() => createHandlers(treeState), [treeState])

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (!containerRef.current) return
      if (!containerRef.current.contains(document.activeElement)) {
        return
      }

      switch (event.key) {
        case 'ArrowUp':
          event.preventDefault()
          handlers.handleArrowUp()
          break
        case 'ArrowDown':
          event.preventDefault()
          handlers.handleArrowDown()
          break
        case 'ArrowLeft':
          event.preventDefault()
          handlers.handleArrowLeft()
          break
        case 'ArrowRight':
          event.preventDefault()
          handlers.handleArrowRight()
          break
        case 'Home':
          event.preventDefault()
          handlers.handleHome()
          break
        case 'End':
          event.preventDefault()
          handlers.handleEnd()
          break
        case 'Enter':
        case ' ':
          event.preventDefault()
          handlers.handleSelect()
          break
      }
    },
    [containerRef, handlers]
  )

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [handleKeyDown])
}
