import React, { useEffect, useState } from 'react'
import { useApolloClient } from 'react-apollo'
import { isApolloError } from 'apollo-client'

import { routes } from '@sketch/modules-common'

import { clamp, castError } from '@sketch/utils'

import {
  confirmPendingSetupIntent,
  getPaymentTypeByProratedAmounts,
  getAppliedDiscount,
  ERROR_MESSAGE,
} from '../../utils'

import { getInviteLimitMessage, useMemberLimit } from '../../hooks'

import {
  Button,
  Link,
  Pluralize,
  pluralize,
  Banner,
  Modal,
  ModalInjectedProps,
  useStripe,
} from '@sketch/components'
import { useToast } from '@sketch/toasts'
import Discount from '../../components/Discount'

import {
  SummaryHeader,
  SummaryLine,
  SummaryTotalLine,
  SummaryError,
  SummaryTotalDescribed,
  SummaryPaymentMethod,
  SummaryDiscountLine,
  SummaryProrated,
  ModalScheduleDisclaimer,
  MemberInvite,
} from '../../components'

import {
  StyledSummaryWrapper,
  StyledMemberList,
  StyledModalWarning,
  ModalDialog,
  Columns,
  Column,
  DiscountWrapper,
  SubTitle,
} from './InviteMembersModal.styles'

import {
  GetWorkspaceMembershipsDocument,
  GetWorkspaceMembershipsQuery,
  GetWorkspaceMembershipsQueryVariables,
  PaymentDetailsFragment,
  BillingSeatsInfoFragment,
  useGetSeatsUpdateBillingSimulationQuery,
  useInviteWorkspaceMembersMutation,
  useApplyCouponMutation,
  CouponFragment,
  useGetPartnerQuery,
  SubscriptionInfoPlanFragment,
  CloudBillingPlanFragment,
} from '@sketch/gql-types'

// This is a valid use case of expansive types
// eslint-disable-next-line no-restricted-imports
import { WorkspaceUser } from '@sketch/gql-types/expansive'

import { Member } from '../../types'
import { isBillingHidden } from '@sketch/env-config'

const LIMIT_INVITE_SAFEGUARD = { helpText: undefined, tooltip: undefined }

const getEditors = (members: Member[]) =>
  members.filter(member => member.isEditor).length

const getViewers = (members: Member[]) =>
  members.filter(member => !member.isEditor).length

const buildWorkspaceMember = (member: Member) => ({
  email: member.email,
  accessLevel: member.isEditor ? ('EDITOR' as const) : ('VIEWER' as const),
  admin: member.isAdmin,
})

type DiscountError =
  | {
      code: string
      reason: string
    }
  | undefined

export interface InviteMembersModalProps extends ModalInjectedProps {
  customerId: string
  isOnTrial: boolean
  nextBillingCycleDate: string
  plan: SubscriptionInfoPlanFragment['currentPlan']
  seats: BillingSeatsInfoFragment
  workspaceId: string
  order?: GetWorkspaceMembershipsQueryVariables['order']
  orderDirection?: GetWorkspaceMembershipsQueryVariables['orderDirection']
  paymentDetails: PaymentDetailsFragment
  isScheduledToBeCancelled?: boolean
  isEducationWorkspace?: boolean
  isPartneredWorkspace?: boolean
  partner?: WorkspaceUser | null
  isIOS?: boolean
}

/**
 * InviteMembersModal
 *
 * Renders the Invite Members modal.
 * Can be found, for example, in the Workspace Settings > General > Members panel.
 *
 */
export const InviteMembersModal: React.FC<InviteMembersModalProps> = ({
  customerId,
  hideModal,
  isOnTrial,
  nextBillingCycleDate,
  plan,
  seats,
  workspaceId,
  order,
  orderDirection,
  paymentDetails,
  isScheduledToBeCancelled,
  cancelOnClickOutside,
  isEducationWorkspace,
  isPartneredWorkspace,
  partner,
  isIOS,
}) => {
  const { load: loadStripe } = useStripe()
  const { showToast } = useToast()
  const apolloClient = useApolloClient()
  const [members, setMembers] = useState<Member[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [
    noSeatsAvailableEducationWorkspace,
    setNoSeatsAvailableEducationWorkspace,
  ] = useState(
    // If its an Education Workspace, check for available seats
    isEducationWorkspace && seats.availableSeats <= 0
  )

  const [
    noSeatsAvailablePartneredWorkspace,
    setNoSeatsAvailablePartneredWorkspace,
  ] = useState(
    // If it's a Partnered Workspace, check for available seats
    isPartneredWorkspace && seats.availableSeats <= 0
  )

  // Used to check its type for invitee limits
  const limit = useMemberLimit(workspaceId, members.length)

  const [discountError, setDiscountError] = useState<DiscountError>(undefined)
  const [isDiscountAlreadyApplied, setIsDiscountAlreadyApplied] = useState<
    boolean | undefined
  >(undefined)
  const [parnerAlreadyExists, setParnerAlreadyExists] = useState<boolean>(false)

  /**
   * For Education and Sketch Partnered Workspaces,
   * we keep an eye on the available seats
   * and the number of members added to the list of invitees.
   * When the number of invitees (editors) fills the available seats
   * we show a banner and disable the "Editor" role on the dropdowns
   */
  useEffect(() => {
    const availableSeats = seats.availableSeats
    const numberOfEditorsToInvite = members.filter(member => member.isEditor)
      .length

    const noSeatsLeft = availableSeats - numberOfEditorsToInvite <= 0

    if (isEducationWorkspace) {
      setNoSeatsAvailableEducationWorkspace(noSeatsLeft)
    }

    if (isPartneredWorkspace) {
      setNoSeatsAvailablePartneredWorkspace(noSeatsLeft)
    }
  }, [
    isEducationWorkspace,
    isPartneredWorkspace,
    members,
    seats.availableSeats,
  ])

  const { availableSeats, scheduledSeatsTotal, currentSeatsTotal } = seats
  const numberOfEditorsAfterAvailableSeatsFilled = clamp(
    getEditors(members) - availableSeats,
    0,
    getEditors(members)
  )

  const numberOfPrepaidEditors = clamp(getEditors(members), 0, availableSeats)
  const showEditorsInSummary = !!numberOfEditorsAfterAvailableSeatsFilled
  const showPrePaidEditorsInSummary = !!availableSeats
  const hasScheduledSeatsChange = !!scheduledSeatsTotal
  const hasNewerWorkspaceMembers = members.length > 0
  const canSkipSCA = isOnTrial && isScheduledToBeCancelled
  const isSummaryVisible =
    !isEducationWorkspace && !isPartneredWorkspace && !isIOS && !isBillingHidden

  // Handlers
  const handleInvite = async (newMember: Member) => {
    // Check if the email belongs to a partner
    const partnerData = await getPartner({
      email: newMember.email,
    })

    const hasPartner = Boolean(partnerData.data.partner)
    setParnerAlreadyExists(hasPartner)
    if (hasPartner) return

    /**
     * Validate if the email already exists in the state list first
     */
    if (members.find(member => member.email === newMember.email)) {
      throw new Error('Already a Workspace Member')
    }

    /**
     * Make sure the user doesn't exist in the workspace before we add him to the list
     * not having this validation can influence the total due amount
     */
    const { data } = await apolloClient
      .query<
        GetWorkspaceMembershipsQuery,
        GetWorkspaceMembershipsQueryVariables
      >({
        query: GetWorkspaceMembershipsDocument,
        variables: { workspaceId, filter: newMember.email, limit: 10 },
        fetchPolicy: 'network-only',
      })
      .catch(() => {
        throw new Error('Something went wrong')
      })

    /**
     * Temporary fix to check if memberships returned by
     * email search (LIKE filter, instead of exact search)
     * include the provided email
     *
     * https://github.com/sketch-hq/cloud-frontend/pull/3466
     */
    const workspaceMemberships =
      data?.workspace?.memberships?.list?.entries || []

    const isWorkspaceMember = workspaceMemberships.some(
      member => member.user?.email === newMember.email && member.acceptedAt
    )

    if (isWorkspaceMember) {
      throw new Error('Already a Workspace Member')
    }

    const isAlreadyInvited = workspaceMemberships.some(
      member =>
        (member.user?.email === newMember.email && !member.acceptedAt) ||
        member.invite?.email === newMember.email
    )

    if (isAlreadyInvited) {
      throw new Error('This Member has already been invited')
    }

    setMembers(prevMembers => [
      ...prevMembers,
      {
        ...newMember,
        isEditor: isIOS ? false : newMember.isEditor,
      },
    ])
  }

  const handleRemove = (member: Member) => {
    setMembers(prevMembers =>
      prevMembers.filter(prevMember => prevMember.email !== member.email)
    )
  }

  const updateMemberRole = (member: Member) => {
    setMembers(prevMembers =>
      prevMembers.map(prevMember =>
        prevMember.email === member.email ? member : prevMember
      )
    )
  }

  const handleEditor = (clickedMember: Member) => {
    updateMemberRole({ ...clickedMember, isEditor: true })
  }
  const handleViewer = (clickedMember: Member) => {
    updateMemberRole({ ...clickedMember, isEditor: false })
  }
  const handleToggleAdmin = (clickedMember: Member) => {
    updateMemberRole({ ...clickedMember, isAdmin: !clickedMember.isAdmin })
  }

  const handleInviteMembers = async () => {
    setIsLoading(true)

    try {
      const { data } = await inviteMembers({
        variables: {
          input: {
            workspaceIdentifier: workspaceId,
            members: members.map(buildWorkspaceMember),
          },
        },
      })

      const { pendingScaToken } = data?.inviteWorkspaceMembers || {}

      /**
       * If "pendingScaToken" is present on the payload we need to do the
       * challenge with stripe to make sure the card is not INCOMPLETE
       */
      if (pendingScaToken && !canSkipSCA) {
        const stripe = await loadStripe()

        await confirmPendingSetupIntent(stripe, pendingScaToken)
      }
    } catch (error) {
      // Ignore this error, mutation errors will handle by the mutation `onError`
    }

    setIsLoading(false)
  }

  // QUERIES

  const {
    loading: isBillingSummaryLoading,
    data: billingSummaryData,
    error: billingSummaryError,
    refetch,
  } = useGetSeatsUpdateBillingSimulationQuery({
    fetchPolicy: 'network-only',
    variables: {
      customerId,
      totalSeats: currentSeatsTotal + numberOfEditorsAfterAvailableSeatsFilled,
    },
    /* Prevent unneeded queries from being done */
    skip:
      numberOfEditorsAfterAvailableSeatsFilled === 0 || isPartneredWorkspace,
    onCompleted: data => {
      if (numberOfEditorsAfterAvailableSeatsFilled === 0) {
        return
      }

      if (isDiscountAlreadyApplied === undefined) {
        setIsDiscountAlreadyApplied(
          !!data?.seatsUpdateBillingSimulation.couponInfo
        )
      }
    },
  })

  const { refetch: getPartner } = useGetPartnerQuery({
    skip: true,
  })

  // MUTATIONS
  const [
    inviteMembers,
    { loading: invitationLoading },
  ] = useInviteWorkspaceMembersMutation({
    onCompleted: () => {
      showToast(
        `${pluralize('Member', 'Members', members.length)} added successfully`,
        'positive'
      )

      hideModal()
    },
    onError: 'show-toast',
    refetchQueries: () => [
      {
        query: GetWorkspaceMembershipsDocument,
        variables: {
          workspaceId,
          order,
          orderDirection,
        } as GetWorkspaceMembershipsQueryVariables,
      },
    ],
  })

  const { prorated, extraSeatsAmount } =
    billingSummaryData?.seatsUpdateBillingSimulation || {}

  const paymentType = getPaymentTypeByProratedAmounts(
    prorated?.chargeAmount,
    prorated?.creditsAmount
  )

  const [
    applyCoupon,
    { loading: isLoadingApplyCoupon },
  ] = useApplyCouponMutation({ onError: 'unsafe-throw-exception' })

  const appliedDiscount = getAppliedDiscount(
    billingSummaryData?.seatsUpdateBillingSimulation?.couponInfo || undefined
  )

  // TEMPORARILY DISABLED DISCOUNTS UI
  // https://github.com/sketch-hq/cloud-frontend/pull/6249
  // const isDiscountFieldVisible =
  //   numberOfEditorsAfterAvailableSeatsFilled !== 0 &&
  //   hasPaymentDetails(paymentDetails) &&
  //   false
  const isDiscountFieldVisible = false

  const handleApplyDiscount = async (discountCode: string) => {
    try {
      await applyCoupon({
        variables: {
          customerId,
          promotionCode: discountCode,
        },
      })

      refetch()
      setDiscountError(undefined)
    } catch (e) {
      const error = castError(e)
      if (isApolloError(error)) {
        const code = error?.graphQLErrors[0]?.extensions
          ?.code as CouponFragment['errorCode']

        const isValidCode = !!code && Object.keys(ERROR_MESSAGE).includes(code)

        setDiscountError({
          code: discountCode,
          reason: isValidCode ? ERROR_MESSAGE[code!] : ' is invalid',
        })
      } else {
        setDiscountError({
          code: '',
          reason: error.message,
        })
      }
    }
  }

  const modalTitle = !isIOS
    ? 'Invite Members to Your Workspace'
    : 'Invite Viewers to Your Workspace'

  const { helpText, tooltip } = limit
    ? getInviteLimitMessage(limit)
    : LIMIT_INVITE_SAFEGUARD

  const isInviteesLimitExceeded = Boolean(limit?.hasReachedLimit)

  return (
    <ModalDialog
      onCancel={hideModal}
      cancelOnClickOutside={cancelOnClickOutside}
      isSummaryVisible={isSummaryVisible}
    >
      <Modal.Header>
        <>{modalTitle}</>
      </Modal.Header>

      <Modal.Body>
        {isIOS && (
          <SubTitle>
            Viewers can browse, inspect and comment on documents in the web app.
          </SubTitle>
        )}
        <Columns $smallerMargin={isIOS}>
          <Column isSummaryHidden={!isSummaryVisible}>
            <MemberInvite
              onInvite={handleInvite}
              isDisabled={false}
              isInviteesLimitExceeded={isInviteesLimitExceeded}
              inviteeLimitHelp={helpText}
              inviteeLimitTooltip={tooltip}
              isEditorDisabled={
                noSeatsAvailableEducationWorkspace ||
                noSeatsAvailablePartneredWorkspace
              }
              isIOS={isIOS}
            />
            {parnerAlreadyExists && (
              <Banner type="information">
                Sketch Partners cannot be added as Workspace Members here. You
                can invite a{' '}
                <Link
                  to={`${routes.WORKSPACE_SETTINGS_BILLING.create({
                    workspaceId: workspaceId,
                  })}#sketch-partner`}
                  isUnderlined
                >
                  Sketch Partner now
                </Link>{' '}
                — or later from the billing section.
              </Banner>
            )}
            {noSeatsAvailableEducationWorkspace && (
              <Banner type="warning" showIcon={false}>
                <b>No Editor Seats Available</b>
                <br />
                All Editor Seats in your Education Workspace are in use. You can
                request more Editor Seats by contacting{' '}
                <Link
                  variant="secondary"
                  href="mailto:cloud@sketch.com"
                  isUnderlined
                  external
                >
                  cloud@sketch.com
                </Link>
              </Banner>
            )}
            {noSeatsAvailablePartneredWorkspace && (
              <Banner type="warning" showIcon={false}>
                <b>No Editor Seats Available</b>
                <br />
                All Editor Seats in your Workspace are in use. You can request
                more Editor Seats by contacting your{' '}
                <Link
                  variant="secondary"
                  href={`mailto:${partner!.email}`}
                  isUnderlined
                  external
                >
                  Sketch Partner
                </Link>
              </Banner>
            )}
            <StyledMemberList
              members={members}
              onRemove={handleRemove}
              onEditor={handleEditor}
              onViewer={handleViewer}
              onToggleAdmin={handleToggleAdmin}
              isDisabled={false}
              hasSingleEditor={false}
              isIOS={isIOS}
              isEditorDisabled={
                noSeatsAvailableEducationWorkspace ||
                noSeatsAvailablePartneredWorkspace
              }
            />
          </Column>
          {isSummaryVisible && (
            <Column>
              <StyledSummaryWrapper>
                {billingSummaryError ? (
                  <SummaryError refetch={() => refetch()} />
                ) : (
                  <>
                    <SummaryHeader
                      // This summary header is never rendered for Appstore subscriptions
                      // so this plan is always a CloudBillingPlan
                      plan={plan as CloudBillingPlanFragment}
                      paymentMethod={
                        // Wait for the BE response to show the proper payment method
                        prorated && (
                          <SummaryPaymentMethod
                            chargeMethod={paymentDetails}
                            paymentType={paymentType}
                          />
                        )
                      }
                    />
                    {showEditorsInSummary && (
                      <SummaryLine
                        description={`${numberOfEditorsAfterAvailableSeatsFilled} ${pluralize(
                          'Editor',
                          'Editors',
                          numberOfEditorsAfterAvailableSeatsFilled
                        )}`}
                        value={extraSeatsAmount || 0}
                        loading={isBillingSummaryLoading}
                      />
                    )}
                    {showPrePaidEditorsInSummary && (
                      <SummaryLine
                        description={`${numberOfPrepaidEditors} ${pluralize(
                          'Editor',
                          'Editors',
                          numberOfPrepaidEditors
                        )} (pre-paid Seats)`}
                        value={0}
                      />
                    )}
                    <SummaryLine
                      description={`${getViewers(members)} ${pluralize(
                        'Viewer',
                        'Viewers',
                        getViewers(members)
                      )}`}
                      value={'Free'}
                    />
                    {isDiscountFieldVisible && (
                      <DiscountWrapper>
                        <Discount
                          onApplyDiscountCode={handleApplyDiscount}
                          error={discountError}
                          isDisabled={isLoadingApplyCoupon}
                          isLoading={isLoadingApplyCoupon}
                        />
                      </DiscountWrapper>
                    )}
                    {appliedDiscount && (
                      <SummaryDiscountLine
                        appliedDiscount={appliedDiscount}
                        isAlreadyApplied={isDiscountAlreadyApplied}
                      />
                    )}
                    {prorated && (
                      <SummaryProrated
                        daysElapsedInCycle={prorated.daysElapsedInCycle}
                        refundAmountForNewSeats={prorated.discountForUnusedDays}
                      />
                    )}
                    <SummaryTotalLine
                      description={
                        isOnTrial ? 'Total Due During Trial' : 'Total due'
                      }
                      value={prorated?.amount || 0}
                      loading={isBillingSummaryLoading}
                    />
                    <SummaryTotalDescribed
                      charge={prorated?.chargeAmount}
                      credit={prorated?.creditsAmount}
                    />
                  </>
                )}
              </StyledSummaryWrapper>
              <StyledModalWarning
                title="What happens after the trial period?"
                description="If you continue with a subscription after your trial ends, you’ll be charged for Editors and any Unused Seats you have.
You won’t be charged during your trial period."
                show={isOnTrial}
              />
              {hasScheduledSeatsChange && (
                <ModalScheduleDisclaimer
                  hideModal={hideModal}
                  nextBillingCycleDate={nextBillingCycleDate}
                  workspaceId={workspaceId}
                  scheduledSeats={scheduledSeatsTotal!}
                />
              )}
            </Column>
          )}
        </Columns>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={hideModal} variant="secondary">
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleInviteMembers}
          loading={invitationLoading || !customerId || isLoading}
          disabled={!hasNewerWorkspaceMembers}
        >
          <Pluralize
            singular={isIOS ? 'Add Viewer' : 'Add Member'}
            plural={isIOS ? 'Add Viewers' : 'Add Members'}
            count={members.length}
          />
        </Button>
      </Modal.Footer>
    </ModalDialog>
  )
}
