import React, { useEffect, useRef } from 'react'

import { useHistory } from 'react-router-dom'
import { InView } from 'react-intersection-observer'

import { ResponsiveValues } from '@sketch/global-styles'

import { useSearchFilters } from '../../../shares/hooks/useSearchFilters'
import { useSearch } from '../../../shares/hooks/useSearch'

import { clamp } from '@sketch/utils'
import { routes } from '@sketch/modules-common'

import CollectionItem, {
  CollectionItemGridViewSkeleton,
  CollectionItemListViewSkeleton,
} from '../CollectionItem'
import {
  Table,
  Breakpoint,
  AspectRatioGrid,
  Pill,
  pluralize,
} from '@sketch/components'

import {
  ActionTableCell,
  FocusLink,
  HeaderTableCell,
} from '../../../shares/components/DocumentItemsLayout/DocumentItemsLayout.styles'
import { Header } from '../../../projects/components/ProjectShares/ProjectShares.styles'
import { TableWrapper } from './CollectionItemsLayout.styles'

import { RenderCollectionDropdown } from '../CollectionItem/types'

import {
  CollectionPreviewsFragment,
  ShareListItemFragment,
  useGetDocumentListSettingsQuery,
} from '@sketch/gql-types'

type Share = ShareListItemFragment
type Collection = CollectionPreviewsFragment

interface CustomColumns {
  customColumns?: ResponsiveValues<number>
}

type Action = (item: Share | Collection) => (() => void) | 'link'

interface CollectionUrlProps {
  workspaceId: string
  collectionId: string
  projectId: string
  search: string | null | undefined
  filters: string[]
}

const collectionUrl = ({
  workspaceId,
  projectId,
  collectionId,
  search,
  filters,
}: CollectionUrlProps): string => {
  const query: Partial<Record<'search' | 'filters', string>> = {}
  if (search) {
    query['search'] = search
  }
  if (filters.length > 0) {
    query['filters'] = filters.join(',')
  }

  return routes.WORKSPACE_COLLECTION.create({
    workspaceId,
    projectId,
    collectionId,
    query,
  })
}

interface CollectionItemsLayoutProps extends CustomColumns {
  workspaceId: string
  projectId: string

  collections: Collection[]
  totalCount?: number

  onLoadMore: () => Promise<any>
  renderDropdown: RenderCollectionDropdown
  action?: Action
  search?: string

  canUseDesignSystemManager?: boolean
}

const COLUMNS: ResponsiveValues<number> = [1, 1, 2, 2, 2, 3, 4, 5]
const GUTTER: ResponsiveValues<number> = [16, 16, 16, 16, 24, 24, 24]
const VERTICAL_SPACE: ResponsiveValues<number> = [24, 24, 24, 24, 24, 24, 24]
const PAGE_SIZE = 20

const buildPlaceholderArray = (remainingSharesToLoad: number) =>
  Array(clamp(remainingSharesToLoad, 0, PAGE_SIZE))

const CollectionItemsLayout: React.FC<CollectionItemsLayoutProps> = props => {
  const {
    onLoadMore,
    action: actionCreator = () => 'link',
    renderDropdown,
    collections,
    projectId,
    workspaceId,
    totalCount = 0,
    customColumns,
  } = props

  const { searchDebounced: search } = useSearch()
  const { filters } = useSearchFilters()

  // Make sure the onLoadMore Reference is always the latest
  const onLoadMoreRef = useRef<() => Promise<any>>(onLoadMore)
  const history = useHistory()

  useEffect(() => {
    onLoadMoreRef.current = onLoadMore
  }, [onLoadMore])

  // Load the document layout
  const { data } = useGetDocumentListSettingsQuery()
  const { documentsLayout } = data || {}

  const renderGridItem = (collection: Collection, isMobile: boolean) => {
    const action = actionCreator(collection)

    if (action !== 'link') {
      return (
        <CollectionItem
          key={collection.identifier}
          collection={collection}
          projectIdentifier={projectId}
          workspaceIdentifier={workspaceId}
          renderDropdown={renderDropdown}
          presentation="grid"
          onClick={action}
        />
      )
    }

    return (
      <FocusLink
        key={collection.identifier}
        draggable={false}
        to={collectionUrl({
          workspaceId: workspaceId,
          projectId: projectId,
          collectionId: collection.identifier,
          search: search,
          filters: filters,
        })}
      >
        <CollectionItem
          key={collection.identifier}
          projectIdentifier={projectId}
          collection={collection}
          workspaceIdentifier={workspaceId}
          renderDropdown={renderDropdown}
          presentation="grid"
        />
      </FocusLink>
    )
  }

  const renderTableItem = (item: Collection) => {
    const action = actionCreator(item)
    return (
      <CollectionItem
        collection={item}
        projectIdentifier={projectId}
        workspaceIdentifier={workspaceId}
        renderDropdown={renderDropdown}
        presentation="list"
        onClick={event => {
          if (action !== 'link') {
            action()
            return
          }

          const link = collectionUrl({
            workspaceId: workspaceId,
            projectId: projectId,
            collectionId: item.identifier,
            search,
            filters,
          })

          if (event.metaKey) {
            /* Simulate the link opening with meta key */
            window.open(link, '_blank')
          } else {
            history.push(link)
          }
        }}
      />
    )
  }

  const renderLayout = (
    isMobile: boolean,
    customColumns?: ResponsiveValues<number>
  ) => {
    const placeholders = buildPlaceholderArray(
      (totalCount || 0) - collections.length
    )

    // Merge the items with the placeholders have them rendered together
    // This prevents the page from bumping when the placeholders are replaced
    // with actual items
    const collectionsAndPlaceholders = [...collections, ...placeholders]

    // Create the InView listener warn when it starts showing the placeholders, this will load the next page
    const inViewListener =
      placeholders.length > 0 ? (
        <InView onChange={inView => inView && onLoadMoreRef.current?.()}>
          <span className="sr-only">Loading Placeholder</span>
        </InView>
      ) : null

    if (documentsLayout === 'LIST') {
      const headerLabel = pluralize('Collection', 'Collections', totalCount)

      return (
        <>
          <TableWrapper>
            <Table
              header={[
                {
                  label: `${totalCount} ${headerLabel}`,
                  customCell: HeaderTableCell,
                },
                { label: 'Documents', customCell: HeaderTableCell },
                { label: '', customCell: ActionTableCell },
              ]}
              items={collectionsAndPlaceholders}
              renderItemKey={collection =>
                // if it's not a share the table will generate a index based key
                typeof collection === 'object'
                  ? collection.identifier
                  : undefined
              }
              renderItem={item => {
                if (typeof item === 'object') {
                  return renderTableItem(item)
                }

                return (
                  <CollectionItemListViewSkeleton
                    inViewListener={inViewListener}
                  />
                )
              }}
              evenColumns={!isMobile}
            />
          </TableWrapper>
        </>
      )
    } else {
      return (
        <>
          {!isMobile && (
            <Header>
              Collections <Pill variant="secondary">{totalCount}</Pill>
            </Header>
          )}
          <AspectRatioGrid
            columns={customColumns ?? COLUMNS}
            verticalRowSpace={VERTICAL_SPACE}
            gutterSize={GUTTER}
          >
            {collectionsAndPlaceholders.map((collection, index) => {
              if (typeof collection === 'object') {
                return renderGridItem(collection, isMobile)
              }

              return (
                <CollectionItemGridViewSkeleton
                  key={index}
                  inViewListener={inViewListener}
                />
              )
            })}
          </AspectRatioGrid>
        </>
      )
    }
  }

  return (
    <>
      <Breakpoint on="sm">
        {matches => renderLayout(!matches, customColumns)}
      </Breakpoint>
    </>
  )
}

export default CollectionItemsLayout
