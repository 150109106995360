import React from 'react'

import { Box, Button, TruncateWithTooltip } from '@sketch/components'

import NotificationListItemAvatar from './NotificationListItemAvatar'

import {
  NotificationDescription,
  NotificationShareName,
  NotificationBody,
} from '../NotificationListItem.styles'

import useAcceptShareMembershipInvitation from '../../../../shares/operations/useAcceptShareMembershipInvitation'
import { useToast } from '@sketch/toasts'
import { ErrorHandler } from '@sketch/tracing'

import { AddShareMemberNotificationFragment } from '@sketch/gql-types'

interface AcceptShareInviteButtonProps {
  shareIdentifier: string
  onCompleted: () => void
}

const AcceptShareInviteButton: React.FC<AcceptShareInviteButtonProps> = ({
  shareIdentifier,
  onCompleted,
}) => {
  const { showToast } = useToast()
  const [acceptMembership, { loading }] = useAcceptShareMembershipInvitation({
    variables: { shareIdentifier },
    onError: 'show-toast',
    onCompleted: ({ acceptShareMembershipInvitation }) => {
      if (!acceptShareMembershipInvitation) {
        ErrorHandler.shouldNeverHappen.invalidMutationData(
          'acceptShareMembershipInvitation'
        )
        return
      }

      showToast('Invite successfully accepted')

      onCompleted()
    },
  })

  return (
    <Button
      variant="primary"
      mt={4}
      onClick={event => {
        /* Prevent the link click action */
        event.preventDefault()
        /* Prevent the dropdown from closing */
        event.stopPropagation()

        acceptMembership()
      }}
      loading={loading}
      size="32"
    >
      Accept Invite
    </Button>
  )
}

interface AddShareMemberNotificationListItemProps {
  notification: AddShareMemberNotificationFragment
  markNotificationAsRead: () => void
}

const AddShareMemberNotificationListItem: React.FC<AddShareMemberNotificationListItemProps> = ({
  notification,
  markNotificationAsRead,
}) => {
  const userAvatar = notification.user?.avatar?.small
  return (
    <>
      <NotificationShareName data-testid="notification-share-name">
        <TruncateWithTooltip>
          {notification?.share?.workspace.name} / {notification?.share?.name}
        </TruncateWithTooltip>
      </NotificationShareName>
      <NotificationBody data-testid="notification-body">
        <NotificationListItemAvatar
          avatar={userAvatar ?? ''}
          name={notification.user?.name ?? ''}
        />
        <NotificationDescription data-testid="notification-description">
          {notification.subtitle!}
        </NotificationDescription>
      </NotificationBody>

      {notification.share?.userHasUnacceptedInvitationToView && (
        <Box marginTop={2}>
          <AcceptShareInviteButton
            shareIdentifier={notification.share.identifier}
            onCompleted={markNotificationAsRead}
          />
        </Box>
      )}
    </>
  )
}

export default AddShareMemberNotificationListItem
