import React from 'react'
import { ProjectsPickerWrapper } from './ProjectPickerContent.styles'
import { WorkspaceTreeNodes } from './TreeNode.workspaces'

export const ProjectPickerContent = () => {
  return (
    <ProjectsPickerWrapper>
      <WorkspaceTreeNodes />
    </ProjectsPickerWrapper>
  )
}
