import styled from 'styled-components'
import { RadioButton, Separator } from '@sketch/components'

export const MovingTextWrapper = styled.div`
  margin-top: 24px;
  border-top: 1px solid ${({ theme }) => theme.colors.border.B};
  padding: 24px 0 0;
`

export const Bold = styled.b`
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  font-weight: 500;
`

export const DangerRadioButton = styled(RadioButton)`
  ${RadioButton.Label} {
    color: ${({ theme }) => theme.colors.state.negative.A};
  }
`
export const StyledSeparator = styled(Separator)`
  margin: 24px 0;
`
