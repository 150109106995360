import React, { FC } from 'react'
import { Route, Switch } from 'react-router-dom'

import {
  useFlag,
  DynamicLoadingPage,
  NotFoundView,
  routes,
} from '@sketch/modules-common'

import {
  useBellNotifications,
  useProjectMembershipSubscriptions,
  PrivateRoute,
  UserAcceptedToS,
  ShareRoutes,
  PersonalAuthRoute,
  PrivateWorkspaceRoute,
  WorkspaceSettingsView,
  WorkspaceSubscribeView,
  WorkspacePartnerSubscribeView,
  UserToWorkspaceRedirect,
  WorkspaceProfilePublicView,
  PublicationView,
  ShareSubscriptions,
  WorkspaceSubscriptions,
  RestrictedWorkspaceBannerManagerSwitch,
} from 'cloud-frontend'

import {
  WorkspaceSidebarLayoutRoutes,
  workspaceSidebarLayoutRoutePaths,
} from '../../containers/WorkspaceSidebarLayoutRoutes'

import { CoreLayoutWrapper } from './CoreLayout.styles'
import { WorkspaceThankYou } from 'cloud-frontend/src/modules/workspace/pages'

const AcceptWorkspaceInviteView = React.lazy(
  () =>
    import(
      'cloud-frontend/src/modules/workspace/views/AcceptWorkspaceInviteView'
    )
)

const CreateWorkspaceView = React.lazy(
  () => import('cloud-frontend/src/modules/workspace/views/CreateWorkspaceView')
)

const CreateWorkspaceDoneView = React.lazy(
  () =>
    import('cloud-frontend/src/modules/workspace/views/CreateWorkspaceDoneView')
)

const CommunityView = React.lazy(
  () => import('cloud-frontend/src/modules/community/views/CommunityView')
)

const DesignSystemLayoutSwitch = React.lazy(
  () =>
    import(
      'cloud-frontend/src/modules/designSystems/containers/DesignSystemLayoutSwitch/DesignSystemLayoutSwitch'
    )
)

const CoreLayout: FC = () => {
  useBellNotifications()
  useProjectMembershipSubscriptions()

  const isDesignSystemsOn = useFlag('design-systems')
  const isCommunityCanvasOn = useFlag('community-canvas')

  return (
    <CoreLayoutWrapper>
      <ShareSubscriptions />
      <WorkspaceSubscriptions />
      <React.Suspense fallback={<DynamicLoadingPage />}>
        <RestrictedWorkspaceBannerManagerSwitch />
        <Switch>
          {/* Redirect from Baby Cloud to the Personal-Workspace */}
          <PrivateRoute
            path={[
              routes.PERSONAL_PROJECT.template(),
              routes.DOCUMENTS.template(),
              routes.LIBRARIES.template(),
              routes.TRASH.template(),
              routes.PERSONAL_DOCUMENTS.template(),
            ]}
            component={UserToWorkspaceRedirect}
          />

          {/* Workspace Routes */}
          <PrivateRoute
            path={routes.WORKSPACE_INVITE.template()}
            component={AcceptWorkspaceInviteView}
          />

          <PersonalAuthRoute
            fallbackRouteIfUserOnlyHasSsoAuth={({ location }) => ({
              pathname: routes.SIGN_IN.create({}),
              state: { from: location },
            })}
            path={[
              routes.WORKSPACE_CREATE.template(),
              routes.WORKSPACE_CREATE_DONE.template(),
            ]}
            render={() => (
              <>
                <PrivateRoute
                  exact
                  path={routes.WORKSPACE_CREATE_DONE.template()}
                  render={({ match }) => (
                    <CreateWorkspaceDoneView
                      workspaceId={match.params.workspaceId!}
                    />
                  )}
                />
                <PrivateRoute
                  exact
                  path={routes.WORKSPACE_CREATE.template()}
                  redirectPathIfSignOut="sign-up"
                  component={CreateWorkspaceView}
                />
              </>
            )}
          />

          <Route
            exact
            path={workspaceSidebarLayoutRoutePaths}
            component={WorkspaceSidebarLayoutRoutes}
          />

          <PrivateWorkspaceRoute
            path={routes.WORKSPACE_SETTINGS.template()}
            component={WorkspaceSettingsView}
          />

          <PrivateWorkspaceRoute
            path={routes.WORKSPACE_SUBSCRIBE.template()}
            component={WorkspaceSubscribeView}
          />

          <PrivateRoute
            exact
            path={routes.WORKSPACE_SUBSCRIBE_PARTNER.template()}
            component={WorkspacePartnerSubscribeView}
          />

          {isCommunityCanvasOn && (
            <Route
              path={routes.COMMUNITY_CANVAS.template()}
              component={CommunityView}
            />
          )}

          <Route
            exact
            path={routes.WORKSPACE_PROFILE.template()}
            component={WorkspaceProfilePublicView}
          />

          <Route
            exact
            path={routes.WORKSPACE_PROFILE_DOCUMENT.template()}
            component={PublicationView}
          />

          <PrivateWorkspaceRoute
            exact
            path={routes.WORKSPACE_TRIAL_THANK_YOU.template()}
            component={WorkspaceThankYou}
          />

          {/* 
          For now a design system can only be accessed by a logged in user
          within the same workspace, but in the future design systems could be
          public, that will imply to change PrivateRoute for Route
          */}
          {isDesignSystemsOn && (
            <PrivateWorkspaceRoute
              exact
              path={routes.DESIGN_SYSTEM.template()}
              component={DesignSystemLayoutSwitch}
            />
          )}

          <Route path={routes.SHARE_VIEW.template()} component={ShareRoutes} />

          {/* Not found default */}
          <Route component={NotFoundView} />
        </Switch>
        <UserAcceptedToS />
      </React.Suspense>
    </CoreLayoutWrapper>
  )
}

export default CoreLayout
