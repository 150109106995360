import styled, { css, ThemeProps, DefaultTheme } from 'styled-components'

import { BoxProps, withBox } from '../Box'
import { ButtonType } from './types'

const buttonUnstyledStyles = css`
  border: none;
  background-color: transparent;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  text-align: unset;

  &[disabled],
  &[aria-disabled='true'] {
    pointer-events: none;
    cursor: not-allowed;
  }

  &:focus-visible {
    outline-offset: -2px; /* To avoid cutting focus ring */
  }
`

const ButtonUnstyled = styled(withBox('button'))<
  ThemeProps<DefaultTheme> & OmitSafe<BoxProps, 'color'> & { type?: ButtonType }
>`
  ${buttonUnstyledStyles};
`

ButtonUnstyled.defaultProps = {
  p: 0,
  type: 'button',
}

export { buttonUnstyledStyles, ButtonUnstyled }
