import React, { ComponentProps, ComponentType, useMemo, useState } from 'react'

export interface ConfirmationModalGenericProps<T extends {}> {
  onCancel: () => void
  onConfirm: (args: T) => void
  loading: boolean
}

export const useConfirmationModal = () => {
  const [ConfirmationModal, setConfirmationModal] = useState<{
    // We wrapping the component within an object to avoid calling the component (as setState does accept a function on its own)
    Component: ComponentType<{ loading: boolean }>
  } | null>(null)

  const askForConfirmation = useMemo(
    () => <Props extends ConfirmationModalGenericProps<any>>(
      ConfirmationModalInner: ComponentType<Props>,
      passDownProps: OmitSafe<Props, keyof ConfirmationModalGenericProps<any>>,
      onConfirm: Props['onConfirm']
    ) => {
      const onCancel = () => {
        setConfirmationModal(null)
      }

      const WrapperComponent = ({ loading }: { loading: boolean }) => {
        return (
          <ConfirmationModalInner
            {...(passDownProps as ComponentProps<
              typeof ConfirmationModalInner
            >)}
            onCancel={onCancel}
            onConfirm={onConfirm}
            loading={loading}
          />
        )
      }

      setConfirmationModal({ Component: WrapperComponent })
    },
    []
  )

  return [askForConfirmation, ConfirmationModal?.Component || null] as const
}
