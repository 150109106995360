import React, { useEffect, useRef, useState } from 'react'
import { Portal } from 'react-portal'
import {
  COOKIE_CONSENT_ID,
  COOKIE_PORTAL_ID,
  CookieBanner,
} from '@sketch/modules-common'
import { Transition } from 'react-spring/renderprops.cjs'
import { localStorageKeys } from '@sketch/constants'
import styled from 'styled-components'

const DISPLAY_DURATION = 30000

const Wrapper = styled.div<{
  $opacity: number
}>`
  position: fixed;
  bottom: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: auto;
  padding: 1rem; /* stylelint-disable-line scales/space */
  z-index: ${({ theme }) => theme.zIndex[3]};
  opacity: ${({ $opacity }) => $opacity};
`

interface Seen {
  seen: boolean
}

const getCookieConsent = (): Seen => {
  try {
    return (
      JSON.parse(
        localStorage.getItem(localStorageKeys.cookieConsent) ?? ''
      ) || {
        seen: false,
      }
    )
  } catch (e) {
    return {
      seen: false,
    }
  }
}

const CookieConsent: React.FC = () => {
  const [shouldAppear, setShouldAppear] = useState(!getCookieConsent().seen)
  const timeoutRef = useRef<number | null>(null)

  const close = (): void => {
    setShouldAppear(false)
    if (timeoutRef.current) window.clearTimeout(timeoutRef.current)
  }

  useEffect(() => {
    if (!shouldAppear) {
      return
    }

    localStorage.setItem(
      localStorageKeys.cookieConsent,
      JSON.stringify({ seen: true })
    )

    timeoutRef.current = window.setTimeout(close, DISPLAY_DURATION)

    return () => {
      if (timeoutRef.current) window.clearTimeout(timeoutRef.current)
    }
  }, [shouldAppear])

  const renderBanner = ({ opacity }: { opacity: number }): React.ReactNode => (
    <Portal node={document.getElementById(COOKIE_PORTAL_ID)}>
      <Wrapper id={COOKIE_CONSENT_ID} $opacity={opacity}>
        <CookieBanner onClickClose={close} />
      </Wrapper>
    </Portal>
  )

  return (
    <Transition
      items={shouldAppear}
      from={{ opacity: 1 }}
      enter={{ opacity: 1 }}
      leave={{ opacity: 0 }}
    >
      {appear => props => appear && renderBanner(props)}
    </Transition>
  )
}

export default CookieConsent
