import styled from 'styled-components'
import { FirstLetter, WorkspaceLogo } from '@sketch/components'

export const WorkspaceLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 32px;
`

export const StyledLogo = styled(WorkspaceLogo)`
  background: ${({ theme }) => theme.colors.sketch.A};

  ${FirstLetter} {
    color: ${({ theme }) => theme.colors.foreground.primary.A};
  }
`

export const WorkspaceName = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.E};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  margin-left: 14px; /* stylelint-disable-line scales/space */
`
