import React, { useState } from 'react'

//Utils
import { castError, dateFormat, useDebounceValue } from '@sketch/utils'
import { confirmPendingSetupIntent } from '../../utils'

import { SKETCH_WEBSITE } from '@sketch/env-config'

import {
  Button,
  Stepper,
  Modal,
  ModalInjectedProps,
  useStripe,
} from '@sketch/components'
import { useToast } from '@sketch/toasts'

import {
  SummaryHeader,
  SummaryLine,
  SummaryTotalLine,
  SummaryError,
  ModalWarning,
} from '../../components'

import {
  useUpdateBillingSeatsMutation,
  useGetSeatsUpdateBillingSimulationQuery,
  GetNextBillingCycleDocument,
  CloudBillingPlanFragment,
} from '@sketch/gql-types'

import {
  SeatsWrapper,
  Title,
  StyledPluralize,
} from './ChangeNextBillingCycleModal.styles'

interface ChangeNextBillingCycleModalProps extends ModalInjectedProps {
  currentSeats: number
  scheduledSeats?: number
  workspaceId: string
  currentPlan: CloudBillingPlanFragment
  customerId: string
  nextBillingCycleDate?: string
  variant?: 'default' | 'partner'
}

const MIN_NUM_SEATS = 1

/*
 * ChangeNextBillingCycleModal
 *
 * Renders a modal to change the next billing cycle seat number in "Workspace Settings"
 *
 */
export const ChangeNextBillingCycleModal: React.FC<ChangeNextBillingCycleModalProps> = ({
  hideModal,
  currentSeats,
  scheduledSeats,
  workspaceId,
  currentPlan,
  customerId,
  nextBillingCycleDate,
  variant = 'default',
}) => {
  const { load: loadStripe } = useStripe()
  const isNewPricingEnabled = currentPlan?.product !== 'BUSINESS'

  const { showToast } = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const [selectedSeats, setSelectedSeats] = useState(
    scheduledSeats || currentSeats
  )

  const selectedSeatsDebounced = useDebounceValue(selectedSeats, 500)

  // QUERIES
  const {
    loading: summaryLoading,
    data,
    error,
    refetch,
  } = useGetSeatsUpdateBillingSimulationQuery({
    variables: {
      customerId,
      totalSeats: selectedSeatsDebounced,
      nextBillingCycle: isNewPricingEnabled,
    },
  })

  const { totalAmount } = data?.seatsUpdateBillingSimulation || {}

  // MUTATIONS
  const [updateBillingSeats, { loading }] = useUpdateBillingSeatsMutation({
    awaitRefetchQueries: true,
    refetchQueries: () => {
      // We need to update the nextBillingSummary fields on cache
      return [
        {
          query: GetNextBillingCycleDocument,
          variables: { customerId, isNewPricing: isNewPricingEnabled },
        },
      ]
    },
    onError: 'show-toast',
  })

  const handleUpdateSeats = async () => {
    setIsLoading(true)

    try {
      const { data } = await updateBillingSeats({
        variables: {
          input: {
            billingPeriod: 'NEXT',
            numberOfSeats: selectedSeats,
            workspaceIdentifier: workspaceId,
          },
        },
      })

      const { pendingScaToken } = data?.updateBillingSeats || {}

      /**
       * If "pendingScaToken" is present on the payload we need to do the
       * challenge with stripe to make sure the card is not INCOMPLETE
       */
      if (pendingScaToken) {
        const stripe = await loadStripe()

        await confirmPendingSetupIntent(stripe, pendingScaToken)
      }

      showToast('Editor Seat changes successfully scheduled', 'positive')
      hideModal()
    } catch (e) {
      const error = castError(e)
      showToast(error.message, 'negative')
    } finally {
      setIsLoading(false)
    }
  }

  const isSummaryLoading =
    summaryLoading || selectedSeats !== selectedSeatsDebounced

  const showWarning = currentSeats > selectedSeatsDebounced
  const downgradableEditors = currentSeats - selectedSeats

  return (
    <Modal onCancel={hideModal}>
      <Modal.Header>Schedule Editor Seat Changes</Modal.Header>
      <Modal.Body>
        <p>
          You can schedule a change on the number of Editor Seats you have on
          your next billing date on{' '}
          <strong>{dateFormat(new Date(nextBillingCycleDate!))}</strong>.
          <br /> You currently have {currentSeats} Editor seats.{' '}
          <a
            href={`${SKETCH_WEBSITE}/docs/workspaces/managing-your-workspace-settings/#how-to-schedule-editor-seat-changes`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more.
          </a>
        </p>
        <p>
          Your next bill will be based on the number of Editor Seats you have on
          the billing date.
        </p>
        <SeatsWrapper>
          <Title>Editor Seats on Next Billing Date</Title>
          <Stepper
            value={scheduledSeats || currentSeats}
            onChange={setSelectedSeats}
            min={MIN_NUM_SEATS}
          />
        </SeatsWrapper>
      </Modal.Body>
      <Modal.Body>
        <ModalWarning
          title="You are reducing the number of seats on your workspace."
          description={
            <>
              If you are using more than {selectedSeats} seats on your next
              billing date, we will automatically downgrade{' '}
              <StyledPluralize
                singular="Editor seat"
                plural="Editor seats"
                count={downgradableEditors}
                showCount
              />{' '}
              to{' '}
              <StyledPluralize
                singular="Viewer"
                plural="Viewers"
                count={downgradableEditors}
              />
              .
            </>
          }
          show={showWarning}
        />
        {error ? (
          <SummaryError refetch={() => refetch()} />
        ) : (
          <>
            <SummaryHeader
              plan={currentPlan}
              nextBillingCycleDate={nextBillingCycleDate}
            />
            <SummaryLine
              description={`${selectedSeats} Seats`}
              value={totalAmount}
              loading={isSummaryLoading}
            />
            <SummaryTotalLine
              data-testid="projected-cost"
              description="Cost on Next Billing Date"
              value={totalAmount}
              variant={variant}
              loading={isSummaryLoading}
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={hideModal} disabled={isLoading} variant="secondary">
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleUpdateSeats}
          loading={loading || isLoading}
          disabled={isSummaryLoading}
        >
          Schedule Editor Seat Changes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
