import React, { useRef, useState } from 'react'
import * as yup from 'yup'
import { Formik, useFormikContext } from 'formik'

import { isBillingHidden, SKETCH_WEBSITE } from '@sketch/env-config'

import { UploadWorkspaceLogo } from '../UploadWorkspaceLogo'
import { CreateWorkspaceInvitePartners } from '../CreateWorkspaceInvitePartners'

import {
  FormikScrollToError,
  Caption,
  Form,
  Input,
  RadioButton,
  Text,
  Link,
} from '@sketch/components'

import { useToast } from '@sketch/toasts'
import { getBlobData } from '@sketch/utils'

import {
  StyledForm,
  StyledButton,
  Fieldset,
  UpgradeRadioButtons,
  UpgradeCallout,
  StyledCheckbox,
  ToSFieldSet,
  DescriptionWrapper,
  Wrapper,
  GreyCallout,
  WorkspaceNameField,
} from './CreateWorkspaceForm.styles'

// Other
import { WorkspaceCreationState, WorkspaceMembers } from '../../types'
import { GetPersonalWorkspaceSharesCountQuery } from '@sketch/gql-types'
import { PersonalWorkspaceContentsCount } from '../PersonalWorkspaceContentsCount'
import { useAnalytics, useTrackFirstValueChange } from '@sketch/modules-common'
import { useAnalyticsFormContext } from '../../views/CreateWorkspaceView/CreateWorkspaceView.hooks'

type PersonalWorkspace = GetPersonalWorkspaceSharesCountQuery['me']['personalWorkspace']

export type UpgradeStatus = 'Upgrade' | 'Not yet'

export interface FormValues {
  name: string
  logo?: File
  members: string
  upgrade?: UpgradeStatus
  tosAgreed: boolean
  privacyPolicyAgreed: boolean
}

interface CreateWorkspaceFormProps {
  state?: WorkspaceCreationState
  onWorkspaceDetails: (values: FormValues) => Promise<void>
  onMembersUpdate: (members: WorkspaceMembers) => void
  onMembersDelete: (members: WorkspaceMembers) => void
  isWorkspaceCreated?: boolean
  personalWorkspace: PersonalWorkspace
  showUpgradeCallout: boolean
  isPartner: boolean
  isUpgradingPersonalWorkspace?: boolean
  eligibleForWorkspaceTrial: boolean
}

// Formik Validation
const VALIDATION_SCHEMA = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required(
      'Give your Workspace a name. Don’t worry — you can change it later.'
    ),
  upgrade: yup.mixed().oneOf(['Upgrade', 'Not yet']),
  tosAgreed: yup.boolean(),
  privacyPolicyAgreed: yup.boolean(),
})

const getSubmitText = (
  isPartner: boolean,
  eligibleForWorkspaceTrial: boolean
) => {
  if (isPartner) {
    return 'Continue'
  }

  if (!eligibleForWorkspaceTrial) {
    return 'Continue to Choose a Plan'
  }

  return 'Start Using Sketch'
}

/*
 * CreateWorkspaceForm
 *
 * Renders the necessary content for the Create Workspace Page
 *  - Workspace Name
 *  - Workspace Logo
 */
export const CreateWorkspaceForm: React.FC<CreateWorkspaceFormProps> = props => {
  const {
    state,
    onMembersUpdate,
    onMembersDelete,
    isWorkspaceCreated,
    isPartner,
    personalWorkspace,
    showUpgradeCallout,
    isUpgradingPersonalWorkspace,
    eligibleForWorkspaceTrial,
    onWorkspaceDetails,
  } = props

  // Create initial values correctly
  const formValues = {
    name: '',
    upgrade: undefined,
    tosAgreed: false,
    privacyPolicyAgreed: false,
    members: '',
    ...state?.details,
  }

  const librariesCount = personalWorkspace?.libraries.meta.totalCount ?? 0
  const nonLibrarySharesCount =
    (personalWorkspace?.allShares.meta.totalCount ?? 0) - librariesCount
  const hasAnyShareInPersonalWorkspace =
    librariesCount > 0 || nonLibrarySharesCount > 0
  const isShowingUpgradeCallout =
    showUpgradeCallout && hasAnyShareInPersonalWorkspace

  const handleValidation = (values: FormValues) => {
    const errors: Partial<Record<keyof FormValues, string>> = {}
    const { tosAgreed, privacyPolicyAgreed, upgrade } = values

    if (isShowingUpgradeCallout && !upgrade) {
      errors.upgrade = 'Select an option.'
    }

    if (upgrade === 'Upgrade' || isUpgradingPersonalWorkspace) {
      if (!tosAgreed) {
        errors.tosAgreed = 'You need to agree to the Terms of Service'
      }

      if (!privacyPolicyAgreed) {
        errors.privacyPolicyAgreed = 'You need to agree to the Privacy Policy'
      }
    }

    const hasMemberErrors =
      isPartner &&
      (!state?.members || (state?.members && state?.members?.length < 2))

    if (hasMemberErrors) {
      errors.members = 'You need to invite an owner.'
    }
    return errors
  }

  return (
    <Formik
      initialValues={formValues}
      onSubmit={onWorkspaceDetails}
      validationSchema={VALIDATION_SCHEMA}
      validate={handleValidation}
    >
      <CreateWorkspaceFormContent
        isShowingUpgradeCallout={isShowingUpgradeCallout}
        isPartner={isPartner}
        librariesCount={librariesCount}
        nonLibrarySharesCount={nonLibrarySharesCount}
        onMembersUpdate={onMembersUpdate}
        onMembersDelete={onMembersDelete}
        isWorkspaceCreated={isWorkspaceCreated}
        isUpgradingPersonalWorkspace={isUpgradingPersonalWorkspace}
        eligibleForWorkspaceTrial={eligibleForWorkspaceTrial}
        state={state}
      />
    </Formik>
  )
}

type CreateWorkspaceFormContentProps = Pick<
  CreateWorkspaceFormProps,
  | 'isWorkspaceCreated'
  | 'state'
  | 'onMembersUpdate'
  | 'onMembersDelete'
  | 'isUpgradingPersonalWorkspace'
  | 'isPartner'
  | 'eligibleForWorkspaceTrial'
> & {
  isShowingUpgradeCallout: boolean
  librariesCount: number
  nonLibrarySharesCount: number
}

/**
 * Sub-component wrapped by Formik so we can use
 * useFormikContext and hooks inside.
 */
function CreateWorkspaceFormContent({
  isShowingUpgradeCallout,
  isWorkspaceCreated,
  isUpgradingPersonalWorkspace,
  isPartner,
  state,
  librariesCount,
  nonLibrarySharesCount,
  onMembersUpdate,
  onMembersDelete,
  eligibleForWorkspaceTrial,
}: CreateWorkspaceFormContentProps) {
  const {
    touched,
    values,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    isSubmitting,
    submitCount,
  } = useFormikContext<FormValues>()

  const { trackEvent } = useAnalytics()
  const analyticsFormContext = useAnalyticsFormContext()

  useTrackFirstValueChange(
    values.name,
    'CREATE WORKSPACE - "workspace name" field edited',
    {
      formContext: analyticsFormContext,
    }
  )

  const [logoImage, setLogoImage] = useState<string | undefined>()

  const formRef = useRef<HTMLFormElement>(null)
  const { showToast } = useToast()
  const resetPartnerMembersErrorMessage =
    isPartner &&
    state?.members &&
    state?.members?.length > 1 &&
    errors.members &&
    errors.members !== ''

  if (resetPartnerMembersErrorMessage) {
    errors.members = ''
  }

  return (
    <StyledForm ref={formRef}>
      <FormikScrollToError formRef={formRef} />
      <Fieldset disabled={isWorkspaceCreated}>
        {!eligibleForWorkspaceTrial && (
          <GreyCallout>
            <Text textStyle="copy.tertiary.standard.D">
              You’re not eligible for a 30-Day trial workspace.{' '}
              <Link
                variant="secondary"
                href="/asd"
                target="_blank"
                external
                isUnderlined
              >
                Learn More ↗
              </Link>
            </Text>
          </GreyCallout>
        )}

        <Wrapper>
          <UploadWorkspaceLogo
            inputId="logo-input"
            logo={logoImage}
            workspaceName={values.name}
            onDrop={file => {
              trackEvent('CREATE WORKSPACE - workspace image uploaded', {
                formContext: analyticsFormContext,
              })

              setFieldValue('logo', file)
              getBlobData(file, setLogoImage)
            }}
            onRemove={() => {
              setFieldValue('logo', null)
              setLogoImage(undefined)
            }}
            onError={message => showToast(message, 'negative')}
          />

          <WorkspaceNameField
            name="name"
            label="Workspace Name"
            help="Can be a company name, a project — or your own name."
            errorText={touched.name ? errors.name : undefined}
          >
            <Input
              name="name"
              type="text"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="A Creative Space"
            />
          </WorkspaceNameField>
        </Wrapper>

        {isPartner && (
          <Form.Field
            name="members"
            errorText={touched.members ? errors.members : undefined}
          >
            <CreateWorkspaceInvitePartners
              members={state?.members ?? []}
              onMembersUpdate={onMembersUpdate}
              isWorkspaceCreated={isWorkspaceCreated ?? false}
              isDisabled={isSubmitting}
              onMembersDelete={onMembersDelete}
            />
          </Form.Field>
        )}
      </Fieldset>

      {isShowingUpgradeCallout && (
        <UpgradeCallout
          title="Get instant access to sharing and collaborative tools by
                upgrading your old Personal Workspace"
          description={
            <DescriptionWrapper>
              Your old Personal Workspace with{' '}
              <PersonalWorkspaceContentsCount
                nonLibrarySharesCount={nonLibrarySharesCount}
                librariesCount={librariesCount}
              />{' '}
              will automatically be moved to the new Workspace.
            </DescriptionWrapper>
          }
        >
          <UpgradeRadioButtons>
            <RadioButton
              label={'Upgrade'}
              value={'Upgrade'}
              checked={values.upgrade === 'Upgrade'}
              name="upgrade"
              onChange={handleChange}
              disabled={isSubmitting}
            />
            <RadioButton
              label={'Not yet'}
              value={'Not yet'}
              checked={values.upgrade === 'Not yet'}
              name="upgrade"
              onChange={handleChange}
              disabled={isSubmitting}
            />
          </UpgradeRadioButtons>
          <Form.Field
            name="upgrade"
            mb={0}
            errorText={touched.upgrade ? errors.upgrade : undefined}
          />
        </UpgradeCallout>
      )}

      {(values.upgrade === 'Upgrade' || isUpgradingPersonalWorkspace) && (
        <ToSFieldSet disabled={isWorkspaceCreated}>
          <Form.Field
            name="tosAgreed"
            mb={2}
            errorText={touched.tosAgreed ? errors.tosAgreed : undefined}
          >
            <StyledCheckbox
              name="tosAgreed"
              checked={Boolean(values.tosAgreed)}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              I&apos;ve read and agree to the Sketch&nbsp;
              <a
                href={`${SKETCH_WEBSITE}/tos`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>
              .
            </StyledCheckbox>
          </Form.Field>
          <Form.Field
            name="privacyPolicyAgreed"
            errorText={
              touched.privacyPolicyAgreed
                ? errors.privacyPolicyAgreed
                : undefined
            }
          >
            <StyledCheckbox
              name="privacyPolicyAgreed"
              checked={Boolean(values.privacyPolicyAgreed)}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              I&apos;ve read and agree to the Sketch&nbsp;
              <a
                href={`${SKETCH_WEBSITE}/privacy`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              .
            </StyledCheckbox>
          </Form.Field>
        </ToSFieldSet>
      )}

      <StyledButton
        type="submit"
        variant="primary"
        loading={isSubmitting}
        fill
        size="40"
        onClick={() => {
          trackEvent('CREATE WORKSPACE - form submitted', {
            formContext: analyticsFormContext,
            submitCount: submitCount + 1,
          })
        }}
      >
        {getSubmitText(isPartner, eligibleForWorkspaceTrial)}
      </StyledButton>

      {!(isPartner || isBillingHidden || !eligibleForWorkspaceTrial) && (
        <Caption textAlign="center">
          Try Sketch for free for 30 days. No credit card required.
        </Caption>
      )}
    </StyledForm>
  )
}
