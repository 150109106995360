import { ErrorHandler } from '@sketch/tracing'
import { groupBy } from '@sketch/utils'

export interface TreeDataNode<Payload extends {}> {
  id: string
  parent: TreeDataNode<Payload> | null
  children: TreeDataNode<Payload>[]
  payload: Payload | null
}

interface CreateNodeArgs<Payload extends {}> {
  id: string
  parent: TreeDataNode<Payload> | null
  payload: Payload | null
}

export interface SetChildrenInput<Payload extends {}> {
  id: string
  payload: Payload | null
}

const ROOD_ID = 'root'
class TreeBase<Payload extends {}> {
  static ROOT_ID = ROOD_ID
  protected nodes: Map<string, TreeDataNode<Payload>> = new Map()
  protected root: TreeDataNode<Payload>

  constructor() {
    // Create an empty root node
    this.root = this.createNode({ id: ROOD_ID, parent: null, payload: null })
  }

  protected createNode(args: CreateNodeArgs<Payload>): TreeDataNode<Payload> {
    const { id, parent, payload } = args
    const node: TreeDataNode<Payload> = { id, parent, children: [], payload }
    this.nodes.set(node.id, node)
    return node
  }

  /**
   * Throws away existing children for a given parent and sets new children.
   */
  setChildren(
    parentId: string | null,
    children: SetChildrenInput<Payload>[]
  ): void {
    const parentNode = this.nodes.get(parentId ?? ROOD_ID)
    if (!parentNode) {
      throw new Error(`Parent node with id "${parentId}" not found`)
    }

    if (children.some(x => x.id === ROOD_ID)) {
      throw new Error('Cannot add root node as a child')
    }

    // Remove existing children (if they exist)
    const existingChildren = parentNode.children
    for (const child of existingChildren) {
      this.nodes.delete(child.id)
    }

    // Set new children
    parentNode.children.length = 0
    for (const child of children) {
      const childNode = this.createNode({ ...child, parent: parentNode })
      parentNode.children.push(childNode)
    }
  }

  setChildrenTree<T extends Payload>(args: {
    list: T[]
    id: (item: T) => string
    parentId: (item: T) => string | null
  }) {
    const { list, id, parentId } = args

    const groupedByParentId = groupBy(list, x => parentId(x) || ROOD_ID)
    const groupsCount = Object.keys(groupedByParentId).length

    for (let i = 0; i < groupsCount; i++) {
      // pick first group which parent is already added to the tree
      let existingParentId: string | undefined = undefined
      for (const parentId in groupedByParentId) {
        if (!this.getNode(parentId)) continue
        existingParentId = parentId
        break
      }

      if (!existingParentId) {
        ErrorHandler.shouldNeverHappen(
          'There are non-root groups without a parent'
        )
        return this
      }

      // set children for the existing parent
      const children = groupedByParentId[existingParentId]
      this.setChildren(
        existingParentId,
        children.map(x => ({ id: id(x), payload: x }))
      )

      // remove the processed group
      delete groupedByParentId[existingParentId]
    }
  }

  // Helper methods
  getNode(id: string): TreeDataNode<Payload> | undefined {
    return this.nodes.get(id)
  }

  getRoot(): TreeDataNode<Payload> {
    return this.root
  }

  getChildren(id: string): TreeDataNode<Payload>[] {
    return this.nodes.get(id)?.children ?? []
  }

  /**
   * @returns
   *  - `undefined` if the node is not found
   *  - `null` if the node is the root and therefore has no parent
   */
  getParent(id: string): TreeDataNode<Payload> | undefined | null {
    return this.nodes.get(id)?.parent
  }

  // Navigation methods
  getNextSibling(id: string): TreeDataNode<Payload> | undefined {
    const node = this.nodes.get(id)
    if (!node?.parent) return undefined

    const siblings = node.parent.children
    const index = siblings.findIndex(n => n.id === id)
    return index < siblings.length - 1 ? siblings[index + 1] : undefined
  }

  getPrevSibling(id: string): TreeDataNode<Payload> | undefined {
    const node = this.nodes.get(id)
    if (!node?.parent) return undefined

    const siblings = node.parent.children
    const index = siblings.findIndex(n => n.id === id)
    return index > 0 ? siblings[index - 1] : undefined
  }

  getFirstChild(id: string): TreeDataNode<Payload> | undefined {
    const children = this.getChildren(id)
    return children.length > 0 ? children[0] : undefined
  }

  getLastChild(id: string): TreeDataNode<Payload> | undefined {
    const children = this.getChildren(id)
    return children.length > 0 ? children[children.length - 1] : undefined
  }
}

export class CollapsibleTree<
  Payload extends {} = {}
> extends TreeBase<Payload> {
  private expandedIds: Set<string> = new Set([ROOD_ID])

  setOpen(id: string | string[]) {
    const ids = typeof id === 'string' ? [id] : id
    for (const id of ids) {
      this.expandedIds.add(id)
    }
  }

  setClosed(id: string | string[]) {
    const ids = typeof id === 'string' ? [id] : id
    for (const id of ids) {
      this.expandedIds.delete(id)
    }
  }

  setIsOpen(id: string, isOpen: boolean) {
    if (isOpen) {
      this.setOpen(id)
    } else {
      this.setClosed(id)
    }
  }

  isOpen(id: string): boolean {
    return this.expandedIds.has(id)
  }

  getParentsPathToNode(id: string): string[] {
    const currentNode = this.nodes.get(id)
    if (!currentNode) return []

    // construct the path to the current node
    const parentsPath: string[] = []
    for (let parent = currentNode.parent; parent; parent = parent.parent) {
      parentsPath.push(parent.id)
    }
    parentsPath.reverse()
    return parentsPath
  }

  getPathToNode(id: string): string[] {
    return [...this.getParentsPathToNode(id), id]
  }

  isVisible(id: string): boolean {
    const parentsPath = this.getParentsPathToNode(id)
    return parentsPath.every(nodeId => this.expandedIds.has(nodeId))
  }

  getFirstVisibleParent(id: string): TreeDataNode<Payload> | null {
    const parentsPath = this.getParentsPathToNode(id)
    if (!parentsPath.length) return null

    const firstVisibleParentId = parentsPath.find(p => !this.isOpen(p))

    return this.nodes.get(firstVisibleParentId || '') ?? null
  }

  getNextVisibleNode(id: string): TreeDataNode<Payload> | undefined {
    // if the current node is not visible, walk back up the tree
    // until we find a visible ancestor
    const node = this.isVisible(id)
      ? this.nodes.get(id)
      : this.getFirstVisibleParent(id)

    if (!node) return undefined

    // If node is expanded and has children, return first child
    if (this.expandedIds.has(node.id) && node.children?.length) {
      return node.children[0]
    }

    let currentNode = node
    // Try to get next sibling or its first expanded descendant
    while (currentNode) {
      const nextSibling = this.getNextSibling(currentNode.id)
      if (nextSibling) return nextSibling
      currentNode = currentNode.parent!
    }

    return undefined
  }

  getPrevVisibleNode(id: string): TreeDataNode<Payload> | undefined | null {
    // if the current node is not visible, return first visible parent
    if (!this.isVisible(id)) {
      return this.getFirstVisibleParent(id)
    }

    const node = this.nodes.get(id)
    if (!node) return undefined

    // Try to get previous sibling and its last expanded descendant
    const prevSibling = this.getPrevSibling(node.id)
    if (prevSibling) {
      if (
        this.expandedIds.has(prevSibling.id) &&
        prevSibling.children?.length
      ) {
        return this.getLastExpandedDescendant(prevSibling.id)
      }
      return prevSibling
    }

    // If no previous sibling, return parent

    // If the parent is the root node, return undefined
    if (node.parent?.id === ROOD_ID) {
      return undefined
    }

    return node.parent
  }

  /**
   * Note: expanded !== visible
   * There can be a node that is expanded (or its parent is expanded), but still not visible
   * because one of its ancestors is collapsed
   */
  private getLastExpandedDescendant(
    id: string
  ): TreeDataNode<Payload> | undefined {
    const node = this.nodes.get(id)
    if (!node || !this.expandedIds.has(id) || !node.children?.length) {
      return node
    }

    const lastChild = this.getLastChild(id)
    if (!lastChild) return node

    return this.getLastExpandedDescendant(lastChild.id)
  }

  getFirstVisibleNode(): TreeDataNode<Payload> | undefined {
    return this.getFirstChild(ROOD_ID)
  }

  getLastVisibleNode(): TreeDataNode<Payload> | undefined {
    return this.getLastExpandedDescendant(ROOD_ID)
  }
}
