import styled from 'styled-components'
import { breakpoint } from '@sketch/global-styles'

import { IndexLayoutOld, IndexLayoutOldMain } from '@sketch/modules-common'
import { Heading, OrderedBreadcrumbs } from '@sketch/components'

export const BreadcrumbsWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const StyledOrderedBreadcrumbs = styled(OrderedBreadcrumbs)`
  z-index: initial;
`

export const WorkspaceSubscribeLayout = styled(IndexLayoutOld)<{
  expand?: boolean
}>`
  ${({ expand }) =>
    expand &&
    breakpoint('base', 'md')`
      padding-bottom: 234px;
    `}

  ${({ expand }) =>
    expand &&
    `
    ${IndexLayoutOldMain} {
      max-width: 100%;
      margin-top: 66px;
  `}

  ${breakpoint('base', 'sm')`
      margin-top: 40px;
  `}
`

export const H1 = styled(Heading.H1)`
  text-align: center;
  margin: 32px 0 40px;
  font-size: ${({ theme }) => theme.fontSizes.I};
  line-height: 1.2;
`

export const H2 = styled(Heading.H2)`
  font-weight: 700;
  line-height: 1.6;
  margin-top: 0;
  margin-bottom: 4px;
`

export const Text = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.E};
  color: ${({ theme }) => theme.colors.foreground.secondary.C};
  margin: 0;
`

WorkspaceSubscribeLayout.defaultProps = {
  expand: true,
}
