import styled, { css } from 'styled-components'

import { breakpoint } from '@sketch/global-styles'

import { Button, Form, Input } from '@sketch/components'

export const Wrapper = styled.div`
  position: sticky;
  top: 68px;

  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: space-between;

  /* 14px + 10px from StyledFormField = 24px correct margin between
  /* MemberInvite and MemberList */
  margin-top: 4px;

  ${breakpoint('base', 'sm')`
    top: 48px;
  `}
`

export const StyledForm = styled.div`
  display: flex;
  width: 100%;

  ${breakpoint('base', 'sm')`
    flex-direction: column;
  `}
`
export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`

export const StyledInput = styled(Input)<{ $withRightPadding: boolean }>`
  font-size: 0.8125rem;
  height: 40px;

  && {
    ${({ $withRightPadding }) =>
      $withRightPadding &&
      css`
        && {
          padding-right: 125px; /* stylelint-disable-line scales/space */
        }
      `}
  }
`

export const StyledFormField = styled(Form.Field)`
  width: 100%;
  margin-bottom: 10px; /* stylelint-disable-line scales/space */
`

export const StyledButton = styled(Button)`
  height: 38px;
  min-height: 38px;
  width: 124px;
  margin-left: 16px;

  ${breakpoint('base', 'sm')`
    width: 100%;
    margin-left: 0px;
    margin-top: 8px;
  `}
`
