/**
 * Maximum amount of seats that can be set per workspace.
 */
export const MAX_SEATS_VALUE = 500

/**
 * Minimal amount of seats that can be set per workspace.
 */
export const MIN_SEATS_VALUE = 0

/**
 * Minimal amount of seats that user can ADD.
 * I.e. if user wants to add more seats, we require that the minimal amount would be at least 1.
 */
export const MIN_ADD_SEATS_VALUE = 1

export const PLAN_TO_DURATION_LABEL = {
  MONTHLY: 'Month',
  YEARLY: 'Year',
  TRIAL: '1 month',
}

/**
 * Maximum amount of invitees that can be added to a workspace in a single invite action.
 */
export const INVITEE_LIMIT = {
  business: 100,
  standard: 25,
  trial: 10,
} as const

export const MEMBERS_LIMIT_ON_TRIAL = {
  WITHOUT_SUBSCRIPTION: 3,
  WITH_SUBSCRIPTION: 20,
}

export type InviteeLimitValueType = typeof INVITEE_LIMIT[keyof typeof INVITEE_LIMIT]
