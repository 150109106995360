import React, { useState } from 'react'
import {
  Button,
  ModalInjectedProps,
  Tabs,
  Tab,
  TabPanel,
  LinkButton,
  Modal as BasicModal,
} from '@sketch/components'
import {
  WorkspaceMinimalFragment,
  ProjectInSidebarAndHeaderFragment,
} from '@sketch/gql-types'
import { ProjectSharingHeader } from './ProjectSharingHeader'
import { WorkspacePanel } from './Workspace/WorkspacePanel'
import { GuestsPanel } from './Guests/GuestsPanel'
import { LeaveProjectButton } from './LeaveProjectButton'
import {
  StyledModal as Modal,
  StyledTabList as TabList,
  TabPanelWrapper,
} from '../../../shares/modals/SharingModal/SharingModal.styles'
import { GuestsTitle } from '../../../shares/modals/SharingModal/GuestsPanel'
import useProjectMemberships from './useProjectMemberships'
import { useGetRootProject } from '../../operations'

interface ProjectSharingModalProps extends ModalInjectedProps {
  project: ProjectInSidebarAndHeaderFragment
  workspace: WorkspaceMinimalFragment
}
type WorkspaceAccessLevel = ProjectInSidebarAndHeaderFragment['workspaceAccessLevel']

export const ProjectSharingModal: React.FC<ProjectSharingModalProps> = ({
  project,
  workspace,
  hideModal,
}) => {
  const [
    workspaceAccessLevel,
    setWorkspaceAccessLevel,
  ] = useState<WorkspaceAccessLevel>(project.workspaceAccessLevel)

  const rootProject = useGetRootProject(
    workspace.identifier,
    project.identifier
  )

  const hasAccess = workspaceAccessLevel === 'DEFAULT'

  const { guestsCount } = useProjectMemberships(project.identifier)

  // We only a show the share modal for root projects, nested projects inherit
  // the share settings from the root project
  return project.parentProjectIdentifier ? (
    <BasicModal onCancel={hideModal}>
      <BasicModal.Header>Share: {project.name}</BasicModal.Header>
      <BasicModal.Body>
        This project use the same Share Settings as “{rootProject.project.name}
        ”.
      </BasicModal.Body>
      <BasicModal.Footer
        leftContent={
          // TODO: add correct link
          // https://github.com/sketch-hq/Cloud/issues/19635
          <LinkButton
            external
            href="https://www.sketch.com/support/"
            onClick={() => hideModal()}
          >
            Learn More ↗
          </LinkButton>
        }
      >
        <LinkButton to={rootProject.link}>View Root Project</LinkButton>
        <Button variant="primary" onClick={hideModal}>
          Done
        </Button>
      </BasicModal.Footer>
    </BasicModal>
  ) : (
    <Modal onCancel={hideModal}>
      <ProjectSharingHeader
        project={project}
        workspaceId={workspace.identifier}
        workspaceName={workspace.name}
      />
      <Modal.Body>
        <Tabs>
          <TabList shouldDrawSeparator>
            <Tab>Workspace Members {!hasAccess && `(Restricted)`}</Tab>
            <Tab>
              <GuestsTitle guestsCount={guestsCount} />
            </Tab>
          </TabList>
          <TabPanel>
            <TabPanelWrapper>
              <WorkspacePanel
                workspace={workspace}
                project={project}
                setWorkspaceAccessLevel={setWorkspaceAccessLevel}
                hasAccess={hasAccess}
              />
            </TabPanelWrapper>
          </TabPanel>
          <TabPanel>
            <TabPanelWrapper>
              <GuestsPanel workspace={workspace} project={project} />
            </TabPanelWrapper>
          </TabPanel>
        </Tabs>
      </Modal.Body>
      <Modal.Footer
        leftContent={
          project.userCanLeave ? (
            <LeaveProjectButton project={project} />
          ) : undefined
        }
      >
        <Button variant="primary" onClick={hideModal}>
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
