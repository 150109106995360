import React from 'react'

import { PersonAvatar } from '@sketch/components'

import {
  TooltipAvatar,
  NotificationAvatarWrapper,
} from '../NotificationListItem.styles'

const NotificationAvatar: React.FC<{
  avatar: string
  name: string
}> = ({ avatar, name }) => (
  <NotificationAvatarWrapper>
    {avatar ? (
      <TooltipAvatar content={name}>
        <PersonAvatar flavour="image" size="16px" name={name} src={avatar} />
      </TooltipAvatar>
    ) : (
      <PersonAvatar flavour="color" size="16px" name={name} />
    )}
  </NotificationAvatarWrapper>
)

export default NotificationAvatar
