import React, { useCallback } from 'react'

import { addProjectMembership } from '../../../operations/utils/projectMemberships.cache'

import { FieldAutoCompleteFormValues, useFlag } from '@sketch/modules-common'
import { Flex, Text, LoadPageSeparator } from '@sketch/components'
import { AddMemberForm } from '../ProjectSharingForm'
import { GuestMemberRow } from './GuestMemberRow'

import {
  EmptyTextContainer,
  PersonIconStyled as PersonIcon,
} from './GuestsPanel.styles'

// GraphQL
import {
  WorkspaceMinimalFragment,
  useAddProjectMemberMutation,
  ProjectInSidebarAndHeaderFragment,
  ProjectMembershipFragment,
  GetWorkspaceMembersCountWithCustomerDocument,
  GetWorkspaceMembersCountWithCustomerQueryVariables,
} from '@sketch/gql-types'
import useProjectMemberships from '../useProjectMemberships'

interface FormValues extends FieldAutoCompleteFormValues {
  guestCommentsEnabled: boolean
  guestInspectEnabled: boolean
}

interface GuestsPanelProps {
  project: ProjectInSidebarAndHeaderFragment
  workspace: WorkspaceMinimalFragment
  editable?: boolean
}

export const GuestsPanel: React.FC<GuestsPanelProps> = ({
  project,
  workspace,
  editable = true,
}) => {
  // Remove this FF on
  // https://github.com/sketch-hq/Cloud/issues/19536
  const isTrialAbuse = useFlag('trial-abuse')

  const { guests, after, loadNewPage } = useProjectMemberships(
    project.identifier
  )

  const [addMemberToProject] = useAddProjectMemberMutation({
    onError: 'show-toast',
    update(cache, response) {
      if (!response.data?.addProjectMember.projectMembership) {
        return
      }

      addProjectMembership({
        cache,
        projectIdentifier: project.identifier,
        membership: response.data?.addProjectMember.projectMembership,
      })
    },
    awaitRefetchQueries: true,
    refetchQueries: () =>
      isTrialAbuse
        ? [
            {
              query: GetWorkspaceMembersCountWithCustomerDocument,
              variables: {
                workspaceId: workspace.identifier,
              } as GetWorkspaceMembersCountWithCustomerQueryVariables,
            },
          ]
        : [],
  })

  const handleAddMember = useCallback(
    async (values: FormValues) => {
      const variables = {
        projectIdentifier: project.identifier,
        email: values.email || undefined,
        workspaceMembershipIdentifier:
          values.workspaceMembershipIdentifier || undefined,
        guestCommentsEnabled: values.guestCommentsEnabled,
        guestInspectEnabled: values.guestInspectEnabled,
      }

      await addMemberToProject({ variables })
    },
    [addMemberToProject, project.identifier]
  )

  return (
    <>
      <AddMemberForm
        workspace={workspace}
        projectIdentifier={project.identifier}
        onMemberAdd={handleAddMember}
        // Permissions Groups are not supported for Guests
        onGroupAdd={null}
        hideLabel
        filterMembers="Guests"
        removeSelf
      />
      {guests.length === 0 ? (
        <Flex justifyContent="center" alignItems="center">
          <EmptyTextContainer>
            <PersonIcon height={64} width={64} />
            <Text textStyle="copy.primary.standard.E" pt={3}>
              No Guests yet
            </Text>
            <Text textStyle="copy.quaternary.standard.D">
              Enter a name or email to invite somebody as a Guest.
            </Text>
          </EmptyTextContainer>
        </Flex>
      ) : (
        <>
          {guests.map(member => (
            <GuestMemberRow
              key={member.identifier}
              member={member as ProjectMembershipFragment}
              project={project}
              editable={editable}
              workspaceIdentifier={workspace.identifier}
            />
          ))}

          {after && <LoadPageSeparator key={after} loadNewPage={loadNewPage} />}
        </>
      )}
    </>
  )
}
