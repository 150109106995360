import React from 'react'
import copy from 'copy-to-clipboard'

import { routes } from '@sketch/modules-common'

import { Dropdown, useModalContext } from '@sketch/components'

import { useToast } from '@sketch/toasts'

import {
  ProjectInSidebarAndHeaderFragment,
  TrashedProjectFragment,
  useLeaveProjectMutation,
  WorkspaceMinimalFragment,
  usePinProjectMutation,
  useUnpinProjectMutation,
} from '@sketch/gql-types'

import DeleteProject from '../../modals/DeleteProject'
import DeleteProjectPermanently from '../../modals/DeleteProjectPermanently'
import RestoreProject from '../../modals/RestoreProject'
import { ProjectSharingModal } from '../../modals/ProjectSharingModal'
import { CreateCollectionModal } from '../../../collections/modals'
import { CreateNestedProjectModal } from '../../../nestedProjects/modals'
import { ConfirmRemoveMemberModal } from '../RemoveMemberModal'

import { updatePinnedProject, updateUnpinnedProject } from './cache'

interface ProjectProps {
  project: Pick<
    ProjectInSidebarAndHeaderFragment | TrashedProjectFragment,
    'identifier' | 'name' | '__typename' | 'deletedAt'
  >
}

interface ProjectAndWorkspaceProps extends ProjectProps {
  workspaceId: string
}

interface RenameProjectActionProps {
  onRename: () => void
}

interface LeaveProjectActionProps {
  projectIdentifier: string
  projectName: string
}

type PinProjectProps = {
  pinned?: string | null
  projectIdentifier: string
  workspaceId: string
}

export const CreateCollectionAction = ({
  project,
  workspaceIdentifier,
}: {
  project: ProjectInSidebarAndHeaderFragment
  workspaceIdentifier: string
}) => {
  const { showModal } = useModalContext()

  return (
    <Dropdown.Item
      onClick={() => {
        showModal(CreateCollectionModal, {
          project,
          workspaceIdentifier,
        })
      }}
    >
      Create Collection&hellip;
    </Dropdown.Item>
  )
}

export const CreateNestedProjectAction = ({
  project,
  workspaceIdentifier,
}: {
  project: ProjectInSidebarAndHeaderFragment
  workspaceIdentifier: string
}) => {
  const { showModal } = useModalContext()

  return (
    <Dropdown.Item
      onClick={() => {
        showModal(CreateNestedProjectModal, {
          project,
          workspaceIdentifier,
        })
      }}
    >
      Create Project&hellip;
    </Dropdown.Item>
  )
}

export const LeaveProjectAction: React.FC<LeaveProjectActionProps> = ({
  projectIdentifier,
  projectName,
}) => {
  const { showModal, hideModal } = useModalContext()
  const [leaveProject] = useLeaveProjectMutation({
    variables: { projectIdentifier },
    onError() {
      // onError is used in `<RemoveMemberButton .../>` to handle the case
      // where a user is removing themselves we must show them the confirmation
      // dialog first. All other errors are ignored there.
      //
      // Since we are showing the confirmation dialog in all circumstances (and
      // providing `confirmed: true`) it is not necessary to handle this error
      // case.
      return
    },
  })

  const onConfirm = async () => {
    await leaveProject()
    hideModal()
  }

  return (
    <Dropdown.Item
      intent="negative"
      onClick={() => {
        showModal(ConfirmRemoveMemberModal, {
          projectName,
          onConfirm,
          onCancelAction: hideModal,
        })
      }}
    >
      Leave&hellip;
    </Dropdown.Item>
  )
}
export const DeleteProjectAction: React.FC<ProjectAndWorkspaceProps> = ({
  project,
  workspaceId,
}) => {
  const { showModal } = useModalContext()

  return (
    <Dropdown.Item
      intent="negative"
      onClick={() => {
        showModal(DeleteProject, {
          projectId: project.identifier,
          projectName: project.name,
          workspaceId,
        })
      }}
    >
      Delete&hellip;
    </Dropdown.Item>
  )
}

export const RenameProjectAction: React.FC<RenameProjectActionProps> = ({
  onRename,
}) => (
  <Dropdown.Item data-option-item onClick={onRename}>
    Rename&hellip;
  </Dropdown.Item>
)

export const CopyProjectAction: React.FC<ProjectAndWorkspaceProps> = ({
  project,
  workspaceId,
}) => {
  const { showToast } = useToast()

  return (
    <Dropdown.Item
      onClick={() => {
        const projectLink = (project.deletedAt
          ? routes.WORKSPACE_TRASH_PROJECT
          : routes.WORKSPACE_PROJECT
        ).create({
          workspaceId,
          projectId: project.identifier,
        })

        copy(`${window.location.origin}${projectLink}`)
        showToast('Link copied')
      }}
    >
      Copy Link
    </Dropdown.Item>
  )
}

export const RestoreProjectAction: React.FC<ProjectAndWorkspaceProps> = ({
  project,
  workspaceId,
}) => {
  const { showModal } = useModalContext()

  return (
    <Dropdown.Item
      onClick={() => showModal(RestoreProject, { project, workspaceId })}
    >
      Restore&hellip;
    </Dropdown.Item>
  )
}

export const DeleteProjectPermanentlyAction: React.FC<ProjectAndWorkspaceProps> = ({
  project,
  workspaceId,
}) => {
  const { showModal } = useModalContext()

  return (
    <Dropdown.Item
      intent="negative"
      onClick={() =>
        showModal(DeleteProjectPermanently, { project, workspaceId })
      }
    >
      Delete Permanently&hellip;
    </Dropdown.Item>
  )
}

export const ShareProjectAction: React.FC<{
  project: ProjectInSidebarAndHeaderFragment
  workspace: WorkspaceMinimalFragment
}> = ({ project, workspace }) => {
  const { showModal } = useModalContext()

  const handleShareClick = () => {
    showModal(ProjectSharingModal, {
      project,
      workspace,
    })
  }

  return <Dropdown.Item onClick={handleShareClick}>Share&hellip;</Dropdown.Item>
}

export const PinProjectAction = ({
  pinned,
  projectIdentifier,
  workspaceId,
}: PinProjectProps) => {
  const [pinProject] = usePinProjectMutation({
    variables: {
      projectIdentifier,
    },
    onError: 'show-toast',
    update(cache, { data }) {
      if (!data) return

      updatePinnedProject({ cache, data, workspaceId })
    },
  })

  const [unpinProject] = useUnpinProjectMutation({
    variables: {
      projectIdentifier,
    },
    onError: 'show-toast',
    awaitRefetchQueries: true,
    update(cache, { data }) {
      if (!data) return

      updateUnpinnedProject({ cache, data, workspaceId })
    },
  })

  if (pinned) {
    return (
      <Dropdown.Item
        onClick={() => unpinProject({ variables: { projectIdentifier } })}
      >
        Unpin Project
      </Dropdown.Item>
    )
  }

  return (
    <Dropdown.Item
      onClick={() => pinProject({ variables: { projectIdentifier } })}
    >
      Pin Project
    </Dropdown.Item>
  )
}
