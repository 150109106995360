import styled, { css } from 'styled-components'
import { ButtonUnstyled } from '@sketch/components'
import { ReactComponent as ChevronRightIcon } from '@sketch/icons/chevron-right-16'
import { breakpoint } from '@sketch/global-styles'

export const IconWrapper = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 12px;
  flex-shrink: 0;

  pointer-events: none;
`

const chevronSize = css`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`

export const ChevronButton = styled(ButtonUnstyled)`
  ${chevronSize}

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: ${({ theme }) => theme.radii.small};

  &:hover {
    background-color: ${({ theme }) => theme.colors.background.tertiary.C};
  }
`

export const ChevronEmpty = styled.div`
  ${chevronSize}
`

export const Chevron = styled(ChevronRightIcon)(
  ({ theme: { transitions } }) => css`
    width: 16px;
    height: 16px;
    opacity: 0.55;

    transition: transform ${transitions.duration[1]}
      ${transitions.timing.easeInOut};
  `
)

export const Icon = styled.div`
  width: 100%;
  height: 100%;

  /* This color should be "Light/colors.foreground.secondary.4" but given the icon composition */
  /* Opacity needs to be applied to the whole object */
  color: ${({ theme }) => theme.colors.foreground.secondary.A};

  opacity: 0.55;
`

const hoverStyle = css`
  background-color: ${({ theme }) => theme.colors.background.tertiary.C};
  color: ${({ theme }) => theme.colors.foreground.secondary.B};

  /* Fix the hover color of the icon */
  ${Icon}, ${Chevron} {
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
    opacity: 1;
  }
`

const selectedStyle = css`
  background-color: ${({ theme }) => theme.colors.sketch.F};
  color: ${({ theme }) => theme.colors.sketch.A};

  ${Icon}, ${Chevron} {
    color: ${({ theme }) => theme.colors.sketch.A};
    opacity: 1;
  }

  ${ChevronButton} {
    &:hover {
      background-color: ${({ theme }) => theme.colors.sketch.F};
    }
  }
`

export const TreeItem = styled.span<{ depth: number }>`
  overflow: hidden;

  width: 100%;
  height: 40px;

  ${breakpoint('sm')`
    height: 32px;
  `}

  display: flex;
  align-items: center;

  background-color: transparent;
  padding: 8px 0 8px ${({ depth }) => 16 * depth + 12}px;
  border-radius: 5px;

  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: ${({ theme }) => theme.fontSizes.D};
  line-height: 1.2;

  color: ${({ theme }) => theme.colors.foreground.secondary.B};

  cursor: pointer;

  transition: none;

  :hover,
  :focus,
  :focus-within {
    ${hoverStyle};
  }

  &[aria-selected='true'] {
    ${selectedStyle};
  }

  &[aria-expanded='true'] {
    ${Chevron} {
      transform: rotate(90deg);
    }
  }
`
