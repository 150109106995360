import { dataIdFromObject } from '@sketch/graphql-cache'

import { useOnEvent } from '@sketch/utils'
import { isNetworkRequestInFlight } from 'apollo-client/core/networkStatus'

import { handleFetchMore } from '@sketch/components'

import { useGetProjectCollections } from '../../../collections/hooks'
import { useProjectBanner } from '../useProjectBanner'
import { useGetProjectShares } from './useGetProjectShares'

import { ShareSearchFilter } from '../../../shares/hooks/useSearchFilters'
import { useGetNestedProjects } from './useGetNestedProjects'

interface UseProjectsProps {
  workspaceId: string
  projectId: string
  search?: string
  isNestedProjectsOn?: boolean
  filters?: ShareSearchFilter[]
}

const sharesEntriesPath = ['project', 'shares', 'entries']
const collectionsEntriesPath = ['project', 'collections', 'entries']
const nestedProjectsEntriesPath = ['project', 'projects', 'entries']

export const useProject = ({
  workspaceId,
  projectId,
  search,
  filters = [],
  isNestedProjectsOn,
}: UseProjectsProps) => {
  const {
    data: projectSharesData,
    networkStatus: projectSharesNetworkStatus,
    fetchMore: fetchMoreProjectShares,
    error: projectSharesError,
    refetch: refetchProjectShares,
  } = useGetProjectShares({
    projectIdentifier: projectId,
    searchTerm: search,
    filters,
  })

  const {
    data: collectionsData,
    networkStatus: collectionNetworkStatus,
    fetchMore: fetchMoreCollections,
    error: collectionsError,
    refetch: refetchProjectCollections,
  } = useGetProjectCollections({
    projectIdentifier: projectId,
    searchTerm: search,
    filters,
  })

  const {
    data: nestedProjectsData,
    networkStatus: nestedProjectsNetworkStatus,
    fetchMore: fetchMoreNestedProjects,
    error: nestedProjectsError,
    refetch: refetchNestedProjects,
  } = useGetNestedProjects({
    projectIdentifier: projectId,
    skip: !isNestedProjectsOn,
  })

  useOnEvent(
    'workspaceShareRefresh',
    ({ workspaceIdentifier, projectIdentifier, onlyProjects }) => {
      if (
        workspaceIdentifier !== workspaceId ||
        projectIdentifier !== projectId
      ) {
        // Not the workspace visible ignore then
        return
      }

      if (onlyProjects) {
        refetchNestedProjects()
        return
      }

      refetchProjectShares()
      refetchProjectCollections()
      refetchNestedProjects()
    }
  )

  useProjectBanner({ project: projectSharesData?.project, workspaceId })

  const loading =
    isNetworkRequestInFlight(projectSharesNetworkStatus) ||
    isNetworkRequestInFlight(collectionNetworkStatus) ||
    isNetworkRequestInFlight(nestedProjectsNetworkStatus)
  const error = projectSharesError || collectionsError || nestedProjectsError

  const project = projectSharesData?.project
  const afterShares = project?.shares.meta.after ?? null
  const afterCollections =
    collectionsData?.project.collections.meta.after ?? null
  const afterNestedProjects =
    nestedProjectsData?.project.projects.meta.after ?? null

  const loadMoreSharesHandler = handleFetchMore(
    fetchMoreProjectShares,
    sharesEntriesPath,
    { dataIdFromObject, after: afterShares }
  )

  const loadMoreNestedProjectsHandler = handleFetchMore(
    fetchMoreNestedProjects,
    nestedProjectsEntriesPath,
    { dataIdFromObject, after: afterNestedProjects }
  )

  const loadMoreCollectionsHandler = handleFetchMore(
    fetchMoreCollections,
    collectionsEntriesPath,
    { dataIdFromObject, after: afterCollections }
  )

  return {
    data: {
      project: projectSharesData?.project,
      collections: collectionsData?.project.collections,
      nestedProjects: nestedProjectsData?.project.projects,
    },
    loadMore: {
      project: loadMoreSharesHandler,
      collections: loadMoreCollectionsHandler,
      nestedProjects: loadMoreNestedProjectsHandler,
    },
    loading,
    error,
  }
}
