import styled, { css } from 'styled-components'

import {
  Box,
  Text,
  Tooltip,
  Button,
  ButtonUnstyled,
  Markdown as BaseMarkdown,
} from '@sketch/components'
import { linkStyles, breakpoint } from '@sketch/global-styles'

import { ReactComponent as CheckIcon } from '@sketch/icons/check-16'
import { ReactComponent as ExclamationMarkIcon } from '@sketch/icons/exclamation-mark-circle-11'
import { ReactComponent as ArrowDownCircle } from '@sketch/icons/arrow-down-circle-16'
import { ReactComponent as BellIcon } from '@sketch/icons/bell-24'
import { ReactComponent as BellStrikeThroughIcon } from '@sketch/icons/bell-strikethrough-24'
import { ReactComponent as StarIcon } from '@sketch/icons/star-16'

export const MarkNotificationAsReadButton = styled(Button).attrs({
  size: '32',
})`
  padding: 4px 8px;
`

export const SubscriptionButton = styled(MarkNotificationAsReadButton).attrs({
  size: '32',
})`
  margin-left: 12px;
`

export const NotificationItemContainer = styled.div`
  min-width: 0;
  display: flex;
  flex-direction: column;
`

export const NotificationShareName = styled(Text).attrs({
  as: 'span',
  textStyle: 'copy.quaternary.standard.C',
})``

export const NotificationDescription = styled(Text).attrs({
  as: 'span',
  textStyle: 'copy.secondary.standard.E',
})`
  overflow-wrap: 'break-word';
`

export const NotificationBody = styled(Box).attrs({
  mt: 1,
  mb: 0,
})(
  ({ theme }) => css`
    display: flex;
    flex: 1;

    font-weight: ${theme.fontWeights.regular};
    font-size: ${theme.fontSizes.E};
    color: ${theme.colors.foreground.secondary.B};
    overflow-wrap: break-word;
  `
)

export const NotificationItemContainerMobile = styled(
  NotificationItemContainer
)`
  width: 100%;
  margin-left: 0;

  ${NotificationBody} {
    align-items: flex-start;
  }
`

export const NotificationsDate = styled(Text).attrs({
  textStyle: 'copy.quaternary.standard.E',
  as: 'span',
})`
  flex-shrink: 0;
`

export const NotificationsDateMobile = styled(NotificationsDate)`
  text-align: right;
`

export const NotificationsType = styled(Text).attrs({
  as: 'span',
  textStyle: 'copy.quaternary.standard.E',
})``

// Other styles
export const UnreadIndicator = styled.div`
  position: relative;

  font-size: 0.75rem;
  text-align: center;
  flex-shrink: 0;
  line-height: 1;

  ::after {
    color: ${({ theme }) => theme.colors.sketch.A};
    content: '●';
  }
`

export const MobileHeader = styled.div<{ $isRead: boolean }>(
  ({ $isRead }) => css`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;

    ${UnreadIndicator} {
      &:before {
        top: 12px;
        left: 0;
      }
    }
  `
)

export const FailedAsset = styled.div`
  color: ${({ theme }) => theme.colors.state.negative.A};
`

export const MarkAsReadButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  width: 128px;
  flex-shrink: 0;

  @media (hover: hover) {
    opacity: 0;
  }
`

const iconCSS = css`
  width: 24px;
  height: 24px;
`

export const MarkAsReadIcon = styled(CheckIcon)`
  ${iconCSS}
`

export const SubscribeIcon = styled(BellIcon)`
  ${iconCSS}
`

export const UnsubscribeIcon = styled(BellStrikeThroughIcon)`
  ${iconCSS}
`

export const StarredIcon = styled(StarIcon)`
  width: 16px;
  height: 16px;
  margin-right: 4px;
  flex: 0 0 auto;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
`

export const FailedIcon = styled(ExclamationMarkIcon)`
  width: 11px;
  height: 11px;
  flex: 0 0 auto;
`

export const AssetReadyIcon = styled(ArrowDownCircle)`
  width: 16px;
  height: 16px;
  margin-right: 4px;
  margin-top: 2px; /* stylelint-disable-line scales/space */
  flex: 0 0 auto;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
`

export const TooltipAvatar = styled(Tooltip)`
  display: flex;
`

export const NotificationAvatarWrapper = styled.div`
  margin-right: 8px;
  margin-top: 2px; /* stylelint-disable-line scales/space */
`

export const DownloadAsset = styled(ButtonUnstyled)(
  ({ theme }) => css`
    ${linkStyles};
    text-decoration: underline;
    font-weight: ${theme.fontWeights.semibold};
  `
)

export const Markdown = styled(BaseMarkdown)`
  overflow: hidden;

  /* Make sure the markdown text is matching color set by "NotificationBody" */
  color: currentColor;
`

export const Skeleton = styled.div(
  ({ theme }) => css`
    width: 100%;
    border-radius: ${theme.radii.xlarge};
    min-height: 70px;

    &:nth-child(odd) {
      background-color: ${theme.colors.background.secondary.A};
    }
  `
)

export const SkeletonMobile = styled(Skeleton)`
  min-height: 140px;
`

export const NotificationFlex = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  max-width: 100%;
`

export const NotificationFlexMobile = styled(NotificationFlex)`
  flex-direction: column;
`

// Notification structure
export const NotificationContainer = styled(Skeleton)<{
  $isRead?: boolean
}>(
  ({ theme, $isRead }) => css`
    display: flex;
    align-items: flex-start;

    /* Fix styles inherited by LinkUnstyled */
    font-weight: 400;
    padding: 16px;

    &:hover {
      background: ${theme.colors.state.hover};

      ${breakpoint('base', 'sm')`
        background: none;
      `}
    }

    white-space: pre-wrap;
    color: inherit;

    position: relative;
    transition: background ${theme.transitions.duration[1]}
      ${theme.transitions.timing.easeOut};

    /* stylelint-disable-next-line no-descending-specificity */
    &:hover,
    [data-testid='notifications-read-title'] ~ &:hover {
      ${breakpoint('base', 'sm')`
        border-radius: ${theme.radii.large};

        ${breakpoint('xs')`
          border-radius: ${theme.radii.xlarge};
        `}
      
      `}

      ${MarkAsReadButtonContainer} {
        opacity: 1;
      }
    }

    ${NotificationFlex} {
      padding: 16px;

      ${breakpoint('base', 'sm')`
        padding: 0px;
        margin-left: 0px;
      `}
    }
  `
)

export const NotificationContainerMobile = styled(NotificationContainer)<{
  $isRead?: boolean
}>(
  () => css`
    margin-left: 0;
    padding: 16px;

    ${Skeleton} {
      height: 140px;
    }

    ${NotificationsType} {
      text-align: left;
      color: ${({ theme }) => theme.colors.foreground.secondary.A};
    }

    ${UnreadIndicator} + ${NotificationsType} {
      margin-left: 16px;
    }

    /* stylelint-disable-next-line no-descending-specificity */
    ${MarkAsReadButtonContainer} {
      margin-top: 8px;
      opacity: 1;
      align-self: end;
    }
  `
)

export const NotificationContainerDesktop = styled(NotificationContainer)`
  gap: 16px;
  align-items: center;
  position: relative;
  overflow: hidden;

  ${UnreadIndicator} {
    width: 32px;
    margin-left: -8px;
  }

  ${NotificationItemContainer} {
    flex: 1 1 60%;
    max-width: 80%;
  }

  ${NotificationsDate}, ${NotificationsType} {
    flex: 1 0 90px;
  }

  ${breakpoint('sm', 'md')`
    ${MarkAsReadButtonContainer} {
      position: absolute;
      padding: 16px;
      right: 0;
      height: 100%;
      backdrop-filter: blur(15px);
    }
  `}
`
