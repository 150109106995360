import React from 'react'
import copy from 'copy-to-clipboard'
import { useRouteMatch } from 'react-router-dom'

import { routes, isProjectTrashRoute } from '@sketch/modules-common'
import { useToast } from '@sketch/toasts'

import {
  Dropdown,
  useResponsiveDropdown,
  Box,
  useForTablet,
} from '@sketch/components'

import {
  DropdownButton,
  DropdownButtonEllipsis,
} from '../../../shares/components/DocumentItem/DocumentItem.styles'

import {
  NestedProjectFragment,
  ProjectFragment,
  ProjectInSidebarAndHeaderFragment,
  WorkspaceMinimalFragment,
} from '@sketch/gql-types'
import { NestedProjectHeaderActionsProps } from './types'

interface CopyLinkProps {
  workspaceId: WorkspaceMinimalFragment['identifier']
  nestedProjectId: NestedProjectFragment['identifier']
}

interface DeleteNestedProjectProps {
  nestedProject: NestedProjectFragment
  project: ProjectFragment
  workspaceId: WorkspaceMinimalFragment['identifier']
}

interface EditNestedProjectActionProps {
  workspaceIdentifier: WorkspaceMinimalFragment['identifier']
  nestedProject: NestedProjectFragment
  project: ProjectInSidebarAndHeaderFragment
}

export const EditNestedProjectAction: React.FC<EditNestedProjectActionProps> = ({
  workspaceIdentifier,
  nestedProject,
  project,
}) => {
  const { showToast } = useToast()

  const handleEditNestedProject = () => {
    // eslint-disable-next-line no-console
    console.log('Edit nested project', {
      workspaceIdentifier,
      nestedProject,
      project,
    })
    showToast('Not implemented yet', 'info')
  }

  return (
    <Dropdown.Item unmountAfterClick onClick={handleEditNestedProject}>
      Edit&hellip;
    </Dropdown.Item>
  )
}

export const CopyNestedProjectAction: React.FC<CopyLinkProps> = ({
  workspaceId,
  nestedProjectId,
}) => {
  const { showToast } = useToast()

  return (
    <Dropdown.Item
      onClick={() => {
        const projectLink = routes.WORKSPACE_PROJECT.create({
          workspaceId,
          projectId: nestedProjectId,
        })

        copy(`${window.location.origin}${projectLink}`)
        showToast('Link copied')
      }}
    >
      Copy Link
    </Dropdown.Item>
  )
}

export const DeleteNestedProjectAction: React.FC<DeleteNestedProjectProps> = ({
  nestedProject,
  project,
  workspaceId,
}) => {
  const { showToast } = useToast()

  return (
    <Dropdown.Item
      intent="negative"
      onClick={() => {
        // eslint-disable-next-line no-console
        console.log('Delete nested project', {
          nestedProject,
          project,
          workspaceId,
        })
        showToast('Not implemented yet', 'info')
      }}
    >
      Remove&hellip;
    </Dropdown.Item>
  )
}

export const NestedProjectDropdownContent: React.FC<NestedProjectHeaderActionsProps> = ({
  userCanManageNestedProject,
  workspace,
  nestedProject,
  project,
}) => {
  const { path } = useRouteMatch()
  const isInTrash = isProjectTrashRoute(path)
  const isTabletAndBigger = useForTablet()
  const isMobile = !isTabletAndBigger

  return (
    <Box width={isMobile ? '100%' : 212}>
      <Dropdown.Header>Project</Dropdown.Header>
      {userCanManageNestedProject && !isInTrash && (
        <EditNestedProjectAction
          workspaceIdentifier={workspace.identifier}
          nestedProject={nestedProject}
          project={project}
        />
      )}
      <CopyNestedProjectAction
        workspaceId={workspace.identifier}
        nestedProjectId={nestedProject.identifier}
      />
      {userCanManageNestedProject && (
        <>
          <Dropdown.Divider />
          <DeleteNestedProjectAction
            nestedProject={nestedProject}
            project={project}
            workspaceId={workspace.identifier}
          />
        </>
      )}
    </Box>
  )
}

const NestedProjectDropdown: React.FC<NestedProjectHeaderActionsProps> = ({
  userCanManageNestedProject,
  workspace,
  nestedProject,
  project,
}) => {
  const [content, buttonProps] = useResponsiveDropdown({
    dropdown: NestedProjectDropdownContent,
    dropdownProps: {
      userCanManageNestedProject,
      workspace,
      nestedProject,
      project,
    },
    placement: 'bottom-end',
    usePortal: true,
  })

  return (
    <div onClick={event => event.stopPropagation()}>
      <DropdownButton data-testid="nestedProject-dropdown" {...buttonProps}>
        <DropdownButtonEllipsis aria-label="Project Settings" />
        <span className="sr-only">Project Settings</span>
      </DropdownButton>
      {content}
    </div>
  )
}

export default NestedProjectDropdown
