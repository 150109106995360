import React, {
  FC,
  ReactNode,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react'
import { useLocalStorage } from 'react-use'

import { useFlag, useGetNewNotificationsCount } from '@sketch/modules-common'

import { useThemeContext } from '@sketch/global-styles'

import { localStorageKeys } from '@sketch/constants'

import { useCanUseDesignSystemManager } from '../../../designSystems/operations/useCanUseDesignSystemManager'
import { useIsProfileEnabled } from '../../../community/hooks'
import { canUserAdministerShares as getCanUserAdministerShares } from '../../utils'
import useGetActiveProjectInRoute from '../../../projects/hooks/useGetActiveProjectInRoute'

import * as Collapsible from '@radix-ui/react-collapsible'
import { Box, UnstyledList, ModalContext } from '@sketch/components'
import UserNavbarSection from '../../../user/components/UserNavbarSection'
import { EmptyTrashModal } from '../../../shares/components/EmptyTrashModal'
import { ProjectsMenu } from '../../../projects/components/ProjectsMenu'
import { ProjectsMenu as ProjectsMenuOld } from '../../../projects/components/ProjectsMenuOld'
import { useShareDeleteWithUi } from '../../../shares/components/DeleteShareModal'
import { DesignSystemSidebar } from '../../../designSystems/components'

import { WorkspacesDropdown } from '../../containers/WorkspacesDropdown'
import { MenuItem, getWorkspaceMenuItems } from './menuItems'
import BottomMenu from './components/BottomMenu/BottomMenu'

import {
  SidebarWrapper,
  WorkspaceSelectorWrapper,
  MenuWrapper,
  Header,
  FooterWrapper,
  CollapsibleIcon,
  MenuListItem,
} from './Sidebar.styles'

// GQL
import {
  GetProjectsQuery,
  useGetInitialUserQuery,
  WorkspaceMinimalFragment,
} from '@sketch/gql-types'

import { WorkspaceSidebarNotice } from '../WorkspaceSidebarNotice'

interface SidebarProps {
  workspace: WorkspaceMinimalFragment
}

// Helper Components
const renderMenuItems = (menuItems: MenuItem[]): ReactNode => (
  <UnstyledList role={!menuItems.length ? undefined : 'menu'}>
    {menuItems.map(({ key, item, hidden }) =>
      !hidden ? <MenuListItem key={key}>{item}</MenuListItem> : null
    )}
  </UnstyledList>
)

export const Sidebar: FC<SidebarProps> = ({ workspace }) => {
  const { showModal } = useContext(ModalContext)

  const { isDarkMode } = useThemeContext()
  const isDesignSystemsOn = useFlag('design-systems')
  const isNestedProjectsOn = useFlag('nested-projects')

  const { isProfileEnabled } = useIsProfileEnabled(workspace.identifier)

  const { data: userData } = useGetInitialUserQuery()

  const { canUseDesignSystemManager } = useCanUseDesignSystemManager({
    workspace,
  })

  const localStorageKey = localStorageKeys.sidebarSectionDocumentsCollapsed
  const dsmIntroductionSeen = localStorageKeys.dsmIntroductionSeen
  const [
    isCollapsedDocumentsSection,
    setIsCollapsedDocumentsSection,
  ] = useLocalStorage(localStorageKey)

  const [introductionSeen, setIntroductionSeen] = useLocalStorage(
    dsmIntroductionSeen
  )

  const activeProject = useGetActiveProjectInRoute()
  const isCurrentProjectArchived = !!activeProject?.archivedAt

  const canUserAdministerShares = getCanUserAdministerShares(workspace)
  // We do the call here to fetch the new notifications count
  // that we will use from the cache in the LoggedIn UserNavBar section
  useGetNewNotificationsCount()

  //   Projects
  const isFinance = workspace.userRole === 'FINANCE'
  const isGuest = workspace.userRole === 'GUEST'
  const isPartner = userData?.me.isPartner

  const showProjectsList = userData && !isFinance && !isPartner

  const [hasDraftsProject, setHasDraftsProject] = useState(false)

  const handleProjectsLoad = (data: GetProjectsQuery) => {
    const draftsProject = data?.workspace.draftsProject.entries[0]
    setHasDraftsProject(Boolean(draftsProject))
  }

  // Delete share
  const [deleteShare] = useShareDeleteWithUi()

  const handleOnEmptyTrash = () => {
    showModal(EmptyTrashModal, {
      workspaceId: workspace.identifier,
    })
  }

  const workspaceMenuItems = getWorkspaceMenuItems({
    workspaceId: workspace.identifier,
    canUseDesignSystemManager,
    userRole: workspace.userRole,
    hasDraftsProject,
    canUserAdministerShares,
    handleOnEmptyTrash,
    deleteShare,
    isProfileEnabled,
    isArchiveFeatureEnabled: workspace.features.archiveEnabled,
    isCurrentProjectArchived,
  })

  const hasVisibleMenuitems = !workspaceMenuItems.every(item => item.hidden)

  const handleCollapsibleClick = (e: React.SyntheticEvent) => {
    e.preventDefault()
    setIsCollapsedDocumentsSection(!isCollapsedDocumentsSection)
  }

  // Handle DSM upsell banner
  const handleScroll = useCallback(() => {
    if (introductionSeen) return

    setIntroductionSeen(true)
    window.dispatchEvent(new Event('dsm_intro_seen'))
  }, [setIntroductionSeen, introductionSeen])

  useEffect(() => {
    if (introductionSeen) return

    window.addEventListener('scroll', handleScroll, { capture: true })

    return () => {
      window.removeEventListener('scroll', handleScroll, { capture: true })
    }
  }, [introductionSeen, handleScroll])

  return (
    <SidebarWrapper>
      <WorkspaceSelectorWrapper>
        <WorkspacesDropdown workspace={workspace} />
        <UserNavbarSection isWorkspaceGuestUser={isGuest} />
      </WorkspaceSelectorWrapper>
      <WorkspaceSidebarNotice workspace={workspace} />
      <MenuWrapper data-testid="sidebar-projects" $isDarkMode={isDarkMode}>
        {!isPartner && !isFinance && hasVisibleMenuitems && (
          <>
            <Collapsible.Root
              data-testid="documents"
              className="CollapsibleRoot"
              open={!isCollapsedDocumentsSection}
              onOpenChange={
                setIsCollapsedDocumentsSection as (isOpen: boolean) => void
              }
            >
              <Header>
                Documents
                <Collapsible.Trigger
                  data-testid="collapse-documents-button"
                  asChild
                >
                  <CollapsibleIcon
                    isOpen={!isCollapsedDocumentsSection}
                    onClick={handleCollapsibleClick}
                  />
                </Collapsible.Trigger>
              </Header>
              {!isCollapsedDocumentsSection ? (
                <Collapsible.Content data-testid="documents-content">
                  {renderMenuItems(workspaceMenuItems)}
                </Collapsible.Content>
              ) : (
                <>
                  {renderMenuItems(
                    workspaceMenuItems.filter((item: MenuItem) => item.isActive)
                  )}
                </>
              )}
            </Collapsible.Root>
          </>
        )}

        {(!isDesignSystemsOn || !canUseDesignSystemManager) && <Box mb={4} />}

        {isDesignSystemsOn && canUseDesignSystemManager && (
          <DesignSystemSidebar workspace={workspace} />
        )}

        {showProjectsList &&
          (isNestedProjectsOn ? (
            <ProjectsMenu
              workspace={workspace}
              canUserAdministerShares={canUserAdministerShares}
              onLoadCompleted={handleProjectsLoad}
            />
          ) : (
            <ProjectsMenuOld
              workspace={workspace}
              canUserAdministerShares={canUserAdministerShares}
              onLoadCompleted={handleProjectsLoad}
            />
          ))}

        {isDesignSystemsOn && !canUseDesignSystemManager && (
          <DesignSystemSidebar workspace={workspace} />
        )}
      </MenuWrapper>
      <FooterWrapper>
        <BottomMenu workspace={workspace} />
      </FooterWrapper>
    </SidebarWrapper>
  )
}
