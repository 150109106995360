import { ProjectProps } from './ProjectListItem.types'
import { ProjectLink } from '../../types'

import { ReactComponent as FolderClosedIcon } from '@sketch/icons/folder-closed-16'
import { ReactComponent as FolderOpenIcon } from '@sketch/icons/folder-open-16'
import { ReactComponent as LockIcon } from '@sketch/icons/lock-16'
import { ReactComponent as PeopleIcon } from '@sketch/icons/persons-two-16'

export const privacyIcon = (
  project: Pick<ProjectProps['project'], 'privacyIcon'>,
  active: boolean = false
): {
  component: React.ElementType
  label: string
} => {
  switch (project.privacyIcon) {
    case 'GUESTS':
      return {
        component: PeopleIcon,
        label: 'People icon',
      }

    case 'INVITED_WORKSPACE_MEMBERS':
      return {
        component: LockIcon,
        label: 'Lock icon',
      }

    case 'WORKSPACE_MEMBERS':
    default:
      if (active) {
        return { component: FolderOpenIcon, label: 'Folder icon' }
      } else {
        return { component: FolderClosedIcon, label: 'Closed Folder icon' }
      }
  }
}

/**
 * Finds a project in the nested projects, needed to expand projects when a
 * nested project is active, or to show a newly created nested project
 */
export const hasProject = (
  projects: ProjectLink[],
  projectId: string
): boolean => {
  return projects.some(projectLink => {
    if (projectLink.project.identifier === projectId) {
      return true
    }
    return hasProject(projectLink.nestedProjects, projectId)
  })
}

// Calculates the nested level to style the project item in the sidebar
export const calculateNestedLevel = (
  projects: { [key: string]: ProjectLink },
  projectId: string
): number => {
  if (!Object.keys(projects).length || !projectId) {
    return 1
  }

  const project = projects[projectId]

  if (!project) {
    return 1
  }

  let level = 1
  let parentProjectId = project.project.parentProject?.identifier

  while (parentProjectId) {
    level++
    const parentProject = projects[parentProjectId]

    parentProjectId = parentProject?.project.parentProject?.identifier
  }

  return level
}
