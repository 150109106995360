import React from 'react'

import { ModalRestrictor } from '@sketch/components'

import { NestedProjectItemGridView } from './NestedProjectItemGridView'
import { NestedProjectItemListView } from './NestedProjectItemListView'

import { NestedProjectFragment } from '@sketch/gql-types'
import { RenderNestedProjectDropdown } from './types'

interface NestedProjectItemProps {
  nestedProject: NestedProjectFragment
  projectIdentifier: string
  workspaceIdentifier: string
  onClick?: React.MouseEventHandler<HTMLElement>
  renderDropdown: RenderNestedProjectDropdown
  presentation: 'grid' | 'list'
}

const NestedProjectItem: React.FC<NestedProjectItemProps> = ({
  nestedProject,
  projectIdentifier,
  workspaceIdentifier,
  presentation,
  onClick,
  renderDropdown,
}) => {
  const Component =
    presentation === 'grid'
      ? NestedProjectItemGridView
      : NestedProjectItemListView

  return (
    <ModalRestrictor>
      <Component
        data-testid="nestedProject-item"
        nestedProject={nestedProject}
        projectIdentifier={projectIdentifier}
        workspaceIdentifier={workspaceIdentifier}
        renderDropdown={() => renderDropdown(nestedProject)}
        onClick={onClick}
      />
    </ModalRestrictor>
  )
}

export default NestedProjectItem
