import React from 'react'
import { Skeleton } from './NotificationListItem.styles'

export const NotificationListItemSkeleton = ({
  pageSize,
}: {
  pageSize: number
}) => {
  return (
    <>
      {[...Array(pageSize)].map((_, index) => (
        <Skeleton key={index} />
      ))}
    </>
  )
}
