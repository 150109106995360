import React, { PropsWithChildren } from 'react'

import {
  TreeItem,
  IconWrapper,
  Chevron,
  ChevronButton,
  ChevronEmpty,
} from './TreeNode.styles'
import { useTreeNodeState } from './state'

import { Skeleton } from '@sketch/components'

export interface TreeNodeProps extends PropsWithChildren<{}> {
  depth: number
  icon: React.ReactNode | ((isOpen: boolean) => React.ReactNode)
  hasChildren: boolean
  id: string
  label: React.ReactNode
}

export const TreeNode = (props: TreeNodeProps) => {
  const { id, children, label, hasChildren, depth, icon } = props
  const {
    isOpen,
    isSelected,
    isCurrentDestination,
    onRefLoaded,
    onFocusedChange,
    onOpenChange,
    onSelect,
  } = useTreeNodeState(id)

  const iconElement = typeof icon === 'function' ? icon(isOpen) : icon

  const labelElement = (
    <TreeItem
      depth={depth}
      tabIndex={0}
      ref={ref => ref && onRefLoaded(ref)}
      role="treeitem"
      aria-current={isCurrentDestination}
      aria-selected={isSelected}
      aria-expanded={isOpen}
      aria-level={props.depth}
      onFocus={() => onFocusedChange(true)}
      onBlur={() => onFocusedChange(false)}
      onClick={() => onSelect()}
    >
      {hasChildren ? (
        <ChevronButton
          tabIndex={-1}
          onClick={e => {
            e.stopPropagation()
            onOpenChange(!isOpen)
          }}
        >
          <Chevron />
        </ChevronButton>
      ) : (
        <ChevronEmpty />
      )}
      <IconWrapper>{iconElement}</IconWrapper>
      {label}
    </TreeItem>
  )

  if (hasChildren)
    return (
      <div>
        {labelElement}
        <div style={isOpen ? {} : { display: 'none' }} role="group">
          {children}
        </div>
      </div>
    )

  return labelElement
}

export interface TreeNodeSkeletonProps {
  depth: number
}
export const TreeNodeSkeleton = (props: TreeNodeSkeletonProps) => {
  return (
    <TreeItem
      tabIndex={-1}
      depth={props.depth}
      style={{ pointerEvents: 'none' }}
    >
      <ChevronButton tabIndex={-1} style={{ opacity: 0.2 }}>
        <Chevron />
      </ChevronButton>
      <Skeleton width="16px" style={{ marginRight: '8px' }} height="100%" />
      <Skeleton width="50%" height="100%" />
    </TreeItem>
  )
}
