import { useLayoutEffect, useRef } from 'react'

/**
 * Utility hook which fires a callback when length of the list changes.
 *
 * We are using it to set children of the treeState when the list of projects
 * or workspace changes.
 *
 * NOTES:
 * - if this hook is called with an empty list, it will not fire the callback.
 * - if is called with a non-empty list, it will fire the callback straight away.
 *
 * NOTE: this is not a generic hook, it is specifically designed for the treeState.
 *       it is not recommended to move this hook to a shared location.
 */
export const useOnListChange = <T>(
  items: T[],
  /**
   * @param items - The list of items
   * @param isFirstChange - Whether this is the first time when we are calling the callback.
   *                        Useful for setting the initial state of the tree.
   */
  onListChange: (items: T[], isFirstChange: boolean) => void
) => {
  const wasFirstCalled = useRef(false)

  // we are using useLayoutEffect instead of useEffect to reduce the flickering when the tree is being updated.
  // as otherwise we would render the tree with empty children and then update it.
  // if useLayoutEffect will not be enough
  useLayoutEffect(() => {
    if (items.length === 0) return
    const isFirst = !wasFirstCalled.current
    wasFirstCalled.current = true
    onListChange(items, isFirst)

    // We are OK assume that the array changed if (and only if) the length changed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items.length])
}
