import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "create-project-24", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("path", { d: "M10.69 19H6.883c-1.003 0-1.366-.104-1.733-.3a2.044 2.044 0 0 1-.85-.851c-.197-.367-.301-.73-.301-1.733V7.58c0-.639.104-1.063.3-1.43.197-.367.484-.654.851-.85.367-.197.791-.301 1.43-.301h2.212c.453 0 .887.18 1.207.5.32.32.754.5 1.207.5h5.909c1.003 0 1.366.104 1.733.3.367.197.654.484.85.851.197.367.301.73.301 1.733v3.743a5.983 5.983 0 0 0-1.5-.785V8.884c0-.688-.032-.856-.123-1.026a.546.546 0 0 0-.235-.235c-.156-.083-.31-.117-.865-.122l-6.07-.001c-.85 0-1.666-.338-2.268-.94a.207.207 0 0 0-.146-.06H6.58c-.379 0-.57.042-.723.123a.546.546 0 0 0-.235.235c-.081.153-.123.344-.123.723v8.696c.006.555.04.709.123.865.057.105.13.178.235.235.17.09.338.123 1.026.123H10.5c0 .518.066 1.021.19 1.5Z" }),
    React.createElement("path", { fillOpacity: 0.149, d: "M19 10v2.044a6 6 0 0 0-8.48 5.957L5 18v-8h14Z" }),
    React.createElement("path", { d: "M5 9h14v1.5H5V9Z" }),
    React.createElement("path", { fillRule: "evenodd", d: "M16.5 13a4.5 4.5 0 1 1 0 9 4.5 4.5 0 0 1 0-9Zm0 2a.5.5 0 0 0-.5.5V17h-1.5a.5.5 0 1 0 0 1H16v1.5a.5.5 0 1 0 1 0V18h1.5a.5.5 0 1 0 0-1H17v-1.5a.5.5 0 0 0-.5-.5Z" }));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
