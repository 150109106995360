import React, { useState, MutableRefObject } from 'react'
import { useParams } from 'react-router-dom'

import {
  useModalContext,
  SelectDropdownActionDropdown,
} from '@sketch/components'
import {
  ShareDropPayload,
  DroppableLinkItem,
  RouteParams,
} from '@sketch/modules-common'
import { ConfirmMoveModal } from '../../modals'

import { ProjectLink } from '../../types.old'
import { WorkspaceMinimalFragment } from '@sketch/gql-types'

import { RenameInlineEditor } from '../InlineEditor'
import ProjectDropdown from '../ProjectDropdown'

import { useRemoveSharesFromCollection } from '../../../collections/operations'
import { useToast } from '@sketch/toasts'

import { privacyIcon } from '../ProjectListItem/ProjectListItem.utils'
import { ReactComponent as PinIcon } from '@sketch/icons/pin-16'

export interface ProjectProps extends ProjectLink {
  userCanEdit: boolean
  userCanAdminister: boolean
  workspace: WorkspaceMinimalFragment
  refToLast?: React.MutableRefObject<HTMLElement>
  setEditedItem?: (value: string) => void
  autofocusLastCreated?: MutableRefObject<boolean>
  forceHighlight?: boolean
}

export const ProjectListItem: React.FC<ProjectProps> = props => {
  const {
    link,
    project,
    workspace,
    refToLast,
    setEditedItem,
    forceHighlight = false,
  } = props

  const { showModal } = useModalContext()
  const { showToast } = useToast()
  const [isRenaming, setIsRenaming] = useState(false)
  const { collectionId: originCollectionId } = useParams<
    RouteParams<'WORKSPACE_COLLECTION'>
  >()

  const removeSharesFromCollection = useRemoveSharesFromCollection({
    projectIdentifier: project.identifier,
    onCompleted: () => {
      showToast(`Document removed from this collection`)
    },
  })

  const onDropped = ({ share }: ShareDropPayload) => {
    /**
     * No share nothing to move ¯\_(ツ)_/¯
     */
    if (!share) {
      return
    }

    /**
     * If the project identifier is different from the one dropped, move it please
     */
    if (share.project?.identifier !== project.identifier) {
      showModal(ConfirmMoveModal, {
        workspaceIdentifier: workspace.identifier,
        share,
        newProject: project,
      })

      return
    }

    // if we're moving this share to the same project identifier + we're also coming from a collection
    // then what we actually want is to remove this share from this collection!
    if (originCollectionId) {
      removeSharesFromCollection({
        variables: {
          identifier: originCollectionId,
          shareIdentifiers: [share.identifier],
        },
      })

      return
    }

    /**
     * Given that project identifier is the same and there's no collection set
     * we friendly remind that the document already exists in that project
     */
    showToast('Document is already on this project')
  }

  const workspaceId = workspace.identifier

  const toggleEditor = () => setIsRenaming(!isRenaming)
  const onCompleted = () => {
    setEditedItem?.(project.identifier)
  }

  if (isRenaming) {
    return (
      <RenameInlineEditor
        project={project}
        workspaceId={workspaceId}
        placeholder={project.name || 'Folder'}
        toggleEditor={toggleEditor}
        onCompleted={onCompleted}
      />
    )
  }

  // We should show the pin icon when FF is enabled
  // when the project is pinned and when it's not
  // a private project, as the lock icon should prevail
  // https://www.sketch.com/s/d57ba117-58de-4a49-b217-0720404f0a08/a/oM73ayj
  const showPinIcon =
    project.pinnedByCurrentUserAt &&
    project.privacyIcon !== 'INVITED_WORKSPACE_MEMBERS'

  const icon = showPinIcon
    ? {
        icon: PinIcon,
        activeIcon: PinIcon,
        label: 'Pin icon',
      }
    : {
        icon: privacyIcon(project).component,
        label: privacyIcon(project).label,
        activeIcon: privacyIcon(project, true).component,
      }

  return (
    <DroppableLinkItem
      icon={icon}
      iconLabel={icon.label}
      to={link}
      text={project.name}
      onDropped={onDropped}
      action={
        <SelectDropdownActionDropdown
          text="Rename & More…"
          content={
            <ProjectDropdown
              workspace={workspace}
              project={project}
              onRename={() => setIsRenaming(!isRenaming)}
            />
          }
        />
      }
      ref={refToLast}
      aria-label={`Link to ${project.name}`}
      forceHighlight={forceHighlight}
    />
  )
}
