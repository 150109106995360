import React from 'react'

import { ReactComponent as ChevronRight } from '@sketch/icons/chevron-right-16'

import { Item, Divider, Pill, Check } from './Crumb.styles'

/**
 * Step
 *
 * A single breadcrumb item "1 Crumb >" used in <OrderedBreadcrumbs />
 *
 */
interface CrumbProps {
  isActive: boolean
  showAllInMobile?: boolean
  isLink?: boolean
  isCompleted: boolean
  number?: number
}

export const Crumb: React.FC<CrumbProps> = ({
  children,
  isActive,
  showAllInMobile,
  isLink,
  isCompleted,
  number,
}) => {
  return (
    <Item
      $number={number}
      $isActive={isActive}
      $isCompleted={isCompleted}
      $showAllInMobile={showAllInMobile}
      $isLink={isLink}
    >
      <Pill $green={isCompleted || isActive}>
        {isCompleted ? <Check /> : number}
      </Pill>

      {children}
      <Divider>
        <ChevronRight height="16px" />
      </Divider>
    </Item>
  )
}
