import styled, { css } from 'styled-components'

import {
  CircleButton,
  Spinner as BaseSpinner,
  WorkspaceLogo,
} from '@sketch/components'

import { ReactComponent as CrossIcon } from '@sketch/icons/cross-16'
import { ReactComponent as ImageLogo } from '@sketch/icons/image-64'

export const Spinner = styled(BaseSpinner)``

export const Dropzone = styled.div<{ isDragActive: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-color: ${({ theme, isDragActive }) =>
    isDragActive ? theme.colors.sketch.A : '#d9d9d9'};

  cursor: pointer;
  user-select: none;
  * {
    pointer-events: none;
  }
`

export const RemoveButton = styled(CircleButton).attrs({
  icon: CrossIcon,
  buttonSize: '32px',
  size: '16px',
})`
  position: absolute;
  top: -16px;
  left: -16px;
  padding: 0;
  pointer-events: auto;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  z-index: 1;

  opacity: 0;
  visibility: hidden;
  transform: translate(0, -10px);
  transition: ${({ theme }) => `
    opacity ${theme.transitions.duration[2]} ${theme.transitions.timing.easeInOut}
    transform ${theme.transitions.duration[2]} ${theme.transitions.timing.easeInOut}
    visibility ${theme.transitions.duration[2]} ${theme.transitions.timing.easeInOut}
  `};
`

const imageWrapperDark = css`
  background-color: rgb(0, 0, 0, 0.75);
  backdrop-filter: blur(3px);
  clip-path: inset(0px 0px 0px 0px round 12px);
  color: #ffffff;
`

interface ImageIconWrapperProps {
  $dark?: boolean
}

export const ImageIconWrapper = styled.div<ImageIconWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  color: ${({ theme }) => theme.colors.foreground.secondary.C};
  transition: opacity 100ms ease-in-out;

  ${({ $dark }) =>
    $dark &&
    css`
      ${imageWrapperDark};
    `}
`

export const ImageIcon = styled(ImageLogo)`
  width: 24px;
  height: 24px;
`

export const PreviewContainer = styled.div`
  position: relative;
  pointer-events: none;
  height: ${({ theme }) => theme.avatarSizes.default}px;
  background-color: ${({ theme }) => theme.colors.sketch.A};

  /* This needs to be white because the background is orange */
  color: white;
  border-radius: ${({ theme }) => theme.radii.xxlarge};

  ${Spinner} {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  ${WorkspaceLogo} {
    border-radius: ${({ theme }) => theme.radii.xxlarge};
  }

  & + ${ImageIconWrapper} {
    /* 
      We need to hide the image icon when the logo is set
      https://www.sketch.com/s/43364393-1a74-4c07-a729-bdb69cc30ea9/a/0A3GQ4W
    */
    opacity: 0;
  }
`

export const Container = styled.div`
  position: relative;
  height: 64px;
  width: 64px;
  display: flex;
  align-items: center;
  margin-right: 16px;

  border-radius: ${({ theme }) => theme.radii.xxlarge};
  background-color: ${({ theme }) => theme.colors.background.tertiary.C};

  &:hover ${/* sc-selector */ RemoveButton}:not([disabled]) {
    opacity: 1;
    transform: translate(0, 0);
    visibility: visible;
  }

  &:hover ${ImageIconWrapper} {
    /*
      We need to show the image in dark when hovering the image
      https://www.sketch.com/s/43364393-1a74-4c07-a729-bdb69cc30ea9/a/0A3GQ4W
    */
    opacity: 1;
    ${imageWrapperDark};
  }
`
