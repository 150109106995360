import styled, { css } from 'styled-components'
import { Text, Image, Modal } from '@sketch/components'

export const Header = styled(Modal.Header)(
  ({ theme }) => css`
    border: 1px solid ${theme.colors.border.A};
  `
)

// this override is necessary because two Text elements always result on a 20px padding on the first one
export const Title = styled(Text).attrs({
  fontSize: 'G',
  fontWeight: 'regular',
})`
  padding-bottom: 4px !important; /* stylelint-disable-line declaration-no-important */
`

export const PreviewImage = styled(Image)(
  ({ theme }) => css`
    width: 68px;
    height: 68px;
    background-color: ${({ theme }) => theme.colors.background.tertiary.C};
    border-radius: ${theme.radii.large};
    border: 1px solid ${theme.colors.border.A};

    img {
      height: auto;
      width: auto;
      max-width: 68px;
      max-height: 68px;
    }
  `
)

export const PreviewImageEmpty = styled.div(
  ({ theme }) => css`
    width: 68px;
    height: 68px;
    border-radius: ${theme.radii.large};
    border: 1px solid ${theme.colors.border.A};
    background-color: ${theme.colors.background.tertiary.D};
    position: relative;

    ::after {
      content: ' ';
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: ${theme.colors.background.tertiary.B};
      position: absolute;
      top: 25px;
      right: 10px;
    }

    ::before {
      content: ' ';
      width: 28px;
      height: 28px;
      background-color: ${theme.colors.background.tertiary.B};
      position: absolute;
      top: 12px;
      left: 12px;
    }
  `
)

export const ShareParentName = styled.strong`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.foreground.secondary.B};
`

export const Subtitle = styled(Text).attrs({
  textStyle: 'copy.tertiary.standard.D',
  fontWeight: 'normal',
})``
