import React from 'react'
import { Portal } from 'react-portal'

import { capitalize } from '@sketch/utils'

import {
  PrivatePermissionsAccessSelect,
  hasRequestedAccess,
} from '../../../components/AccessLevelSelect'
import {
  AccessLevelSelectTriggerButton,
  ConfirmationDialog,
  Tooltip,
  BaseListRowPlaceholderAvatar,
  BaseListRowInfo,
  Skeleton,
} from '@sketch/components'

import { Avatar, NameSkeleton, PlaceholderWrapper } from './ShareMember.styles'

import { useDeleteMember } from './useDeleteMember'
import { useUpdateMember } from './useUpdateMember'

import { ShareForSettingsModal } from '../types'
import { ShareMembershipInfoFragment } from '@sketch/gql-types'
import PermissionWrapper from '../PermissionWrapper'
import { useUserProfile } from '@sketch/modules-common'

export type ShareForShareMember = Pick<
  ShareForSettingsModal,
  | 'identifier'
  | 'userCanUpdateCommentsEnabled'
  | 'userCanUpdateSettings'
  | 'type'
  | 'parentAccessLevel'
  | 'isDraft'
  | 'name'
>

interface ShareMemberProps {
  share: ShareForShareMember
  member: ShareMembershipInfoFragment
  workspaceIdentifier: string
  isMyMembership?: boolean
}

export const ShareMember = ({
  member,
  share,
  isMyMembership,
  workspaceIdentifier,
}: ShareMemberProps) => {
  const {
    deleteMember,
    deleteMemberConfirmDialogOpen,
    getDeleteMemberConfirmDialogProps,
  } = useDeleteMember({
    share,
    member,
    isMyMembership,
    workspaceIdentifier,
  })

  const {
    updateMemberConfirmDialogOpen,
    getUpdateMemberConfirmDialogProps,
    getUpdateMemberDropdownProps,
    updateMemberLevel,
  } = useUpdateMember({ share, member })

  const isMemberTheCurrentUser = useIsMemberTheCurrentUser(member)
  // For Draft documents only, the owner/creator can't edit/remove themselves
  // from the member's list.
  // Since for draft documents, the membership settings tab is not accessible
  // by invited members, the current user is always the owner and `isMemberTheCurrentUser`
  // means that the member is the owner of the draft document".
  const canEditLevelAccess = !share.isDraft || !isMemberTheCurrentUser

  const user = member.workspaceMembership?.user
  const invitationRequested =
    member.inviteStatus === 'REQUESTED' || member.inviteStatus === 'INVITED'

  const {
    access,
    guestCommentsEnabled,
    guestInspectEnabled,
    setCommentsEnabled,
    setInspectEnabled,
    canUpdateCommentsEnabled,
  } = getUpdateMemberDropdownProps()

  const memberIsGuest = member.workspaceMembership?.role === 'GUEST'

  const memberCommentsEnabled =
    memberIsGuest && access.level !== 'EDIT' ? guestCommentsEnabled : true
  const memberInspectEnabled =
    memberIsGuest && access.level !== 'EDIT' ? guestInspectEnabled : true
  const disableEditPermissions = !memberIsGuest || !canUpdateCommentsEnabled

  return (
    <>
      <PermissionWrapper role="membership-row">
        {user?.avatar?.small ? (
          <Avatar
            src={user?.avatar?.small ?? undefined}
            name={user?.name || member.email!}
            size="32px"
            flavour="image"
            invitationRequested={invitationRequested}
          />
        ) : (
          <Avatar
            name={user?.name || member.email!}
            size="32px"
            flavour="color"
            color="default"
            invitationRequested={invitationRequested}
          />
        )}
        <BaseListRowInfo
          identifier={user?.identifier}
          title={user?.name || user?.email || member.email || ''}
          subtitle={user?.email || member.email || ''}
          inviteStatus={member?.inviteStatus || undefined}
          role={member?.workspaceMembership?.role}
          isCurrentUser={isMemberTheCurrentUser}
        />
        {!canEditLevelAccess ? (
          <Tooltip
            placement="top-end"
            spacing="0"
            content="Documents in My Drafts belong to you, so you cannot remove yourself"
            disabled={!share.isDraft}
          >
            <AccessLevelSelectTriggerButton disabled label="Edit" />
          </Tooltip>
        ) : (
          <PrivatePermissionsAccessSelect
            level={
              (access.level as string) === 'INSPECT' ? 'VIEW' : access.level
            }
            setLevel={level => {
              access.setLevel(level)

              if (level === 'EDIT') {
                setCommentsEnabled(true)
                setInspectEnabled(true)
              }
            }}
            setCommentsEnabled={event =>
              setCommentsEnabled(event.target.checked ?? false)
            }
            commentsEnabled={memberCommentsEnabled}
            setInspectEnabled={event =>
              setInspectEnabled(event.target.checked ?? false)
            }
            inspectEnabled={memberInspectEnabled}
            disabled={disableEditPermissions}
            showDisabledText={!memberIsGuest}
            removeButtonText={
              hasRequestedAccess(member?.inviteStatus)
                ? 'Deny Access to Document'
                : 'Remove Selected Access'
            }
            deleteMember={() => deleteMember()}
          />
        )}
      </PermissionWrapper>
      {updateMemberConfirmDialogOpen && (
        <Portal>
          <ConfirmationDialog
            {...getUpdateMemberConfirmDialogProps()}
            title={`Change Your Access to ${capitalize(updateMemberLevel)}?`}
            confirmButton={{ text: 'Change Access', variant: 'negative' }}
          >
            You’ll no longer be able to edit “{share.name}”, but you can still{' '}
            {updateMemberLevel.toLowerCase()} it. To get edit access again, ask
            an Admin or somebody with edit access to add you.
          </ConfirmationDialog>
        </Portal>
      )}
      {deleteMemberConfirmDialogOpen && (
        <Portal>
          <ConfirmationDialog
            {...getDeleteMemberConfirmDialogProps()}
            title="Remove Edit Access?"
            confirmButton={{ text: 'Remove Access', variant: 'negative' }}
          >
            You’ll no longer be able to edit “{share.name}”, but you can still{' '}
            view or inspect it. To get edit access again, ask an Admin or
            somebody with edit access to add you.
          </ConfirmationDialog>
        </Portal>
      )}
    </>
  )
}

/** Is the member the same as the current user => "You"? */
function useIsMemberTheCurrentUser(member: ShareMembershipInfoFragment) {
  const { data } = useUserProfile(true)

  return data?.me.identifier === member.workspaceMembership?.user?.identifier
}

export const ShareMemberPlaceholder = () => (
  <PermissionWrapper>
    <BaseListRowPlaceholderAvatar size="32px" name="" flavour="color" />
    <PlaceholderWrapper>
      <NameSkeleton height="19px" width="200px" />
      <Skeleton height="15px" width="100px" />
    </PlaceholderWrapper>
    <span className="sr-only">placeholder item</span>
  </PermissionWrapper>
)
