import React from 'react'

import { SKETCH_WEBSITE } from '@sketch/env-config'

import { Button, Modal, ModalInjectedProps } from '@sketch/components'
import { updateSubscriptionEndInCache } from '../../utils'

import { dateFormat } from '@sketch/utils'

import { useCancelSubscriptionMutation } from '@sketch/gql-types'

interface CancelWorkspaceSubscriptionModalProps extends ModalInjectedProps {
  customerId: string
  workspaceId: string
  nextBillingCycleDate: string
  isPartner?: boolean
}

export const CancelWorkspaceSubscriptionModal: React.FC<CancelWorkspaceSubscriptionModalProps> = props => {
  const {
    hideModal,
    customerId,
    nextBillingCycleDate,
    workspaceId,
    isPartner,
  } = props

  const [cancelSubscription, { loading }] = useCancelSubscriptionMutation({
    variables: { customerId },
    onError: 'show-toast',
    onCompleted: () => {
      hideModal()
    },
    update: (cache, { data }) => {
      if (!data?.cancelSubscription?.customer) {
        return
      }

      const {
        subscriptionEnd,
      } = data.cancelSubscription.customer.subscriptionInfo

      updateSubscriptionEndInCache(cache, {
        workspaceId,
        subscriptionEnd,
        isPartner,
      })
    },
  })

  const partnerCopy1 =
    "After you cancel you'll still have access to Sketch until the end of the billing period on"
  const partnerCopy2 =
    'You can easily reactivate your subscription during your current billing period.'

  const usersCopy1 =
    'You and other Workspace Members can keep using Sketch until'
  const usersCopy2 =
    "After that you'll lose access to your documents, be unable to manage Members — and Editors won't be able to save documents to this Workspace."
  return (
    <Modal title="Cancel Subscription?" onCancel={hideModal}>
      <Modal.Body>
        <p>
          {isPartner ? partnerCopy1 : usersCopy1}{' '}
          <strong>{dateFormat(new Date(nextBillingCycleDate))}</strong>.
        </p>
        <p>
          {isPartner ? partnerCopy2 : usersCopy2}{' '}
          <a
            href={`${SKETCH_WEBSITE}/support/teams-and-cloud/cancel-subscription`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
          </a>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          disabled={loading}
          onClick={() => {
            hideModal()
          }}
        >
          Keep Plan
        </Button>
        <Button
          variant="negative"
          loading={loading}
          onClick={() => {
            cancelSubscription()
          }}
        >
          Cancel Subscription
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
