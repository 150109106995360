import React from 'react'
import { routes } from '@sketch/modules-common'

import {
  useGetProjectsQuery,
  useGetProjectsLazyQuery,
  GetProjectsQueryVariables,
  GetProjectsQuery,
  ProjectInSidebarAndHeaderFragment,
} from '@sketch/gql-types'

import { ProjectLink } from '../types'

export interface UseProjectsSwitchProps
  extends OmitSafe<GetProjectsQueryVariables, 'workspaceId'> {
  workspaceId: string
}

export const useGetProjects = ({
  workspaceId,
  after,
}: UseProjectsSwitchProps) => {
  const { data: workspaceData, ...rest } = useGetProjectsQuery({
    variables: { workspaceId, after },
  })

  const { rootProjectsSorted, projectsById } = React.useMemo(() => {
    const projects = workspaceData?.workspace.projects.entries ?? []

    return sortProjects(projects, workspaceId)
  }, [workspaceData?.workspace.projects.entries, workspaceId])

  return {
    ...rest,
    ...normalizeProjects(workspaceData),
    rootProjectsSorted,
    projectsById,
  }
}

export const useGetProjectsLazy = () => {
  const [getProjects, workspaceResult] = useGetProjectsLazyQuery()
  const { data: workspaceData, ...rest } = workspaceResult

  return { getProjects, ...rest, ...normalizeProjects(workspaceData) }
}

function normalizeProjects(workspaceData: GetProjectsQuery | undefined) {
  if (!workspaceData) return {}

  const projects = workspaceData.workspace.projects
  const draftsProject = workspaceData.workspace.draftsProject

  return {
    projects,
    draftsProject,
    allProjects: [...draftsProject.entries, ...projects.entries],
  }
}

// Takes the projects data and normalizes it to a structure that can be used by
// different components, returning a list of sorted projects and an object of projects by id
function sortProjects(
  entries: ProjectInSidebarAndHeaderFragment[],
  workspaceId: string
) {
  // Create needed structure from project data, and fill the links
  const projectsLink: ProjectLink[] = entries.map(project => ({
    project,
    nestedProjects: [],
    link: routes.WORKSPACE_PROJECT.create({
      projectId: project.identifier,
      workspaceId,
    }),
  }))

  // Create a plain projects list by id, it's easier to manipulate
  const projectsById = projectsLink.reduce(
    (acc: { [key: string]: ProjectLink }, projectLink) => {
      acc[projectLink.project.identifier] = projectLink
      return acc
    },
    {}
  )

  // Fill the nested projects
  projectsLink.forEach(projectLink => {
    const parentId = projectLink.project.parentProject?.identifier

    if (parentId && projectsById[parentId]) {
      projectsById[parentId].nestedProjects.push(projectLink)
    }
  })

  // Return only root projects and sorted
  const rootProjectsSorted = Object.values(projectsById)
    .filter(project => !project.project.parentProject)
    .sort((a, b) => {
      // If a is pinned and b is not, sort a to the start
      if (a.project.pinnedByCurrentUserAt && !b.project.pinnedByCurrentUserAt) {
        return -1
      }

      // If b is pinned and a is not, sort b to the start
      if (b.project.pinnedByCurrentUserAt && !a.project.pinnedByCurrentUserAt) {
        return 1
      }

      // If both a and b are pinned or not pinned, sort by name
      return a.project.name.localeCompare(b.project.name, undefined, {
        numeric: true,
      })
    })

  return { rootProjectsSorted, projectsById }
}

export function useGetRootProject(workspaceId: string, projectId: string) {
  const { projectsById } = useGetProjects({ workspaceId })

  let currentProject = projectsById[projectId]

  while (currentProject?.project?.parentProject) {
    currentProject =
      projectsById[currentProject.project.parentProject.identifier]
  }

  return currentProject
}
