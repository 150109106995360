import React, { FC, useContext, useState, useRef } from 'react'

import { useHistory, useParams } from 'react-router'
import { useLocalStorage } from 'react-use'
import { dataIdFromObject } from '@sketch/graphql-cache'

import { localStorageKeys } from '@sketch/constants'
import { routes, RouteParams } from '@sketch/modules-common'

import { ReactComponent as PlusIcon } from '@sketch/icons/plus-16'

import {
  Tooltip,
  handleFetchMore,
  ModalContext,
  SelectDropdownButtonItem,
} from '@sketch/components'
import * as Collapsible from '@radix-ui/react-collapsible'
import { ToastContext } from '@sketch/toasts'

import ProjectsList from '../ProjectsList'
import AddNewProjectModal from '../../modals/AddNewProjectModal'
import { ProjectListItem } from '../ProjectListItem'

import { useGetProjects } from '../../operations'

import {
  MenuWrapper,
  ProjectsHeader,
  WarningIcon,
  Error,
  ErrorText,
  RefetchButton,
  StyledSkeletonSidebarList,
  StyledCollapsibleContent,
  StyledTrigger,
} from './ProjectsMenu.styles'
import { CollapsibleIcon } from '../../../workspace/components/Sidebar/Sidebar.styles'

import {
  useGetProjectsQuery,
  GetProjectsQueryResult,
  WorkspaceMinimalFragment,
  CreateProjectMutation,
  GetProjectsQuery,
} from '@sketch/gql-types'

export interface ProjectsMenuProps {
  canUserAdministerShares: boolean
  showWorkspaceSettings?: boolean
  workspace: WorkspaceMinimalFragment
  onLoadCompleted: (data: GetProjectsQuery) => void
}

interface ProjectsListContainerProps {
  workspace: WorkspaceMinimalFragment
  projectsResponse: GetProjectsQueryResult
  isUserEditor: boolean
  isUserAdmin: boolean
  isProjectsListDisabled?: boolean
  isCollapsed?: boolean
  showCreateProjectEditor: boolean
  onBlur: () => void
  onAdd: () => void
}

/**
 * CONSTANTS
 */
const entriesPath = ['workspace', 'projects', 'entries']

/**
 * HELPER COMPONENTS
 */
const ProjectsListContainer: FC<ProjectsListContainerProps> = props => {
  // This is for setting autofocus just after creating a new folder
  const autofocusLastCreated = useRef(false)

  const {
    workspace,
    isUserEditor,
    isUserAdmin,
    onAdd,
    isProjectsListDisabled,
    isCollapsed,
  } = props

  const workspaceId = workspace.identifier

  const { projectId } = useParams<RouteParams<'WORKSPACE_PROJECT'>>()

  const {
    projects,
    refetch,
    fetchMore,
    loading,
    error,
    rootProjectsSorted,
    projectsById,
  } = useGetProjects({
    workspaceId,
  })

  const handleRefetch = () => {
    refetch()
  }

  // We will hide this when it's collapsed with the new Sidebar
  if (loading && !isCollapsed) {
    return <StyledSkeletonSidebarList data-testid="spinner" numberOfItems={4} />
  }

  if (error) {
    return (
      <Error>
        <WarningIcon width="24px" height="24px" />
        <ErrorText>Unable to load projects</ErrorText>
        <RefetchButton type="button" onClick={handleRefetch}>
          Try Again
        </RefetchButton>
      </Error>
    )
  }

  if (!projects) {
    return null
  }

  const {
    meta: { after, totalCount },
  } = projects

  if (totalCount === 0) {
    if (isUserEditor || isUserAdmin) {
      if (isCollapsed) {
        return null
      }

      return (
        <SelectDropdownButtonItem
          text="Create Project..."
          icon={PlusIcon}
          onClick={onAdd}
        />
      )
    }

    return (
      <Tooltip
        placement="bottom"
        content="Only Editors and Admins can create new projects"
      >
        <SelectDropdownButtonItem
          text="Create Project..."
          icon={PlusIcon}
          onClick={onAdd}
          disabled
        />
      </Tooltip>
    )
  }

  const handleLoadMore = handleFetchMore(fetchMore, entriesPath, {
    dataIdFromObject,
    after,
  })

  const selectedProject = projectsById[projectId]

  if (isCollapsed && selectedProject) {
    // If it's collapsed we only show the active item, not the list
    const { project, link } = selectedProject

    return (
      <ProjectListItem
        key={project.identifier}
        project={project}
        link={link}
        userCanEdit={isUserEditor}
        userCanAdminister={isUserAdmin}
        workspace={workspace}
        forceHighlight={true}
        nestedProjects={selectedProject.nestedProjects}
      />
    )
  }

  return (
    <StyledCollapsibleContent data-testid="projects-content">
      <ProjectsList
        canLoadMore={after !== null}
        onLoadMore={handleLoadMore}
        items={rootProjectsSorted}
        userCanEdit={isUserEditor}
        userCanAdminister={isUserAdmin}
        workspace={workspace}
        autofocusLastCreated={autofocusLastCreated}
        isDisabled={isProjectsListDisabled}
      />
    </StyledCollapsibleContent>
  )
}

/**
 * MAIN COMPONENTS
 */
export const ProjectsMenu: FC<ProjectsMenuProps> = ({
  canUserAdministerShares,
  workspace,
  onLoadCompleted,
}) => {
  const { showModal, hideModal } = useContext(ModalContext)
  const history = useHistory()
  const workspaceId = workspace.identifier
  const isUserEditor = workspace?.userCanEdit ?? false
  const isProjectsListDisabled = workspace.status === 'INACTIVE'

  const localStorageKey = localStorageKeys.sidebarSectionProjectsCollapsed
  const [
    isCollapsedProjectsSection,
    setIsCollapsedProjectsSection,
  ] = useLocalStorage(localStorageKey)

  const [showCreateProjectEditor, setShowCreateProject] = useState(false)

  const projectsResponse = useGetProjectsQuery({
    variables: { workspaceId },
    shouldInvalidatePrevious: (prev, curr) =>
      prev?.workspaceId !== curr?.workspaceId,
    skip: !workspace,
    onCompleted: onLoadCompleted,
  })

  const { showToast } = useContext(ToastContext)

  const handleOnComplete = (data: CreateProjectMutation) => {
    const { name, identifier: projectId } = data.createWorkspaceProject.project

    showToast(`"${name}" Project created`)

    history.push(
      routes.WORKSPACE_PROJECT.create({
        projectId,
        workspaceId,
      })
    )

    hideModal()
  }

  const handleOnAdd = () => {
    showModal(AddNewProjectModal, {
      workspace,
      onCompleted: handleOnComplete,
    })
    return
  }

  const handleOnBlur = () => {
    setShowCreateProject(false)
  }

  const handleCollapsibleClick = (e: React.SyntheticEvent) => {
    e.preventDefault()
    setIsCollapsedProjectsSection(!isCollapsedProjectsSection)
  }

  return (
    <MenuWrapper data-testid="projects">
      <Collapsible.Root
        className="CollapsibleRoot"
        open={!isCollapsedProjectsSection}
        onOpenChange={
          setIsCollapsedProjectsSection as (isOpen: boolean) => void
        }
      >
        <ProjectsHeader
          onAdd={handleOnAdd}
          isProjectsListDisabled={isProjectsListDisabled}
          userCanAddProjects={isUserEditor || canUserAdministerShares}
          collapseButton={
            <StyledTrigger data-testid="collapse-projects-button" asChild>
              <CollapsibleIcon
                isOpen={!isCollapsedProjectsSection}
                onClick={handleCollapsibleClick}
              />
            </StyledTrigger>
          }
        />

        {/* We need to include the Collapsible.Content
            one level inside as we need to handle the switch between
            the active project and the list depending if is collapsed or not */}
        <ProjectsListContainer
          workspace={workspace}
          isUserEditor={isUserEditor}
          isUserAdmin={canUserAdministerShares}
          isCollapsed={Boolean(isCollapsedProjectsSection)}
          onBlur={handleOnBlur}
          showCreateProjectEditor={showCreateProjectEditor}
          onAdd={handleOnAdd}
          projectsResponse={projectsResponse}
          isProjectsListDisabled={isProjectsListDisabled}
        />
      </Collapsible.Root>
    </MenuWrapper>
  )
}
