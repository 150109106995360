import { useEffect, useRef, useState } from 'react'

export const COOKIE_CONSENT_ID = 'cookie-consent'
export const COOKIE_PORTAL_ID = 'cookie-portal'

const CONFIG = { childList: true, subtree: true }
/**
 * Keep track of the Cookie Consent element in the DOM
 * This will allow us to show / hide features depending on its presence
 */
export const useCookieConsentExists = () => {
  const [cookieExists, setCookieExists] = useState(false)
  const targetNode = useRef(document.getElementById(COOKIE_PORTAL_ID))

  useEffect(() => {
    const observer = new MutationObserver(() => {
      setCookieExists(!!document.getElementById(COOKIE_CONSENT_ID))
    })

    if (targetNode.current) {
      observer.observe(targetNode.current, CONFIG)
    }

    setCookieExists(!!document.getElementById(COOKIE_CONSENT_ID))

    return () => observer.disconnect()
  }, [])

  return cookieExists
}
