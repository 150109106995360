import React from 'react'

import PreventiveVersionLink from '../PreventiveVersionLink'
import VersionItemOptions from '../VersionItemOptions'
import StarVersion, { Star } from '../StarVersion'

import { ShareWithoutVersion } from '../../ShareVersionContext/ShareVersionContext'

import { VersionItem } from './ShareVersions.styles'

import { useDuplicateShareToDrafts } from '../../../shares/hooks/useDuplicateShareToDrafts'

import {
  VersionDeletedFragment,
  VersionListItemFragment,
  VersionUnavailableFragment,
} from '@sketch/gql-types'
import { useDuplicateShareTo } from '../../../shares/hooks/useDuplicateShareTo'

type Version =
  | VersionDeletedFragment
  | VersionUnavailableFragment
  | VersionListItemFragment

interface ShareVersionsItemProps {
  version: Version
  share: Pick<
    ShareWithoutVersion,
    | 'identifier'
    | 'name'
    | 'type'
    | 'userCanEdit'
    | 'userCanInspect'
    | 'publicAccessLevel'
    | 'pinnedByCurrentUserAt'
    | 'userCanUpdateSettings'
    | 'workspace'
    | 'project'
    | 'collection'
  >
  position?: number
  isLatestVersion: boolean
  isLatestStared: boolean
  isSingleVersion: boolean
  isVersionSelected: boolean
  hideDescription?: boolean
}

export const ShareVersionsItem = (props: ShareVersionsItemProps) => {
  const {
    version,
    position,
    share,
    isLatestVersion,
    isLatestStared,
    isSingleVersion,
    isVersionSelected,
    hideDescription,
  } = props

  const [duplicateShareToMyDrafts] = useDuplicateShareToDrafts({
    share,
    versionIdentifier: version.identifier,
    workspaceIdentifier: share.workspace.identifier,
  })

  const [duplicateShareTo] = useDuplicateShareTo({
    share,
    versionIdentifier: version.identifier,
    workspaceIdentifier: share.workspace.identifier,
  })

  const authorName = version.creator?.name ?? null

  if (
    version.__typename === 'VersionDeleted' ||
    version.__typename === 'VersionUnavailable'
  ) {
    return (
      <VersionItem
        order={position}
        authorName={authorName}
        creationDate={version.createdAt}
        deleted={version.__typename === 'VersionDeleted'}
      />
    )
  }

  const isStarred = version.kind === 'PUBLISHED'
  const isAuto = version.kind === 'AUTO'
  const renderStatus = version.document?.renderStatus || undefined

  let star: React.ReactNode | null = null
  if (renderStatus === 'FINISHED' && share.userCanEdit && !isAuto) {
    star = (
      <StarVersion
        isStarred={isStarred}
        isLibrary={share.type === 'LIBRARY'}
        isNewLatest={isLatestStared}
        versionIdentifier={version.identifier}
        versionDescription={version.description || undefined}
        shareIdentifier={share.identifier}
      />
    )
  } else if (isStarred) {
    star = <Star isStarred />
  }

  const description = hideDescription ? undefined : version.description

  return (
    <PreventiveVersionLink
      key={version.identifier}
      versionShortId={version.shortId}
      isLatestVersion={isLatestVersion}
      isActive={match => !!match || isVersionSelected}
    >
      <VersionItem
        order={position}
        authorName={authorName}
        creationDate={version.createdAt}
        description={description || undefined}
        renderStatus={renderStatus}
        isAuto={isAuto}
        star={star ? () => star : undefined}
        isStarred={isStarred}
        optionsMenu={{
          component: VersionItemOptions,
          props: {
            share,
            version,
            isDocumentLibrary: share.type === 'LIBRARY',
            isLatestStaredVersion: isLatestStared,
            isSingleVersion,
            isAuto,
            userCanEdit: share.userCanEdit,
            userCanInspect: share.userCanInspect,
            isVersionSelected,
            onDuplicateShareToDraftsClick: duplicateShareToMyDrafts,
            onDuplicateShareToClick: duplicateShareTo,
          },
        }}
      />
    </PreventiveVersionLink>
  )
}
