import React from 'react'
import { ShareWithoutVersion } from '../../../versioning'
import InvitePeople from '../../modals/ShareSettingsModal/InvitePeople'
import ShareMemberships, {
  ShareForMembershipList,
} from '../../modals/ShareSettingsModal/ShareMemberships'

import { getInviteLimitMessage, useMemberLimit } from '../../../workspace/hooks'
import { MemberLimitMessage } from '../../../workspace/components/MemberLimitMessage'

export const GuestsTitle = ({ guestsCount }: { guestsCount: number }) => (
  <>Guests {guestsCount > 0 && `(${guestsCount})`}</>
)

const LIMIT_INVITE_SAFEGUARD = { helpText: undefined, tooltip: undefined }

export const GuestsPanel = ({ share }: { share: ShareWithoutVersion }) => {
  const limit = useMemberLimit(share.workspace.identifier)

  const { helpText } = limit
    ? getInviteLimitMessage(limit)
    : LIMIT_INVITE_SAFEGUARD

  return (
    <>
      <InvitePeople
        hideLabel
        data-testid="sharing-guests-invite"
        share={share}
        filter="Guests"
        removeSelf
        disabled={limit?.hasReachedLimit}
      />

      {limit && <MemberLimitMessage>{helpText}</MemberLimitMessage>}

      <ShareMemberships share={share as ShareForMembershipList} guestsOnly />
    </>
  )
}
