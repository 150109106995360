import React, { useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'

import {
  Form,
  Button,
  Input,
  Modal,
  ModalInjectedProps,
  Tooltip,
  Flex,
  Text,
  Link,
} from '@sketch/components'

import { ProjectFragment } from '@sketch/gql-types'

import {
  StyledTextArea,
  StyledQuestionMarkIcon,
  ModalHeader,
} from './NestedProjectModalContent.styles'

const MAX_LENGTH = 255

export const validationSchema = Yup.object().shape({
  name: Yup.string().required(
    `Give your project a name. Don't worry, you can change it later.`
  ),
  description: Yup.string().max(
    MAX_LENGTH,
    'Description must be 255 characters or less'
  ),
})

export interface FormProps {
  name: string
  description: string
}

interface CreateNestedProjectModalProps extends ModalInjectedProps {
  project: ProjectFragment

  onSubmit: (values: FormProps) => void
  isLoading?: boolean
}

export const NestedProjectModalContent = (
  props: CreateNestedProjectModalProps
) => {
  const { isLoading, hideModal, onSubmit, project } = props

  const [tooltipVisible, setTooltipVisible] = useState(false)

  const initialValues: FormProps = {
    name: '',
    description: '',
  }

  return (
    <Modal onCancel={hideModal}>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={onSubmit}
      >
        {formikbag => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
          } = formikbag

          const isDisabled = !values.name.trim().length

          return (
            <Form>
              <Modal.Body>
                <ModalHeader>
                  Create a Project inside &ldquo;{project.name}&rdquo;
                </ModalHeader>
                <Flex alignItems="center" pb={6}>
                  <Text textStyle="copy.tertiary.standard.E" pr={1}>
                    Use projects to organize and share Sketch documents.
                    <Tooltip
                      content="Learn more about using projects"
                      placement="top"
                      visible={tooltipVisible}
                      style={{
                        display: 'inline',
                      }}
                    >
                      <Link
                        external
                        href="https://www.sketch.com/docs/workspaces/organizing-your-workspace/"
                        tabIndex={-1}
                      >
                        <StyledQuestionMarkIcon
                          // using the controlled tooltip because there's a bug with tooltips and modals
                          // when you open a modal with an uncontrolled tooltip, it auto shows the tooltip
                          onMouseEnter={() => setTooltipVisible(true)}
                          onMouseLeave={() => setTooltipVisible(false)}
                        />
                      </Link>
                    </Tooltip>
                  </Text>
                </Flex>

                <Form.Field
                  name="name"
                  label="Name"
                  errorText={touched.name ? errors.name : undefined}
                >
                  <Input
                    type="text"
                    name="name"
                    placeholder="Give your project a name…"
                    value={values.name}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </Form.Field>
                <Form.Field
                  name="description"
                  label="Description (Optional)"
                  errorText={
                    touched.description ? errors.description : undefined
                  }
                >
                  <StyledTextArea
                    name="description"
                    placeholder="This project is for…"
                    maxLength={MAX_LENGTH}
                    value={values.description}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                  <Text textStyle="copy.quaternary.standard.C" pt={2}>
                    {MAX_LENGTH - values.description.length} characters left
                  </Text>
                </Form.Field>
              </Modal.Body>
              <Modal.Footer>
                <Button disabled={isSubmitting} onClick={hideModal}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  loading={isLoading}
                  disabled={isDisabled}
                >
                  Create Project
                </Button>
              </Modal.Footer>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}

export default NestedProjectModalContent
