import React from 'react'
import {
  StyledLogo,
  WorkspaceLogoContainer,
  WorkspaceName,
} from './WorkspaceSubscriveBadge.styles'

interface WorkspaceSubscribeBadgeProps {
  name: string
  avatarSrc?: string
}

export const WorkspaceSubscribeBadge = ({
  name,
  avatarSrc,
}: WorkspaceSubscribeBadgeProps) => (
  <WorkspaceLogoContainer>
    <StyledLogo workspaceName={name} src={avatarSrc} size="24px" />
    <WorkspaceName>{name}</WorkspaceName>
  </WorkspaceLogoContainer>
)
