import { ToastLink } from '@sketch/components'
import {
  ProjectFragment,
  ShareInfoFragment,
  WorkspaceMinimalFragment,
} from '@sketch/gql-types'
import { routes } from '@sketch/modules-common'
import { useToast } from '@sketch/toasts'
import { ErrorHandler } from '@sketch/tracing'
import React, { useCallback } from 'react'
import { useShareTransferToWorkspace } from '../../../shares/operations/useShareTransferToWorkspace'
import {
  useMoveShareToProject,
  useRemoveShareFromProject,
} from '../../operations'
import { TreeNodeTypes, useTreeState } from '../ProjectPickerContent'
import { TreeState } from '../ProjectPickerContent/state.base'
import { ConfirmDocumentTransferModal } from './ConfirmDocumentTransferModal'
import { useConfirmationModal } from './useConfirmationModal'

export function getWorkspaceMoveCompletedToast(
  project: Pick<ProjectFragment, 'identifier' | 'name'> | null,
  workspace: Pick<WorkspaceMinimalFragment, 'identifier' | 'name'>
) {
  if (project) {
    return (
      <span>
        Document moved to &ldquo;
        <ToastLink
          to={routes.WORKSPACE_PROJECT.create({
            projectId: project.identifier,
            workspaceId: workspace.identifier,
          })}
        >
          {project.name}
        </ToastLink>
        &rdquo; in &ldquo;
        <ToastLink
          to={routes.WORKSPACE_SHARES.create({
            workspaceId: workspace.identifier,
          })}
        >
          {workspace.name}
        </ToastLink>
        &rdquo; Workspace
      </span>
    )
  }

  return (
    <span>
      Document moved to &ldquo;
      <ToastLink
        to={routes.WORKSPACE_SHARES.create({
          workspaceId: workspace.identifier,
        })}
      >
        {workspace.name}
      </ToastLink>
      &rdquo; Workspace
    </span>
  )
}

const findTargetWorkspace = (
  targetId: string,
  treeState: TreeState<TreeNodeTypes>
) => {
  const path = treeState.tree.getPathToNode(targetId)

  for (const pathId of path) {
    const node = treeState.tree.getNode(pathId)
    if (node?.payload?.__typename === 'Workspace') {
      return node.payload as WorkspaceMinimalFragment
    }
  }
}

export interface MoveDocumentProps {
  data: TreeNodeTypes
}

export const useMoveDocument = (
  share: ShareInfoFragment,
  hideModal: () => void
) => {
  const { showToast } = useToast()
  const treeState = useTreeState()
  const currentIds = treeState.currentIds
  const [askForConfirmation, ConfirmationModal] = useConfirmationModal()

  const [
    removeShareFromProject,
    { loading: isLoadingRemoveShare },
  ] = useRemoveShareFromProject({
    // { identifier: '', name: '' } acts as a placeholder to avoid accessing properties of `undefined` object
    // practically, these values should never be used as this mutation should never be called
    // if the share does not belong to a project
    project: share.project || { identifier: '', name: '' },
    share,
    // TODO: remove search argument once we remove collections support
    search: {},
    onCompleted: () => hideModal(),
  })

  const [
    transferShare,
    { loading: isLoadingTransfer },
  ] = useShareTransferToWorkspace({
    onCompleted: data => {
      const { share: updatedShare } = data.transferShare

      if (!updatedShare) return

      // share.workspace
      showToast(
        getWorkspaceMoveCompletedToast(
          updatedShare.project,
          updatedShare.workspace
        )
      )
      hideModal()
    },
    onError: message => showToast(message, 'negative'),
  })

  const [
    moveShareWithinWorkspace,
    { loading: isLoadingMoveDocument },
  ] = useMoveShareToProject({
    onCompleted: project => {
      showToast(
        <>
          Document moved to &ldquo;
          <ToastLink
            to={routes.WORKSPACE_PROJECT.create({
              projectId: project.identifier,
              workspaceId: currentIds.workspaceId,
            })}
          >
            {project.name}
          </ToastLink>
          &rdquo;
        </>
      )
      hideModal()
    },
    onError: errorMessage => {
      showToast(errorMessage, 'negative')
    },
  })

  const moveDocument = useCallback(
    (target: TreeNodeTypes) => {
      const targetWorkspace = findTargetWorkspace(target.identifier, treeState)

      if (!targetWorkspace) {
        ErrorHandler.shouldNeverHappen('targetWorkspace is not defined')
        return
      }

      const isWithinTheSameWorkspace =
        targetWorkspace.identifier === share.workspace.identifier

      if (!isWithinTheSameWorkspace) {
        const targetProject = target.__typename === 'Project' ? target : null

        askForConfirmation(
          ConfirmDocumentTransferModal,
          { targetWorkspace, share, project: targetProject || undefined },
          ({ membershipsOperation }) => {
            transferShare({
              membershipsOperation,
              shareIdentifier: share.identifier,
              workspaceIdentifier: targetWorkspace.identifier,
              projectIdentifier: targetProject?.identifier || null,
            })
          }
        )
        return
      }

      if (target.__typename === 'Project') {
        moveShareWithinWorkspace({
          shareId: share.identifier,
          projectId: target.identifier,
        })
        return
      }

      if (target.__typename === 'Workspace') {
        const projectId = share.project?.identifier
        if (!projectId) {
          ErrorHandler.shouldNeverHappen('projectId is not defined')
          return
        }
        removeShareFromProject({ shareId: share.identifier, projectId })
        return
      }
    },
    [
      askForConfirmation,
      moveShareWithinWorkspace,
      removeShareFromProject,
      share,
      transferShare,
      treeState,
    ]
  )

  const loading =
    isLoadingRemoveShare || isLoadingMoveDocument || isLoadingTransfer

  return [moveDocument, { loading, ConfirmationModal }] as const
}
