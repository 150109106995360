import React, { useState } from 'react'
import {
  PopoverChildrenProps,
  Popover,
  CopyToClipboard,
} from '@sketch/components'
import ColorDropdown from '../../../../../../../Inspector/Sidebar/components/ColorDropdown'
import ConnectedColorDot from './ConnectedColorDot'
import {
  Stops,
  StopContainer,
  StopPercentage,
  CopyAll,
  PopoverButtonContainer,
} from './GradientPopover.styles'
import rgbTo, {
  ColorFormat,
} from '../../../../../../../Inspector/Sidebar/components/Color/utils'
import { Gradient } from '../../../../../../../../inspector'
import { ColorValue } from './ColorValue'

interface StopProps {
  stopPosition: number
  red: number
  green: number
  blue: number
  alpha?: number
  colorFormat: ColorFormat
  colorVariableName?: string
  onColorFormatChange: (f: ColorFormat) => void
  connected: boolean
}

const Stop: React.FC<StopProps> = ({
  stopPosition,
  red,
  green,
  blue,
  alpha = 1,
  colorFormat,
  onColorFormatChange,
  connected,
  colorVariableName,
}) => {
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const copyValue = rgbTo(colorFormat, { red, green, blue, alpha })

  return (
    <CopyToClipboard
      value={copyValue}
      placement="left"
      disabled={dropdownVisible}
      showIcon={false}
      dropdown={
        <PopoverButtonContainer>
          <ColorDropdown
            onChange={newFormat => {
              onColorFormatChange(newFormat)
            }}
            dropdownOpen={setDropdownVisible}
          />
        </PopoverButtonContainer>
      }
    >
      <StopContainer connected={connected}>
        <StopPercentage>{`${Math.round(stopPosition * 100)}%`}</StopPercentage>
        <ConnectedColorDot
          red={red}
          green={green}
          blue={blue}
          alpha={alpha}
          connected={connected}
        />
        <ColorValue
          red={red}
          green={green}
          blue={blue}
          alpha={alpha}
          format={colorFormat}
          colorVariableName={colorVariableName}
        />
      </StopContainer>
    </CopyToClipboard>
  )
}

const gradientColors: (
  gradient: Gradient,
  colorModifiers: {
    onColorFormatChange: (f: ColorFormat) => void
    colorFormat: ColorFormat
  }
) => React.ReactNode[] = (gradient, colorModifiers) => {
  const stopsLength = gradient.stops.length
  const stops = gradient.stops
    .sort((lhs, rhs) => lhs.position - rhs.position)
    // Remove duplicated stops (same position, same color)
    .filter(
      (stop, index, self) =>
        index === self.findIndex(t => t.position === stop.position)
    )
    .map((stop, i) => {
      const connected = stopsLength !== i + 1

      return (
        <Stop
          stopPosition={stop.position}
          red={stop.color.red}
          green={stop.color.green}
          blue={stop.color.blue}
          alpha={stop.color.alpha}
          connected={connected}
          colorVariableName={stop.color.colorVariableName}
          key={stop.position}
          {...colorModifiers}
        />
      )
    })
  return stops
}

interface GradientPopoverProps {
  toggle: PopoverChildrenProps
  gradient: Gradient
  visible: boolean
  onColorFormatChange: (f: ColorFormat) => void
  colorFormat: ColorFormat
  hasScrollBar?: boolean
  onClickOutside?: () => void
  copyValue: string
  dropdown?: React.ReactElement
}

const GradientPopover: React.FC<GradientPopoverProps> = ({
  toggle,
  gradient,
  visible,
  onColorFormatChange,
  colorFormat,
  hasScrollBar,
  onClickOutside,
  copyValue,
  dropdown,
  ...props
}) => {
  return (
    <Popover
      visible={visible}
      placement="top"
      onClickOutside={onClickOutside}
      popup={
        <Stops>
          {gradientColors(gradient, { onColorFormatChange, colorFormat })}

          <CopyAll>
            <CopyToClipboard
              title="Copy All"
              value={copyValue}
              dropdown={dropdown}
            />
          </CopyAll>
        </Stops>
      }
      spacing="18px"
      data-testid="gradient-popover"
      {...props}
    >
      {toggle}
    </Popover>
  )
}

export default GradientPopover
