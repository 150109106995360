import {
  CollectionForSelectFragment,
  ProjectFragment,
  RemoveShareFromProjectMutationVariables,
  ShareInfoFragment,
  useRemoveShareFromProjectMutation,
} from '@sketch/gql-types'
import { useToast } from '@sketch/toasts'
import { ErrorHandler } from '@sketch/tracing'
import React from 'react'
import { Link } from 'react-router-dom'

import { removeFromPaginated, routes } from '@sketch/modules-common'
import { updateShareWithProjectFragment } from './utils'
import {
  invalidateCollectionsExcept,
  removeSharesFromCollection,
} from '../../collections/operations'
import { SearchArgument } from '../../collections/hooks'

export interface UseRemoveShareFromProjectProps {
  project: Pick<ProjectFragment, 'identifier' | 'name'>
  share: Pick<
    ShareInfoFragment,
    'identifier' | 'name' | 'workspace' | 'memberships'
  >
  collection?: CollectionForSelectFragment
  search: SearchArgument
  onCompleted?: () => void
}

export const useRemoveShareFromProject = (
  props: UseRemoveShareFromProjectProps
) => {
  const { showToast } = useToast()

  const { share, search, onCompleted } = props

  // save project data to a reference, as otherwise, it is possible that project will be removed
  // from the share ref before the `onCompleted` callback is called
  const projectDataRef = React.useRef({
    identifier: props.project.identifier,
    name: props.project.name,
  })

  const [removeFromProjectBase, state] = useRemoveShareFromProjectMutation({
    redirectErrors: true,
    update: (cache, data) => {
      const project = projectDataRef.current

      if (!data) {
        ErrorHandler.shouldNeverHappen.invalidMutationData(
          'removeShareFromProject'
        )
        return
      }

      removeFromPaginated(
        cache,
        { identifier: share.identifier, __typename: 'Share' },
        { identifier: project.identifier, __typename: 'Project' }
      )

      // Update the Share cache
      updateShareWithProjectFragment(cache, share.identifier, share => {
        share.project = null
      })

      // Removing a share from a project removes it from any collections in the
      // project.
      // If the share belonged to a collection, we remove it from the
      // collection in the cache.
      if (props.collection) {
        removeSharesFromCollection({
          cache,
          projectIdentifier: project.identifier,
          collectionIdentifier: props.collection.identifier,
          search,
          sharesToRemove: [share],
        })
        invalidateCollectionsExcept({
          cache,
          projectIdentifier: project.identifier,
          search,
        })
      }
    },
    onCompleted: () => {
      showToast(
        <>
          Document removed from &quot;
          <Link
            to={routes.WORKSPACE_PROJECT.create({
              projectId: projectDataRef.current.identifier,
              workspaceId: share.workspace.identifier,
            })}
          >
            {projectDataRef.current.name}
          </Link>
          &quot;
        </>
      )
      onCompleted?.()
    },
    onError: 'show-toast',
  })

  const removeFromProject = (
    variables: RemoveShareFromProjectMutationVariables
  ) => {
    return removeFromProjectBase({ variables })
  }

  return [removeFromProject, state] as const
}
