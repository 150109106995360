import React from 'react'

import { useNestedProjectDropShares } from '../../hooks'

import {
  DroppableArea,
  OnDraggedOverTooltip,
} from './NestedProjectShareDrop.styles'

interface NestedProjectShareDropProps {
  projectIdentifier: string
  workspaceIdentifier: string
  children: React.ReactNode
}

const NestedProjectShareDrop: React.FC<NestedProjectShareDropProps> = ({
  projectIdentifier,
  workspaceIdentifier,
  children,
}) => {
  const { isDraggedOver = false, ...dropEvents } = useNestedProjectDropShares({
    projectIdentifier,
    workspaceIdentifier,
  })

  return (
    <>
      {isDraggedOver && (
        <OnDraggedOverTooltip>Add to project</OnDraggedOverTooltip>
      )}
      <DroppableArea $isDraggedOver={isDraggedOver} {...dropEvents}>
        {children}
      </DroppableArea>
    </>
  )
}

export default NestedProjectShareDrop
