import { useEffect } from 'react'

import { localStorageKeys } from '@sketch/constants'

import { getParsedItem } from '@sketch/utils'

import { useModalContext } from '@sketch/components'

import EducationWorkspaceApprovedModal from './EducationWorkspaceApprovedModal'

import {
  WorkspaceMinimalFragment,
  useGetCustomerQuery,
  useGetWorkspaceBillingQuery,
} from '@sketch/gql-types'

/**
 * Renders a Education Workspace Approved Modal
 */
export const useEducationWorkspaceApproved = (
  workspace: WorkspaceMinimalFragment
) => {
  const { showModal } = useModalContext()

  const isEducationWorkspace = workspace.type === 'STUDENT'

  const wasAlreadyDismissed = getParsedItem(
    localStorageKeys.dismissedEducationApprovedModal
  )?.includes(workspace.identifier)

  const shouldRenderModal = !wasAlreadyDismissed && isEducationWorkspace

  const customerQuery = useGetCustomerQuery({
    variables: { customerId: workspace.customer?.identifier ?? '' },
    skip: !shouldRenderModal || !workspace.customer?.identifier,
  })

  const billingQuery = useGetWorkspaceBillingQuery({
    variables: { identifier: workspace.identifier },
    skip: !shouldRenderModal || !workspace.customer?.identifier,
  })

  const subscriptionEnd =
    billingQuery.data?.workspace?.customer?.billing?.subscriptionEnd

  const nextBillingCycleDate =
    customerQuery.data?.customer.subscriptionInfo.renewalDate

  const educationBannerExpireDate = nextBillingCycleDate ?? subscriptionEnd

  useEffect(() => {
    if (shouldRenderModal) {
      showModal(EducationWorkspaceApprovedModal, {
        endDate: educationBannerExpireDate as string,
        workspaceId: workspace.identifier,
      })
    }
  }, [showModal, shouldRenderModal, workspace, educationBannerExpireDate])
}
