import React from 'react'

import { routes, useShareDrop, ShareDropPayload } from '@sketch/modules-common'
import { ToastLink } from '@sketch/components'

import { useToast } from '@sketch/toasts'

import { useMoveShareToProject } from '../../projects/operations'

interface UseNestedProjectDropSharesProps {
  projectIdentifier: string
  workspaceIdentifier: string
}

export const useNestedProjectDropShares = ({
  projectIdentifier,
  workspaceIdentifier,
}: UseNestedProjectDropSharesProps) => {
  const { showToast } = useToast()

  // TODO: implement optimistic response for the MoveShare mutation
  // see: https://github.com/sketch-hq/Cloud/issues/19629
  const [moveShare] = useMoveShareToProject({
    onError: errorMessage => {
      showToast(errorMessage, 'negative')
    },
    onCompleted: () => {
      showToast(
        <>
          Document added to &quot;
          <ToastLink
            to={routes.WORKSPACE_PROJECT.create({
              workspaceId: workspaceIdentifier,
              projectId: projectIdentifier,
            })}
          >
            {/* // TODO: pass project name */}
            Not yet implemented
          </ToastLink>
          &quot; project
        </>
      )
    },
  })

  return useShareDrop({
    onDropped: ({ shareId }: ShareDropPayload) => {
      moveShare({ projectId: projectIdentifier, shareId })
    },
  })
}
