import { castError } from '@sketch/utils'
import React, { useState, useEffect } from 'react'
import {
  StyledIconWrapper,
  StyledInput,
  InlineEditorWrapper,
} from './InlineEditor.styles'
import { Formik, FormikProps, FormikHelpers } from 'formik'
import { ErrorHandler } from '@sketch/tracing'
import { Icon } from '../MenuLink'
import { Tooltip, Form } from '@sketch/components'
import * as yup from 'yup'

interface FormValues {
  projectName: string
}

type FormActions = FormikHelpers<FormValues>

type FormOnSubmit = (
  formValues: FormValues,
  formikActions?: FormActions
) => Promise<any> | undefined

interface InlineEditorFormFormProps {
  placeholder: string
  onSubmit: FormOnSubmit
  toggleEditor: () => void
  defaultValue?: string
  icon: {
    component: React.ElementType<any>
    label: string
  }
}

const inlineEditorFormSchema = yup.object({
  projectName: yup
    .string()
    .max(100, 'Your project name cannot be longer than 100 characters')
    .trim()
    .required('A project name is required'),
})

export const InlineEditorForm = ({
  placeholder,
  onSubmit,
  toggleEditor,
  defaultValue,
  icon,
}: InlineEditorFormFormProps) => {
  const [folderName, setFolderName] = useState(defaultValue || '')

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        toggleEditor()
      }
    }

    window.addEventListener('keydown', handleKeyPress)

    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [toggleEditor])

  const handleFormikRender = (formikBag: FormikProps<FormValues>) => {
    const { errors, values, handleBlur, handleChange, isSubmitting } = formikBag

    const onBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (folderName.trim() === '' || folderName === undefined) {
        toggleEditor()
        return
      }

      if (!errors.projectName) {
        onSubmit({ projectName: e.target.value })
      }

      handleBlur(e)
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setFolderName(e.target.value)
      handleChange(e)
    }

    const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter') {
        if (folderName === undefined || folderName.trim().length === 0) {
          toggleEditor()
          return
        }

        if (!errors) {
          setFolderName('')
          onSubmit({ projectName: folderName })
        }
      }
    }

    return (
      <Form data-testid="rename-project-form">
        <Form.Field name="projectName">
          <Tooltip
            placement="bottom"
            spacing="8px"
            content={errors?.projectName}
            visible={!!errors?.projectName}
            disabled={!errors.projectName}
          >
            <InlineEditorWrapper aria-label="Inline Editor">
              <StyledIconWrapper>
                <Icon as={icon.component} aria-label={icon.label} />
              </StyledIconWrapper>
              <StyledInput
                name="projectName"
                type="text"
                placeholder={placeholder}
                value={values.projectName}
                onChange={onChange}
                onKeyPress={handleKeyPress}
                onBlur={onBlur}
                disabled={isSubmitting}
                hasError={errors?.projectName}
                autoFocus
              />
            </InlineEditorWrapper>
          </Tooltip>
        </Form.Field>
      </Form>
    )
  }

  const handleOnSubmit = async (
    values: FormValues,
    formikActions: FormActions
  ) => {
    const trimmedValues = inlineEditorFormSchema.cast(values)

    formikActions.setSubmitting(true)

    try {
      // This is already being validated, but since yup.cast can return
      // undefined we need this extra check.
      const { projectName } = trimmedValues
      projectName && (await onSubmit({ projectName }, formikActions))
    } catch (e) {
      const error = castError(e)
      ErrorHandler.ignore(
        error,
        'Ignore this error the mutation will handle it'
      )
    } finally {
      formikActions.setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={{ projectName: folderName }}
      onSubmit={handleOnSubmit}
      validationSchema={inlineEditorFormSchema}
    >
      {handleFormikRender}
    </Formik>
  )
}
