import React from 'react'
import { DropdownActionButton, useModalContext } from '@sketch/components'
import {
  ProjectFragment,
  useRemoveProjectMemberMutation,
  ProjectMembershipFragment,
  RemoveProjectMemberMutation,
  GetWorkspaceMembersCountWithCustomerDocument,
  GetWorkspaceMembersCountWithCustomerQueryVariables,
} from '@sketch/gql-types'
import { DataProxy } from 'apollo-cache'
import { removeProjectMembership } from '../../operations/utils/projectMemberships.cache'
import {
  ConfirmRemoveMemberModal,
  RemoveProjectAccessModal,
} from '../RemoveMemberModal'
import { useFlag } from '@sketch/modules-common'

interface RemoveMemberButtonProps {
  project: ProjectFragment
  member: Pick<ProjectMembershipFragment, 'identifier' | 'workspaceMembership'>
  text?: string
  onCancel?: () => void
  onComplete?: (
    cache: DataProxy,
    data?: RemoveProjectMemberMutation | null
  ) => void
  workspaceIdentifier: string
}

export const RemoveMemberButton: React.FC<RemoveMemberButtonProps> = function (
  props
) {
  const {
    project,
    member,
    children,
    onCancel,
    onComplete,
    workspaceIdentifier,
  } = props
  const { showModal, hideModal } = useModalContext()
  const { identifier: membershipIdentifier, workspaceMembership } = member
  const memberName = workspaceMembership.user?.name || ''

  // Remove this FF on
  // https://github.com/sketch-hq/Cloud/issues/19536
  const isTrialAbuse = useFlag('trial-abuse')

  const [removeMemberFromProject] = useRemoveProjectMemberMutation({
    variables: { membershipIdentifier },
    refetchQueries: isTrialAbuse
      ? [
          {
            query: GetWorkspaceMembersCountWithCustomerDocument,
            variables: {
              workspaceId: workspaceIdentifier,
            } as GetWorkspaceMembersCountWithCustomerQueryVariables,
          },
        ]
      : [],
    update(cache, { data }) {
      removeProjectMembership({
        cache,
        projectIdentifier: project.identifier,
        membershipIdentifier,
      })

      onComplete?.(cache, data)
    },
    onError(errorResponse) {
      if (errorResponse.includesErrorCode('CONFIRMATION_REQUIRED')) {
        showModal(ConfirmRemoveMemberModal, {
          projectName: project.name,
          onConfirm: async () => {
            await removeMemberFromProject({
              variables: {
                membershipIdentifier,
                confirmed: true,
              },
            })
            hideModal()
          },
          onCancelAction: () => {
            onCancel?.()
          },
        })

        return
      }

      if (errorResponse.includesErrorCode('EXISTING_SHARE_MEMBERSHIPS')) {
        showModal(RemoveProjectAccessModal, {
          memberName,
          projectName: project.name,
          onConfirm: async (removeShareMemberships: boolean) => {
            await removeMemberFromProject({
              variables: {
                membershipIdentifier,
                removeShareMemberships,
                confirmed: true,
              },
            })
            hideModal()
          },
          onCancelAction: () => {
            onCancel?.()
          },
        })

        return
      }
    },
  })

  return (
    <DropdownActionButton
      variant="negative"
      onClick={() => removeMemberFromProject()}
    >
      {children}
    </DropdownActionButton>
  )
}
