import React from 'react'

import { Flex, TruncateWithTooltip } from '@sketch/components'
import {
  Header,
  Title,
  PreviewImage,
  PreviewImageEmpty,
  ShareParentName,
  Subtitle,
} from './MoveDocumentModalHeader.styles'

import { ShareInfoFragment } from '@sketch/gql-types'

// TODO: update document breadcrumbs
const ShareLocation: React.FC<{
  share?: Pick<ShareInfoFragment, 'project' | 'workspace'>
}> = ({ share }) => {
  if (!share) {
    return null
  }

  if (share.project) {
    return (
      <Subtitle>
        <TruncateWithTooltip width={350}>
          {share.workspace.name} /{' '}
          <ShareParentName>{share.project.name}</ShareParentName>
        </TruncateWithTooltip>
      </Subtitle>
    )
  }

  return (
    <TruncateWithTooltip width={350}>
      <Subtitle>{share.workspace.name}</Subtitle>
    </TruncateWithTooltip>
  )
}

interface MoveDocumentModalHeaderProps {
  share?: Pick<ShareInfoFragment, 'version' | 'name' | 'project' | 'workspace'>
}

export const MoveDocumentModalHeader = (
  props: MoveDocumentModalHeaderProps
) => {
  const { share } = props
  const thumbnails = share?.version?.document?.previewFile?.thumbnails
  const image = thumbnails ? thumbnails[0]?.url : undefined

  return (
    <Header>
      <Flex data-testid="move-document-modal-header" alignItems="center" mb={4}>
        {image ? (
          <PreviewImage alt={share?.name ?? ''} src={image} />
        ) : (
          <PreviewImageEmpty data-testid="move-document-placeholder" />
        )}

        <Flex ml={6} mr={6} flex={1} flexDirection="column">
          <Title>
            <strong>Move:</strong> {share?.name ?? <>&hellip;</>}
          </Title>
          <ShareLocation share={share} />
        </Flex>
      </Flex>
    </Header>
  )
}
