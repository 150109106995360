import styled, { css } from 'styled-components'

import { ReactComponent as Checkmark } from '@sketch/icons/checkmark-16'
import { breakpoint } from '@sketch/global-styles'

export const Divider = styled.div`
  display: flex;
  margin: 0 8px;
  color: ${({ theme }) => theme.colors.state.disabled};
`

export interface ItemProps {
  $isActive: boolean
  $showAllInMobile?: boolean
  $isLink?: boolean
  $isCompleted: boolean
  $number?: number
}

export const Item = styled.li<ItemProps>(
  ({
    theme,
    $isActive,
    $showAllInMobile,
    $isLink,
    $isCompleted,
    $number,
  }) => css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8125rem;
    pointer-events: ${$isActive ? 'none' : 'auto'};
    cursor: ${$isLink ? 'pointer' : 'default'};
    color: ${$isActive || $isCompleted
      ? theme.colors.foreground.secondary.A
      : theme.colors.foreground.secondary.D};

    &:last-child {
      ${Divider} {
        display: none;
      }
    }

    ${breakpoint('base', 'sm')`
      ${
        !$showAllInMobile &&
        !$isActive &&
        css`
          width: 0;
          overflow: hidden;
        `
      }

      ${
        !$showAllInMobile &&
        css`
          ${Divider} {
            display: none;
          }
        `
      }
    `}
  `
)

export const Pill = styled.span<{ $green?: boolean }>(
  ({ theme, $green }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 21px;
    height: 18px;
    margin-right: 8px;
    font-family: ${theme.fonts.monospace};
    font-size: ${theme.fontSizes.B};
    border-radius: ${theme.radii.rounded};

    color: ${$green
      ? theme.colors.brand.green.foreground
      : theme.colors.foreground.secondary.B};
    background-color: ${$green
      ? theme.colors.brand.green.default.E
      : theme.colors.background.tertiary.C};
  `
)

export const Check = styled(Checkmark)`
  width: 12px;
  height: 12px;
`
