import React, { useEffect, useRef } from 'react'
import * as yup from 'yup'
import { Formik } from 'formik'

import { renderPreview } from './utils'

import { pluralize, Skeleton } from '@sketch/components'

import NestedProjectShareDrop from '../NestedProjectShareDrop'

import {
  InputWrapper,
  Pencil,
  StyledInput,
  StyledName,
  StyledSubtitleTextWrapper,
  Container,
  GridWrapper,
  GridWrapperLeft,
  GridWrapperRight,
  GridNestedProjectItemOption,
} from './NestedProjectItem.styles'

// Shared
// This component shares the same util as ProjectItem
import { getButtonProps } from '../../../shares/components/ProjectItem/utils'

import { NestedProjectItemProps } from './types'

const VALIDATION_SCHEMA = yup.object().shape({
  name: yup.string().trim(),
})

interface NameProps {
  name: string | null
  isEditing: boolean
  onCancel: () => void
  onSubmit: ({ name }: FormValues) => void
}

interface FormValues {
  name: string | null
}

const Name: React.FC<NameProps> = ({ name, isEditing, onCancel, onSubmit }) => {
  const inputField = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (inputField?.current && isEditing) {
      inputField.current.focus()
    }
  })

  const handleSubmit = () => {
    // Unfocus the input will submit the values
    inputField.current?.blur()
  }

  const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
    // Avoid click navigation to nestedProject
    //  - If we are editing the name, this is an optimistic nestedProject
    //    and linking to it would cause an error
    event.preventDefault()
  }

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const name = event.target.value

    if (!name.length) {
      onCancel()

      return
    }

    onSubmit({ name })
  }

  if (!isEditing) {
    return <StyledName>{name}</StyledName>
  }

  return (
    <Formik
      validationSchema={VALIDATION_SCHEMA}
      initialValues={{
        name,
      }}
      onSubmit={handleSubmit}
    >
      {({ values, handleChange }) => {
        return (
          <InputWrapper>
            <StyledInput
              ref={inputField}
              autoFocus
              autoComplete="off"
              name="name"
              placeholder="Project name"
              value={values.name ?? ''}
              onClick={handleClick}
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyDown={e => {
                // 'Enter' and 'Escape' will blur the input which will submit the value
                if (e.key === 'Enter') {
                  e.preventDefault()
                  handleSubmit()
                }

                if (e.key === 'Escape') {
                  e.preventDefault()
                  onCancel()
                }
              }}
            />
            <Pencil />
          </InputWrapper>
        )
      }}
    </Formik>
  )
}

export const NestedProjectItemGridView = (props: NestedProjectItemProps) => {
  const {
    className,
    renderDropdown,
    onClick,
    nestedProject,
    workspaceIdentifier,
  } = props

  const previewImage = renderPreview(props, 'grid')
  const a11yProps = onClick ? getButtonProps(onClick) : {}
  const dropdownContent = renderDropdown?.()

  // TODO: fetch the count from the BE once it's available
  const documentsNumber = 0
  // const documentsNumber = nestedProject.shareCount

  const name = nestedProject.name

  return (
    <Container>
      <NestedProjectShareDrop
        projectIdentifier={nestedProject.identifier}
        workspaceIdentifier={workspaceIdentifier}
      >
        <GridWrapper
          className={className}
          title={nestedProject.name}
          {...a11yProps}
        >
          <GridWrapperLeft>{previewImage}</GridWrapperLeft>
          <GridWrapperRight>
            <Name
              name={name}
              isEditing={false}
              onSubmit={() => {
                /* TODO: implement it */
              }}
              onCancel={() => {
                /* TODO: implement it */
              }}
            />
            <StyledSubtitleTextWrapper>
              <b className="shrink">
                {`${documentsNumber} ${pluralize(
                  'Document',
                  'Documents',
                  documentsNumber
                )}`}
              </b>
            </StyledSubtitleTextWrapper>
          </GridWrapperRight>
          {dropdownContent && (
            <GridNestedProjectItemOption aria-label="Document Options">
              {dropdownContent}
            </GridNestedProjectItemOption>
          )}
        </GridWrapper>
      </NestedProjectShareDrop>
    </Container>
  )
}

export interface NestedProjectItemGridViewSkeletonProps {
  inViewListener?: React.ReactNode
}
export const NestedProjectItemGridViewSkeleton = (
  props: NestedProjectItemGridViewSkeletonProps
) => {
  const { inViewListener } = props
  return (
    <Container>
      <GridWrapper>
        <GridWrapperLeft />
        <GridWrapperRight>
          <StyledName>
            <Skeleton width="75%" height="14px" />
          </StyledName>
          <StyledSubtitleTextWrapper>
            <Skeleton width="30%" height="12px" />
            {inViewListener}
          </StyledSubtitleTextWrapper>
        </GridWrapperRight>
      </GridWrapper>
    </Container>
  )
}
